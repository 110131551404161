
i {display: block; background-repeat: no-repeat; background-size: contain; background-position: center;}

/* logo */
.logo-kbl {width: 12rem; height: 8rem; background-image: url(/imgs/logo/logo-kbl.svg);}
.logo-kbl-gray {width: 7.2rem; height: 4.8rem; background-image: url(/imgs/logo/logo-kbl-gray.svg);}
.logo-kbl-youth {width: 24.4rem; height: 5.2rem; background-image: url(/imgs/logo/logo-kbl-youth.svg);}
.logo-kbl-youth-gray {width: 14.2rem; height: 4.8rem; background-image: url(/imgs/logo/logo-kbl-youth-gray.svg);}

@media (prefers-color-scheme: dark) {
    .logo-kbl {background-image: url(/imgs/logo/logo-kbl-wh.svg);}
    .logo-kbl-gray {background-image: url(/imgs/logo/logo-kbl-gray-dark.svg);}
    .logo-kbl-youth {background-image: url(/imgs/logo/logo-kbl-youth-dark.svg);}
}
[data-theme="dark"] .logo-kbl {background-image: url(/imgs/logo/logo-kbl-wh.svg);}
[data-theme="dark"] .logo-kbl-gray {background-image: url(/imgs/logo/logo-kbl-gray-dark.svg);}
[data-theme="dark"] .logo-kbl-youth {background-image: url(/imgs/logo/logo-kbl-youth-dark.svg);}

@media screen and (max-width: 820px) {
    .logo-kbl-youth {width: 18.1rem; height: 3.9rem;}
}

/* emblem */
.emblem-kbl {width: 12rem; height: 8rem; background-image: url(/imgs/logo/logo-kbl.svg);}
.emblem-kbl-gr {width: 12rem; height: 8rem; background-image: url(/imgs/logo/logo-kbl-gr.svg);}
.emblem-kbl-wh {width: 12rem; height: 8rem; background-image: url(/imgs/logo/logo-kbl-wh.svg);}
.emblem-db {width: 12rem; height: 8rem; background-image: url(/imgs/logo/emblem-db.svg);}
.emblem-ss {width: 12rem; height: 8rem; background-image: url(/imgs/logo/emblem-ss.svg);}
.emblem-sk {width: 12rem; height: 8rem; background-image: url(/imgs/logo/emblem-sk.svg);}
.emblem-lg {width: 12rem; height: 8rem; background-image: url(/imgs/logo/emblem-lg.svg);}
.emblem-car {width: 12rem; height: 8rem; background-image: url(/imgs/logo/emblem-car.svg);}
.emblem-sono {width: 12rem; height: 8rem; background-image: url(/imgs/logo/emblem-sono.svg);}
.emblem-or {width: 12rem; height: 8rem; background-image: url(/imgs/logo/emblem-or.svg);}
.emblem-kcc {width: 12rem; height: 8rem; background-image: url(/imgs/logo/emblem-kcc.svg);}
.emblem-kgc {width: 12rem; height: 8rem; background-image: url(/imgs/logo/emblem-kgc.svg);}
.emblem-kt {width: 12rem; height: 8rem; background-image: url(/imgs/logo/emblem-kt.svg);}
.emblem-el {width: 12rem; height: 8rem; background-image: url(/imgs/logo/emblem-el.svg);}
.emblem-hd {width: 12rem; height: 8rem; background-image: url(/imgs/logo/emblem-hd.svg);}
.emblem-gas {width: 12rem; height: 8rem; background-image: url(/imgs/logo/emblem-gas.svg);}
.emblem-kaf {width: 12rem; height: 8rem; background-image: url(/imgs/logo/emblem-kaf.svg);}
.emblem-mb {width: 12rem; height: 8rem; background-image: url(/imgs/logo/emblem-easia-mb.svg);}
.emblem-ntk {width: 12rem; height: 8rem; background-image: url(/imgs/logo/emblem-easia-ntk.svg);}
.emblem-cj {width: 12rem; height: 8rem; background-image: url(/imgs/logo/emblem-easia-cj.svg);}
.emblem-ttg {width: 12rem; height: 8rem; background-image: url(/imgs/logo/emblem-easia-ttg.svg);}
.emblem-rgk {width: 12rem; height: 8rem; background-image: url(/imgs/logo/emblem-easia-rgk.svg);}
.emblem-tfb {width: 12rem; height: 8rem; background-image: url(/imgs/logo/emblem-easia-tfb.svg);}
.emblem-gong {width: 12rem; height: 8rem; background-image: url(/imgs/logo/emblem-gong.svg);}
.emblem-mong {width: 12rem; height: 8rem; background-image: url(/imgs/logo/emblem-mong.svg);}
/* 흰 테두리 */
.emblem-kgc-white {width: 12rem; height: 8rem; background-image: url(/imgs/logo/emblem-kgc-white.svg);}
@media (prefers-color-scheme: dark) {
    .emblem-kbl {background-image: url(/imgs/logo/logo-kbl-wh.svg);}
    .emblem-lg {background-image: url(/imgs/logo/emblem-lg-dark.svg);}
    .emblem-ss {background-image: url(/imgs/logo/emblem-ss-dark.svg);}
    .emblem-or {background-image: url(/imgs/logo/emblem-or-dark.svg);}
    .emblem-car {background-image: url(/imgs/logo/emblem-car-dark.svg);}
    .emblem-kgc {background-image: url(/imgs/logo/emblem-kgc-dark.svg);}
    .emblem-kgc-white {background-image: url(/imgs/logo/emblem-kgc-dark.svg);}
    .emblem-kt {background-image: url(/imgs/logo/emblem-kt-dark.svg);}
    .emblem-cj {width: 12rem; height: 8rem; background-image: url(/imgs/logo/emblem-easia-cj-dark.svg);}
    .emblem-rgk {width: 12rem; height: 8rem; background-image: url(/imgs/logo/emblem-easia-rgk-dark.svg);}
    .emblem-ntk {width: 12rem; height: 8rem; background-image: url(/imgs/logo/emblem-easia-ntk-dark.svg);}
}
[data-theme="dark"] .emblem-kbl {background-image: url(/imgs/logo/logo-kbl-wh.svg);}
[data-theme="dark"] .emblem-lg {background-image: url(/imgs/logo/emblem-lg-dark.svg);}
[data-theme="dark"] .emblem-ss {background-image: url(/imgs/logo/emblem-ss-dark.svg);}
[data-theme="dark"] .emblem-or {background-image: url(/imgs/logo/emblem-or-dark.svg);}
[data-theme="dark"] .emblem-car {background-image: url(/imgs/logo/emblem-car-dark.svg);}
[data-theme="dark"] .emblem-kgc {background-image: url(/imgs/logo/emblem-kgc-dark.svg);}
[data-theme="dark"] .emblem-kgc-white {background-image: url(/imgs/logo/emblem-kgc-dark.svg);}
[data-theme="dark"] .emblem-kt {background-image: url(/imgs/logo/emblem-kt-dark.svg);}
[data-theme="dark"] .emblem-cj {background-image: url(/imgs/logo/emblem-easia-cj-dark.svg);}
[data-theme="dark"] .emblem-rgk {background-image: url(/imgs/logo/emblem-easia-rgk-dark.svg);}
[data-theme="dark"] .emblem-ntk {background-image: url(/imgs/logo/emblem-easia-ntk-dark.svg);}


/* footer sns */
.ic-footer-facebook {width: 3.6rem; height: 3.6rem; background-image: url(/imgs/ico/footer/ic-footer-facebook.svg); cursor: pointer;}
.ic-footer-instagram {width: 3.6rem; height: 3.6rem; background-image: url(/imgs/ico/footer/ic-footer-instagram.svg); cursor: pointer;}
.ic-footer-youtube {width: 3.6rem; height: 3.6rem; background-image: url(/imgs/ico/footer/ic-footer-youtube.svg); cursor: pointer;}
.ic-footer-naver {width: 3.6rem; height: 3.6rem; background-image: url(/imgs/ico/footer/ic-footer-naver.svg); cursor: pointer;}
.ic-footer-navertv {width: 3.6rem; height: 3.6rem; background-image: url(/imgs/ico/footer/ic-footer-navertv.svg); cursor: pointer;}
.ic-footer-africa {width: 3.6rem; height: 3.6rem; background-image: url(/imgs/ico/footer/ic-footer-africa.svg); cursor: pointer;}
@media (prefers-color-scheme: dark) {
    .ic-footer-facebook {background-image: url(/imgs/ico/footer/ic-footer-facebook-dark.svg);}
    .ic-footer-instagram {background-image: url(/imgs/ico/footer/ic-footer-instagram-dark.svg);}
    .ic-footer-youtube {background-image: url(/imgs/ico/footer/ic-footer-youtube-dark.svg);}
    .ic-footer-naver {background-image: url(/imgs/ico/footer/ic-footer-naver-dark.svg);}
    .ic-footer-navertv {background-image: url(/imgs/ico/footer/ic-footer-navertv-dark.svg);}
    .ic-footer-africa {background-image: url(/imgs/ico/footer/ic-footer-africa-dark.svg);}
}
[data-theme="dark"] .ic-footer-facebook {background-image: url(/imgs/ico/footer/ic-footer-facebook-dark.svg);}
[data-theme="dark"] .ic-footer-instagram {background-image: url(/imgs/ico/footer/ic-footer-instagram-dark.svg);}
[data-theme="dark"] .ic-footer-youtube {background-image: url(/imgs/ico/footer/ic-footer-youtube-dark.svg);}
[data-theme="dark"] .ic-footer-naver {background-image: url(/imgs/ico/footer/ic-footer-naver-dark.svg);}
[data-theme="dark"] .ic-footer-navertv {background-image: url(/imgs/ico/footer/ic-footer-navertv-dark.svg);}
[data-theme="dark"] .ic-footer-africa {background-image: url(/imgs/ico/footer/ic-footer-africa-dark.svg);}

/* mobile sns */
.ic-mobile-facebook {width: 3.6rem; height: 3.6rem; background-image: url(/imgs/ico/footer/ic-mobile-facebook.svg); cursor: pointer;}
.ic-mobile-instagram {width: 3.6rem; height: 3.6rem; background-image: url(/imgs/ico/footer/ic-mobile-instagram.svg); cursor: pointer;}
.ic-mobile-youtube {width: 3.6rem; height: 3.6rem; background-image: url(/imgs/ico/footer/ic-mobile-youtube.svg); cursor: pointer;}
.ic-mobile-naver {width: 3.6rem; height: 3.6rem; background-image: url(/imgs/ico/footer/ic-mobile-naver.svg); cursor: pointer;}
.ic-mobile-navertv {width: 3.6rem; height: 3.6rem; background-image: url(/imgs/ico/footer/ic-mobile-navertv.svg); cursor: pointer;}
.ic-mobile-africa {width: 3.6rem; height: 3.6rem; background-image: url(/imgs/ico/footer/ic-mobile-africa.svg); cursor: pointer;}

/* ico(a b c d e f g h i j k l m n o p q r s t u v w x y z) */
.ic-alarm-check {width: 12rem; height: 12rem; background-image: url(/imgs/ico/ic-alarm-check.svg);}
.ic-align {width: 1.2rem; height: 1.2rem; background-image: url(/imgs/ico/ic-align.svg);}
.on .ic-align {background-image: url(/imgs/ico/ic-align-on.svg);}
.ic-arrow-left-16-gr {width: 1.6rem; height: 1.6rem; background-image: url(/imgs/ico/ic-arrow-left-16-gr.svg);}
.ic-arrow-left-40 {width: 4rem; height: 4rem; background-image: url(/imgs/ico/ic-arrow-left-40.svg);}
.ic-arrow-right-14 {width: 1.4rem; height: 1.4rem; background-image: url(/imgs/ico/ic-arrow-right-14.svg);}
.ic-arrow-right-16-gr {width: 1.6rem; height: 1.6rem; background-image: url(/imgs/ico/ic-arrow-right-16-gr.svg);}
.ic-arrow-right-40 {width: 4rem; height: 4rem; background-image: url(/imgs/ico/ic-arrow-right-40.svg);}
.ic-calendar-24 {width: 2.4rem; height: 2.4rem; background-image: url(/imgs/ico/ic-calendar-24.svg);}
.ic-clip-16 {width: 1.6rem; height: 1.6rem; background-image: url(/imgs/ico/ic-clip-16.svg);}
.ic-clock-orange-24 {width: 2.4rem; height: 2.4rem; background-image: url(/imgs/ico/ic-clock-orange-24.svg);}
.ic-coin-60 {width: 6rem; height: 6rem; background-image: url(/imgs/ico/ic-coin-60.svg);}
.ic-compl-20 {width: 2rem; height: 2rem; background-image: url(/imgs/ico/ic-compl-20.svg);}
.ic-event-24 {width: 2.4rem; height: 2.4rem; background-image: url(/imgs/ico/ic-event-24.svg);}
.ic-game-24 {width: 2.4rem; height: 2.4rem; background-image: url(/imgs/ico/ic-game-24.svg);}
.ic-info-gy-16 {width: 1.6rem; height: 1.6rem; background-image: url(/imgs/ico/ic-info-gy-16.svg);}
.ic-like  {width: 2.4rem; height: 2.4rem; background-image: url(/imgs/ico/ic-like.svg);}
.ic-like-ov  {width: 2.4rem; height: 2.4rem; background-image: url(/imgs/ico/ic-like-ov.svg);}
.ic-like-gr-24 {width: 2.4rem; height: 2.4rem; background-image: url(/imgs/ico/ic-like-gy-24.svg);}
.ic-like-wh-24 {width: 2.4rem; height: 2.4rem; background-image: url(/imgs/ico/ic-like-wh-24.svg);}
.ic-like-yr-32 {width: 3.2rem; height: 3.2rem; background-image: url(/imgs/ico/ic-like-yr-32.svg);}
.ic-like-wh-32 {width: 3.2rem; height: 3.2rem; background-image: url(/imgs/ico/ic-like-wh-32.svg);}
.ic-list-24 {width: 2.4rem; height: 2.4rem; background-image: url(/imgs/ico/ic-list-24.svg);}
.ic-livevideo-24 {width: 2.4rem; height: 2.4rem; background-image: url(/imgs/ico/ic-livevideo-24.svg);}
.ic-match-24 {width: 2.4rem; height: 2.4rem; background-image: url(/imgs/ico/ic-matich-24.svg);}
.ic-member-32 {width: 3.2rem; height: 3.2rem; background-image: url(/imgs/ico/ic-member-32.svg);}
.ic-menu-24 {width: 2.4rem; height: 2.4rem; background-image: url(/imgs/ico/ic-menu-24.svg);}
.ic-menu-close-24 {width: 2.4rem; height: 2.4rem; background-image: url(/imgs/ico/ic-menu-close-24.svg);}
.ic-mobile-dropdown-12 {width: 1.2rem; height: 1.2rem; background-image: url(/imgs/ico/ic-mobile-dropdown-12.svg);}
.active .ic-mobile-dropdown-12 {transform: rotate(180deg);}
.ic-movieclip-24 {width: 2.4rem; height: 2.4rem; background-image: url(/imgs/ico/ic-movieclip-24.svg);}
.ic-news-24 {width: 2.4rem; height: 2.4rem; background-image: url(/imgs/ico/ic-news-24.svg);}
.ic-not-20 {width: 2rem; height: 2rem; background-image: url(/imgs/ico/ic-not-20.svg);}
.ic-notice-24 {width: 2.4rem; height: 2.4rem; background-image: url(/imgs/ico/ic-notice-24.svg);}
.ic-photo-24 {width: 2.4rem; height: 2.4rem; background-image: url(/imgs/ico/ic-photo-24.svg);}
.ic-player-rank-24 {width: 2.4rem; height: 2.4rem; background-image: url(/imgs/ico/ic-player-rank-24.svg);}
.ic-pop-close-24 {width: 2.4rem; height: 2.4rem; background-image: url(/imgs/ico/ic-pop-close-24.svg);}
.ic-rank-24 {width: 2.4rem; height: 2.4rem; background-image: url(/imgs/ico/ic-rank-24.svg);}
.ic-refund-32 {width: 3.2rem; height: 3.2rem; background-image: url(/imgs/ico/ic-refund-32.svg);}
.ic-rock-100 {width: 10rem; height: 10rem; background-image: url(/imgs/ico/img-rock-100.svg);}
.ic-search-20 {width: 2rem; height: 2rem; background-image: url(/imgs/ico/ic-search-20.svg);}
.ic-shop-24 {width: 2.4rem; height: 2.4rem; background-image: url(/imgs/ico/ic-shop-24.svg);}
.ic-shorts-24 {width: 2.4rem; height: 2.4rem; background-image: url(/imgs/ico/ic-short-24.svg);}
.ic-shoot-chart-x {width: 2.4rem; height: 2.4rem; background-image: url(/imgs/ico/ic-shoot-chart-x-md.png);}
.ic-shoot-chart-o {width: 2.4rem; height: 2.4rem; background-image: url(/imgs/ico/ic-shoot-chart-o-md.png);}
.ic-sns-24 {width: 2.4rem; height: 2.4rem; background-image: url(/imgs/ico/ic-sns-24.svg);}
.ic-star {width: 2rem; height: 2rem; background-image: url(/imgs/ico/ic-star.svg);}
.ic-star-ov {width: 2rem; height: 2rem; background-image: url(/imgs/ico/ic-star-ov.svg);}
.ic-store-24 {width: 2.4rem; height: 2.4rem; background-image: url(/imgs/ico/ic-store-24.svg);}
.ic-ticket-24 {width: 2.4rem; height: 2.4rem; background-image: url(/imgs/ico/ic-ticket-24.svg);}
.ic-design {width: 2.4rem; height: 2.4rem; background-image: url(/imgs/ico/ic-design.svg);}
.ic-photo {width: 2.4rem; height: 2.4rem; background-image: url(/imgs/ico/ic-photo.svg);}
.ic-unrock-100 {width: 10rem; height: 10rem; background-image: url(/imgs/ico/img-unrock-100.svg);}
.ic-write {width: 2.4rem; height: 2.4rem; background-image: url(/imgs/ico/ic-write.svg);}
.ic-language {width: 2.0rem; height: 2.0rem; background-image: url(/imgs/ico/ic-language.svg);}
.ic-cart {width: 2.4rem; height: 2.4rem; background-image: url(/imgs/ico/ic-cart.svg);}
/*※임의로 추가*/
.ic-share-32 {width: 3.2rem; height: 3.2rem; background-image: url(/imgs/ico/ic-share-32.svg);}
/*.share-icon-twitter {width: 3.6rem; height: 3.6rem; background-image: url(/imgs/ico/snsTwitter.png); cursor: pointer;}*/
/*.share-icon-facebook {width: 3.6rem; height: 3.6rem; background-image: url(/imgs/ico/snsFacebook.png); cursor: pointer;}*/
/*.share-icon-naver {width: 3.6rem; height: 3.6rem; background-image: url(/imgs/ico/snsNaver.png); cursor: pointer;}*/
.share-icon-kakao {width: 3.6rem; height: 3.6rem; background-image: url(/imgs/ico/snsKakao.png); cursor: pointer;}


@media (prefers-color-scheme: dark) {
    .ic-event-24,
    .ic-game-24,
    .ic-match-24,
    .ic-menu-24,
    .ic-menu-close-24,
    .ic-news-24,
    .ic-notice-24,
    .ic-photo-24,
    .ic-player-rank-24,
    .ic-pop-close-24,
    .ic-rank-24,
    .ic-shop-24,
    .ic-shorts-24,
    .ic-livevideo-24,
    .ic-design,
    .ic-photo,
    .ic-write,
    .ic-sns-24,
    .ic-store-24,
    .ic-language,
    .ic-cart,
    .ic-ticket-24 {filter:invert(1);}
    .ic-mobile-dropdown-12 {filter: grayscale(1) invert(1);}
}
[data-theme="dark"] .ic-event-24  {filter:invert(1);}
[data-theme="dark"] .ic-game-24  {filter:invert(1);}
[data-theme="dark"] .ic-match-24  {filter:invert(1);}
[data-theme="dark"] .ic-menu-24  {filter:invert(1);}
[data-theme="dark"] .ic-menu-close-24  {filter:invert(1);}
[data-theme="dark"] .ic-news-24  {filter:invert(1);}
[data-theme="dark"] .ic-notice-24  {filter:invert(1);}
[data-theme="dark"] .ic-photo-24  {filter:invert(1);}
[data-theme="dark"] .ic-player-rank-24  {filter:invert(1);}
[data-theme="dark"] .ic-pop-close-24  {filter:invert(1);}
[data-theme="dark"] .ic-rank-24  {filter:invert(1);}
[data-theme="dark"] .ic-shop-24  {filter:invert(1);}
[data-theme="dark"] .ic-shorts-24  {filter:invert(1);}
[data-theme="dark"] .ic-livevideo-24  {filter:invert(1);}
[data-theme="dark"] .ic-design  {filter:invert(1);}
[data-theme="dark"] .ic-photo  {filter:invert(1);}
[data-theme="dark"] .ic-write  {filter:invert(1);}
[data-theme="dark"] .ic-sns-24  {filter:invert(1);}
[data-theme="dark"] .ic-store-24  {filter:invert(1);}
[data-theme="dark"] .ic-language  {filter:invert(1);}
[data-theme="dark"] .ic-cart  {filter:invert(1);}
[data-theme="dark"] .ic-ticket-24 {filter:invert(1);}
[data-theme="dark"] .ic-mobile-dropdown-12 {filter: grayscale(1) invert(1);}

/* img(a b c d e f g h i j k l m n o p q r s t u v w x y z) */
.img-join-complete-120 {width: 12rem; height: 12rem; background-image: url(/imgs/ico/img/img-join-complete-120.svg);}
.img-empty01 {width: 12rem; height: 12rem; background-image: url(/imgs/ico/img/img-empty01.svg);}
.img-empty02 {width: 12rem; height: 12rem; background-image: url(/imgs/ico/img-empty02-120.svg);}
.img-no-game {width: 6rem; height: 6rem; background-image: url(/imgs/ico/img/img-no-game.svg);}
.img-play-60 {width: 6rem; height: 6rem; background-image: url(/imgs/ico/img/img-paly-60.svg);}
.img-checking {width: 8rem; height: 8rem; background-image: url(/imgs/ico/img/imgs-checking-icon.png);}
.img-cart-no {width: 12rem; height: 12rem; background-image: url(/imgs/ico/img-cart-no.svg);}
.ic-complete-cart {width: 12rem; height: 12rem; background-image: url(/imgs/ico/ic-complete-cart.svg);}

.img-event-success {width: 10rem; height: 10rem; background-image: url(/imgs/ico/img-event-success.svg);}
.img-event-fail {width: 10rem; height: 10rem; background-image: url(/imgs/ico/img-event-fail.svg);}

.img-appstore {width: 18rem; height: 5.2rem; background-image: url(/imgs/ico/img/img-appstore.svg); cursor: pointer;}
.img-googleplay {width: 18rem; height: 5.2rem; background-image: url(/imgs/ico/img/img-googleplay.svg); cursor: pointer;}

.img-check-event {width: 10rem; height: 10rem; margin: 0 auto; background-image: url(/imgs/ico/img/img-check-event.svg);}
.img-event-success {width: 10rem; height: 10rem; margin: 0 auto; background-image: url(/imgs/ico/img/img-event-success.svg);}

@media (prefers-color-scheme: dark) {
    .img-empty01, .img-empty02 {filter:invert(.9);}
    .img-no-game {filter:invert(1);}
}
[data-theme="dark"] .img-empty01 {filter:invert(.9);}
[data-theme="dark"] .img-empty02 {filter:invert(.9);}
[data-theme="dark"] .img-no-game {filter:invert(1);}

/* 소개 인사말 아이콘 */
.ic-bf-01 {width: 6rem; height: 6rem; background-image: url(/imgs/ico/ic-bf-01.svg);}
.ic-bf-02 {width: 6rem; height: 6rem; background-image: url(/imgs/ico/ic-bf-02.svg);}
.ic-bf-03 {width: 6rem; height: 6rem; background-image: url(/imgs/ico/ic-bf-03.svg);}
.ic-bf-04 {width: 6rem; height: 6rem; background-image: url(/imgs/ico/ic-bf-04.svg);}
.ic-bf-05 {width: 6rem; height: 6rem; background-image: url(/imgs/ico/ic-bf-05.svg);}
.ic-bf-06 {width: 6rem; height: 6rem; background-image: url(/imgs/ico/ic-bf-06.svg);}
.ic-bf-07 {width: 6rem; height: 6rem; background-image: url(/imgs/ico/ic-bf-07.svg);}
.ic-bf-08 {width: 6rem; height: 6rem; background-image: url(/imgs/ico/ic-bf-08.svg);}
.ic-bf-09 {width: 6rem; height: 6rem; background-image: url(/imgs/ico/ic-bf-09.svg);}
.ic-bf-10 {width: 6rem; height: 6rem; background-image: url(/imgs/ico/ic-bf-10.svg);}

/* shoot chart */
.ic-shoot-chart-x {width: 2.4rem; height: 2.4rem; background-image: url(/imgs/ico/ic-shoot-chart-x-md.png);}
.ic-shoot-chart-o {width: 2.4rem; height: 2.4rem; background-image: url(/imgs/ico/ic-shoot-chart-o-md.png);}

/* dark mode */
.ic-dark-16 {width: 1.6rem; height: 1.6rem; background-image: url(/imgs/ico/ic-dark-16.svg);}
.ic-light-16 {width: 1.6rem; height: 1.6rem; background-image: url(/imgs/ico/ic-light-16.svg);}

/* youth */
.ic-spot-24 {width: 2.4rem; height: 2.4rem; background-image: url(/imgs/ico/ic-spot-24.svg);}

.ic-youth-value-01 {width: 6rem; height: 6rem; background-image: url(/imgs/ico/ic-youth-value-01.svg);}
.ic-youth-value-02 {width: 6rem; height: 6rem; background-image: url(/imgs/ico/ic-youth-value-02.svg);}
.ic-youth-value-03 {width: 6rem; height: 6rem; background-image: url(/imgs/ico/ic-youth-value-03.svg);}
.ic-youth-value-04 {width: 6rem; height: 6rem; background-image: url(/imgs/ico/ic-youth-value-04.svg);}

.ic-club-wh-60 {width: 6rem; height: 6rem; background-image: url(/imgs/ico/ic-club-wh-60.svg);}
.ic-spot-wh-60 {width: 6rem; height: 6rem; background-image: url(/imgs/ico/ic-spot-wh-60.svg);}
.ic-teacher-wh-60 {width: 6rem; height: 6rem; background-image: url(/imgs/ico/ic-teacher-wh-60.svg);}
.ic-player-wh-60 {width: 6rem; height: 6rem; background-image: url(/imgs/ico/ic-player-wh-60.svg);}

.ic-ball-32 {width: 3.2rem; height: 3.2rem; background-image: url(/imgs/ico/ic-ball-32.svg);}

.ic-star-18 {width: 1.8rem; height: 1.8rem; background-image: url(/imgs/ico/ic-star-18.svg);}
.active .ic-star-18 {width: 1.8rem; height: 1.8rem; background-image: url(/imgs/ico/ic-star-18-ov.svg);}

.ic-cart-step01 {width: 4rem; height: 4rem; background-image: url(/imgs/ico/ic-cart-step01.svg);}
.ic-cart-step02 {width: 4rem; height: 4rem; background-image: url(/imgs/ico/ic-cart-step02.svg);}
.ic-cart-step03 {width: 4rem; height: 4rem; background-image: url(/imgs/ico/ic-cart-step03.svg);}

.ic-physical-01 {width: 4rem; height: 4rem; background-image: url(/imgs/ico/ic-physical-01.svg);}
.ic-physical-02 {width: 4rem; height: 4rem; background-image: url(/imgs/ico/ic-physical-02.svg);}
.ic-physical-03 {width: 4rem; height: 4rem; background-image: url(/imgs/ico/ic-physical-03.svg);}
.ic-physical-04 {width: 4rem; height: 4rem; background-image: url(/imgs/ico/ic-physical-04.svg);}

.ic-arrow-right-16 {width: 1.6rem; height: 1.6rem; background-image: url(/imgs/ico/ic-arrow-right-16.svg); cursor: pointer;}
.ic-arrow-down-16-gr {width: 1.6rem; height: 1.6rem; background-image: url(/imgs/ico/ic-arrow-down-16-gy.svg); cursor: pointer;}
.ic-arrow-up-16-gr {width: 1.6rem; height: 1.6rem; background-image: url(/imgs/ico/ic-arrow-up-16-gy.svg); cursor: pointer;}
