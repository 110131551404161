
/* to embed a font : https://fonts.google.com/ */
@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.5/dist/web/static/pretendard.css');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.cdnfonts.com/css/campton');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');


/* document --------------------------- */
*, *::after, *::before {
    box-sizing: border-box;
}

body, html {
    position: relative;
    width: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    /* web font + system font */
    font-family: 'Pretendard', 'Noto Sans KR', 'Roboto', -apple-system, BlinkMacSystemFont, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 62.5%;
    font-weight: 400;
    color: #000;
    /* smoothing font */
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

/* 태블릿 820px ~ 1024px */
@media screen and (max-width: 1024px) {
}
/* 모바일 ~ 820px */
@media screen and (max-width: 820px) {
}

/* typography --------------------------- */
h1, h2, h3, h4, h5, h6 {
    clear: both;
    margin: 0;
    padding: 0;
}

p {
    margin: 0;
}

b, strong {
    /* add the correct font weight in Chrome, Edge, and Safari */
    font-weight: 700;
}

/* list style --------------------------- */
ul, ol {
    margin: 0;
    padding: 0;
    list-style: none;
    -webkit-tap-highlight-color:transparent;
}

/* embedded content --------------------------- */
img {
    /* remove the border on images inside links in IE 10 */
    border-style: none;
}

/* form --------------------------- */
button, input, textarea, select, option {
    /* web font + system font */
    font-family: 'Pretendard', 'Noto Sans KR', 'Roboto', -apple-system, BlinkMacSystemFont, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    /* Remove the inheritance of text transform in Edge, Firefox and IE. */
    text-transform: none;
    background-color: transparent;
    /* Show the overflow in Edge and IE. */
    overflow: visible;
    cursor: pointer;
    -webkit-tap-highlight-color:transparent;
}

button:disabled, input:disabled {
    cursor: not-allowed;
}

button, [type='button'], [type='reset'], [type='submit'] {
    /* correct the inability to style clickable types in iOS and Safari */
    -moz-appearance: button;
    -webkit-appearance: button;
}

[type='checkbox'], [type='radio'] {
    /* remove the padding in IE 10 */
    padding: 0;
}

textarea {
    width: 100%;
    /* remove the default vertical scrollbar in IE 10+ */
    overflow: auto;
    resize: none;
}

/* link --------------------------- */
a {
    color: var(--black);
    text-decoration: none;
    /* remove the gray background on active links in IE 10 */
    background-color: transparent;
    cursor: pointer;
    -webkit-tap-highlight-color:transparent;
}

a:visited, a:hover, a:focus, a:active {
    color: var(--black);
    outline: 0;
    text-decoration: none;
}

:focus {
    outline: none;
}

/* misc --------------------------- */
[hidden] {
    /* add the correct display in IE 10 */
    display: none;
}

.desktop-only {display: block;}
.mobile-only {display: none;}

/* 모바일 ~ 820px */
@media screen and (max-width: 820px) {
    .desktop-only {display: none!important;}
    .mobile-only {display: initial;}
}

/* clearfix --------------------------- */
.clearfix {/*  IE 6,7 */ zoom: 1;}
.clearfix::before,
.clearfix::after {content: ''; display: table;}
.clearfix::after {clear: both;}

/* scroll --------------------------- */
::-webkit-scrollbar {width: .4rem; height: .9rem; background-color: transparent;}
::-webkit-scrollbar-thumb {background-color: var(--gray200); border-radius: 1rem; background-clip: padding-box;}
::-webkit-scrollbar-track {background-color: transparent;}

/* root --------------------------- */
:root {
    /* ------------ brand color */
    --pb50: #E3E6ED;
    --pb100: #BAC0D4;
    --pb200: #8D97B7;
    --pb300: #63709A;
    --pb400: #445387;
    --pb500: #223976; /* kbl sub color */
    --pb600: #1C326E;
    --pb700: #142A63;
    --pb800: #0B2157;
    --pb900: #011241; /* kbl main color */

    --yr50: #FBEAE7;
    --yr100: #FFCEBC;
    --yr200: #FFAE90;
    --yr300: #FF8F63;
    --yr400: #FF7740;
    --yr500: #FF601C; /* kbl point color */
    --yr600: #F45A18;
    --yr700: #E65313;
    --yr800: #D84C0F;
    --yr900: #BF3F06;

    /* ------------ greyscale color */
    --gray100: #F2F3F6;
    --gray200: #E3E5E9;
    --gray300: #D7D8DA;
    --gray400: #BDBEC0;
    --gray500: #747479;
    --gray600: #424243;
    --gray700: #2B2B2B;
    --gray800: #1C1C1C;
    --gray900: #121212;

    /* ------------ system color */
    --red: #FF3529;
    --blue: #236CD9;
    --white01: #FFFFFF;
    --white02: #FFFFFF;
    --black: #000000;

    /* ------------ club color */
    /* 원주 DB 프로미 : db */
    --db-main: #03662C;
    --db-point: #00399F;
    --db-sub01: #71BB98;
    --db-sub02: #C6EAD9;
    /* 서울 삼성 썬더스 : ss */
    --ss-main: #0032A0;
    --ss-point: #FFB81C;
    --ss-sub01: #6D94C7;
    --ss-sub02: #CEDDF1;
    /* 서울 SK 나이츠 : sk */
    --sk-main: #DB0028;
    --sk-point: #FF9600;
    --sk-sub01: #EC696F;
    --sk-sub02: #F9D3DA;
    /* 창원 LG 세이커스 : lg */
    --lg-main: #550E10;
    --lg-point: #FFBA00;
    --lg-sub01: #996E70;
    --lg-sub02: #D3B0B2;
    /* 고양 소노 스카이거너스(오리온) : or */
    --or-main: #283D60;
    --or-point: #7DA1C9;
    --or-sub01: #63748D;
    --or-sub02: #CBD1D9;
    /* 전수 KCC 이지스 : kcc */
    --kcc-main: #07215A;
    --kcc-point: #FF9D00;
    --kcc-sub01: #666E80;
    --kcc-sub02: #ACB3C3;
    /* 안양 KGC 인삼공사 : kgc */
    --kgc-main: #D61D38;
    --kgc-point: #171717;
    --kgc-sub01: #E9667E;
    --kgc-sub02: #F2CBD2;
    /* 수원 KT 소닉붐 : kt */
    --kt-main: #E21820;
    --kt-point: #F07C22;
    --kt-sub01: #F36E6B;
    --kt-sub02: #F1CBD1;
    /* 대구 한국가스공사 : el */
    --el-main: #201351;
    --el-point: #BE1540;
    --el-sub01: #797197;
    --el-sub02: #BEBACF;
    /* 울산 현대모비스 피버스 : hd */
    --hd-main: #00287A;
    --hd-point: #ED1C24;
    --hd-sub01: #617196;
    --hd-sub02: #BFC7D8;

    /* ------------ box shadow */
    --shadow-gr: .2rem .2rem 2rem rgba(1, 18, 65, 0.04);
    --shadow-pb: .4rem .4rem 1.2rem rgba(1, 18, 65, 0.16);
    --shadow-yr: .4rem .4rem 3.2rem rgba(255, 96, 28, 0.2);
}

@media (prefers-color-scheme: dark) {
    :root {
        /* ------------ greyscale color */
        --gray100: #1C1C1C;
        --gray200: #2B2B2B;
        --gray300: #424243;
        --gray400: #424243;

        /* ------------ system color */
        --white01: #1C1C1C;
        --white02: #121212;
        --black: #FFFFFF;
    }

    body, html {
        color: #fff;
    }
}
[data-theme="dark"] {
    /* ------------ greyscale color */
    --gray100: #1C1C1C;
    --gray200: #2B2B2B;
    --gray300: #424243;
    --gray400: #424243;

    /* ------------ system color */
    --white01: #1C1C1C;
    --white02: #121212;
    --black: #FFFFFF;
}
[data-theme="dark"] body, html {
    color: #fff;
}

[data-theme="dark"] ::-webkit-input-placeholder {color:#FFFFFF;}
[data-theme="dark"] ::-moz-placeholder {color:#FFFFFF;opacity:1}
[data-theme="dark"] ::-ms-input-placeholder {color:#FFFFFF;}

/* align */
.position-center {position: absolute; top: 50%; left: 50%; -webkit-transform: translate(-50%, -50%); -moz-transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%); -o-transform: translate(-50%, -50%); transform: translate(-50%, -50%);}
.position-center-top {position: absolute; top: 50%; -webkit-transform: translateY(-50%); -moz-transform: translateY(-50%); -ms-transform: translateY(-50%); -o-transform: translateY(-50%); transform: translateY(-50%);}
.position-center-left {position: absolute; left: 50%; -webkit-transform: translateX(-50%); -moz-transform: translateX(-50%); -ms-transform: translateX(-50%); -o-transform: translateX(-50%); transform: translateX(-50%);}

/* text overflow */
.ellipsis {display: block; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;} /* 한 줄 말줄임, width 지정 필요 */
.ellipsis.double {overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;} /* 두 줄 말줄임 */

/* flex basic */
.flex-txt {display: flex; align-items: center;}
.flex-txt > li {display: flex; align-items: center; font-size: 1.4rem; color: var(--gray500);}
.flex-txt > li + li::before {content: ''; width: 1px; height: 1.2rem; background-color: var(--gray600); margin: 0 .6rem!important;}
.flex-txt > li h6 {font-weight: 600;}
.flex-txt > li * + * {margin-left: .4rem;}

.flex-input {display: flex; align-items: center; column-gap: 3.2rem;}
.flex-input > li {display: flex; align-items: center;}

@media screen and (max-width: 1024px) {
    .flex-txt > li {font-size: 1.2rem;}
    .flex-txt > li + li::before {width: 1px; height: 1rem; margin: 0 .4rem;}    
}

/* font color */
.font-red {color: var(--yr500)!important;}
.font-gr {color: var(--gray500)!important;}
.font-blue {color: var(--blue)!important;}

/* font weight */
.font500 {font-weight: 500;}
.font600 {font-weight: 600;}
.font700 {font-weight: 700;}

/* text align */
.align-left {text-align: left;}
.align-center {text-align: center;}
.align-right {text-align: right;}

/* tag */
.tag {display: flex; align-items: center; justify-content: center; width: fit-content; height: fit-content; padding: .4rem; font-size: 1rem; font-weight: 600; color: var(--yr500); border: 1px solid var(--yr500); border-radius: .2rem;}
.new {width: 1.6rem; height: 1.6rem; display: inline-flex; align-items: center; justify-content: center; margin-right: .4rem; background-color: var(--yr500); font-size: 1.2rem; font-weight: 500; color: #fff; border-radius: .2rem; position: relative; top: -1px;}

.tag.line-wh {border-color: var(--yr100); color: var(--yr100);}
.tag.gr {border-color: var(--gray400); color: #fff; background-color: var(--gray400);}
.tag.line-gr {border-color: var(--gray400); color: var(--gray400); background-color:transparent;}
.tag.red {border-color: var(--yr500); color: #fff; background-color: var(--yr500);}
.tag.navy {border-color: var(--pb900); color: #fff; background-color: var(--pb900);}
.tag.pb {border-color: var(--pb800); color: #fff; background-color: var(--pb800);}

@media screen and (max-width: 820px) {
    .tag {padding: .2rem; font-size: 1rem;}
}

/* label */
.label {display: flex; align-items: center; justify-content: center; width: fit-content; height: 2rem; margin: 0 auto; padding: 0 .4rem; font-size: 1.2rem; font-weight: 600; line-height: 1; border-radius: .2rem; background-color: var(--yr500); color: #fff;}
.label.navy {background-color: var(--pb900);}
.label.gr {background-color: var(--gray400);}

/* input : basic */
input {position: relative; width: 100%; height: 4.8rem; padding: 0 1.6rem; border: 1px solid var(--gray200); background-color: var(--white01); border-radius: .4rem; font-size: 1.6rem; font-weight: 500; cursor: auto; color: var(--black);}
input::placeholder {color: var(--gray400);}

@media screen and (max-width: 1024px) {
    input {height: 4.4rem; padding: 0 1.2rem; font-size: 1.4rem;}
}

/* input : checkbox, radio */
input[type='checkbox'] {display: none;}
input[type='checkbox']:disabled + label {cursor: default;}
input[type='checkbox'] + label {position: relative; display: flex; align-items: center; cursor: pointer;}
input[type='checkbox'] + label::before {content: ''; display: block; width: 2rem; height: 2rem; border-radius: 100%; background-image: url(/imgs/ico/ic-checkbox-20.svg); background-repeat: no-repeat; background-size: contain; background-position: center;}
input[type='checkbox']:checked + label::before {background-image: url(/imgs/ico/ic-checkbox-ov-20.svg);}
input[type='checkbox'] + label > span {display: block; margin-left: .6rem; font-size: 1.4rem; font-weight: 500; color: var(--gray500);}

@media (prefers-color-scheme: dark) {
    input[type='checkbox'] + label::before {background-image: url(/imgs/ico/ic-checkbox-20-dark.svg);}
    input[type='checkbox']:checked + label::before {background-image: url(/imgs/ico/ic-checkbox-ov-20.svg);}
}
[data-theme="dark"] input[type='checkbox'] + label::before {background-image: url(/imgs/ico/ic-checkbox-20-dark.svg);}
[data-theme="dark"] input[type='checkbox']:checked + label::before {background-image: url(/imgs/ico/ic-checkbox-ov-20.svg);}

input[type='radio'] {display: none;}
input[type='radio']:disabled + label {cursor: default;}
input[type='radio'] + label {position: relative; display: flex; align-items: center; cursor: pointer;}
input[type='radio'] + label::before {content: ''; display: block; width: 2rem; height: 2rem; border-radius: 100%; background-image: url(/imgs/ico/ic-radio-20.svg); background-repeat: no-repeat; background-size: contain; background-position: center;}
input[type='radio']:checked + label::before {background-image: url(/imgs/ico/ic-radio-ov-20.svg);}
input[type='radio'] + label > span {display: block; margin-left: .6rem; font-size: 1.4rem; font-weight: 500; color: var(--gray500);}

@media (prefers-color-scheme: dark) {
    input[type='radio'] + label::before {background-image: url(/imgs/ico/ic-radio-20-dark.svg);}
    input[type='radio']:checked + label::before {background-image: url(/imgs/ico/ic-radio-ov-20-dark.svg);}
}
[data-theme="dark"] input[type='radio'] + label::before {background-image: url(/imgs/ico/ic-radio-20-dark.svg);}
[data-theme="dark"] input[type='radio']:checked + label::before {background-image: url(/imgs/ico/ic-radio-ov-20-dark.svg);}

/* input : file */
.input-file input[type='file'] {display: none;}
.input-file > label {width: 100%; display: flex; align-items: center; justify-content: space-between;}
.input-file > label input[type='text'] {width: calc(100% - 12.8rem);}
.input-file > label button {width: 12rem; height: 4.8rem; font-size: 1.6rem; background-color: var(--gray400); border-color: var(--gray400);}

@media screen and (max-width: 1024px) {
    .input-file > label input[type='text'] {width: calc(100% - 10.2rem);}
    .input-file > label button {width: 9.4rem; height: 4.4rem; font-size: 1.4rem;}
}

/* select */
select {width: 100%; height: 4.8rem; border-radius: .4rem; background-color: var(--white01); border: 1px solid var(--gray200); font-size: 1.6rem; font-weight: 500; padding: 0 4.8rem 0 1.6rem; background-image: url(/imgs/ico/ic-select-arrow-16.svg); background-repeat: no-repeat; background-size: 1.6rem; background-position: right 1.6rem center; cursor: pointer; -webkit-appearance: none; appearance: none; color: var(--black);}
select::-ms-expand {display: none;/*for IE10,11*/}

@media (prefers-color-scheme: dark) {
    select {background-image: url(/imgs/ico/ic-select-arrow-16-dark.svg);}
}
[data-theme="dark"] select {background-image: url(/imgs/ico/ic-select-arrow-16-dark.svg);}
@media screen and (max-width: 1024px) {
    select {height: 4.4rem; font-size: 1.4rem; padding: 0 4rem 0 1.2rem; background-position: right 1.2rem center;}
}

/* textarea */
textarea {position: relative; width: 100%; height: 20rem; padding: 1.6rem; border: 1px solid var(--gray200); background-color: var(--white01); border-radius: .4rem; font-size: 1.6rem; font-weight: 500; cursor: auto; color: var(--black); line-height: 1.6;}
textarea::placeholder {color: var(--gray400);}

@media screen and (max-width: 1024px) {
    textarea {font-size: 1.4rem; padding: 1.2rem;}
}

/* button */
.btn-wrap {display: flex; align-items: center; justify-content: center; margin-top: 6rem;}
.btn-wrap button {min-width: 22rem; height: 4.8rem; font-size: 1.6rem;}
.btn-wrap button + button {margin-left: 1.2rem;}

button {display: flex; align-items: center; justify-content: center; width: fit-content; height: 5.2rem; font-size: 1.8rem; font-weight: 600; color: #fff; background-color: var(--yr500); border: 1px solid var(--yr500); border-radius: .4rem; cursor: pointer;}
button:disabled {opacity: .4;}
button.md {min-width: 17.2rem; height: 4.8rem; font-size: 1.6rem;}
button.sm {height: 4.4rem; padding: 0 2.8rem; font-size: 1.4rem;}
button.x-sm {height: 3.2rem; padding: 0 .8rem; font-size: 1.4rem; border-radius: .2rem;}
button > * + * {margin-left: .2rem;}

button.pb {background-color: var(--pb900); border-color: var(--pb900);}
button.light-pb {background-color: var(--pb50); border-color: var(--pb50); color: var(--pb500);}
button.gr {background-color: var(--gray400); border-color: var(--gray400);}
button.red {background-color: var(--red); border-color: var(--red);}
button.green {background-color: #00C300; border-color: #00C300; color: var(--white01);}
button.line-gr {background-color: var(--white01); border-color: var(--gray200); color: var(--gray500);}
button.blue {background-color: var(--blue); border-color: var(--blue); color: #fff;}

@media (prefers-color-scheme: dark) {
    button.pb {background-color: var(--pb800);}
}
[data-theme="dark"] button.pb {background-color: var(--pb800);}
@media screen and (max-width: 1024px) {
    .btn-wrap {margin-top: 4rem;}
    .btn-wrap button {min-width: calc((100% - 1.2rem) / 2); height: 4.4rem; font-size: 1.4rem;}

    button {height: 4.8rem; font-size: 1.6rem;}
    button.md {height: 4.4rem; font-size: 1.4rem;}
    button.x-sm {height: 2.8rem; padding: 0 2rem; font-size: 1.2rem; border-radius: .2rem;}
    button > * + * {margin-left: .4rem;}
}
@media screen and (max-width: 820px) {
    .btn-wrap {margin-top: 3.2rem;}
}

/* toggle */
.toggle-wrap {display: flex; align-items: center; column-gap: .8rem;}
.toggle-wrap > p {font-size: 1.4rem; font-weight: 500; color: var(--gray500);}

.toggle {display: block; width: 4.6rem; height: 2.4rem; position: relative;}
.toggle > input[type="checkbox"] + span {display: block; width: 100%; height: 100%; border-radius: 2rem; background-color: var(--gray300); -webkit-transition: .3s; transition: .3s; position: absolute; top: 0; left: 0; right: 0; bottom: 0; cursor: pointer;}
.toggle > input[type="checkbox"] + span::before {content: ''; width: 2rem; height: 2rem; border-radius: 100%; background-color: #fff; box-shadow: .2rem 0 .4rem rgba(0, 0, 0, .1); -webkit-transition: .3s; transition: .3s; position: absolute; left: .2rem; bottom: .2rem;}
.toggle > input[type="checkbox"]:checked + span {background-color: var(--yr500);}
.toggle > input[type="checkbox"]:checked + span::before {-webkit-transform: translateX(2.2rem); -ms-transform: translateX(2.2rem); transform: translateX(2.2rem);}

@media screen and (max-width: 820px) {
    .toggle-wrap {column-gap: .6rem;}
    .toggle-wrap > p {font-size: 1.2rem;}
    
    .toggle {width: 3.2rem; height: 1.8rem;}
    .toggle > input[type="checkbox"] + span::before {width: 1.4rem; height: 1.4rem;}
    
}

/* tit tab style */
.tit-tab {display: flex; align-items: center;}
.tit-tab > li {position: relative; font-size: 1.6rem; font-weight: 500; color: var(--gray400); cursor: pointer;}
.tit-tab > li + li {margin-left: 1.2rem;}
.tit-tab > li.active {font-weight: 600; color: var(--black);}
.tit-tab > li.active::before {content: ''; width: .4rem; height: .4rem; border-radius: 100%; background-color: var(--black); position: absolute; top: -.8rem; left: 50%; -webkit-transform: translateX(-50%); -moz-transform: translateX(-50%); -ms-transform: translateX(-50%); -o-transform: translateX(-50%); transform: translateX(-50%);}

@media screen and (max-width: 820px) {
    .tit-tab > li {font-size: 1.4rem;}
}

.tit-tab-con {display: none;}
.tit-tab-con.active {display: block;}


/* tab style01 */
.con-box + .tab-style01 {margin-top: 6rem;}
.tab-style01 {position: relative; width: 100%; display: flex; align-items: center; background-color: var(--white01); margin-bottom: 6rem; border-radius: .8rem;} 
.tab-style01::before {content: ''; width: 100%; height: 100%; display: block; position: absolute; top: 0; left: 0; border: 1px solid var(--gray200); border-radius: .8rem;}
.tab-style01 > li {position: relative; height: 6rem; text-align: center; font-size: 1.8rem; font-weight: 500; line-height: 1.2; color: var(--gray500); display: flex; align-items: center; justify-content: center; border: 1px solid transparent; border-radius: .8rem; cursor: pointer; word-break: keep-all;}
.tab-style01 > li:nth-child(1):nth-last-child(2), .tab-style01 > li:nth-child(1):nth-last-child(2) ~ li {width: calc(100% / 2);}
.tab-style01 > li:nth-child(1):nth-last-child(3), .tab-style01 > li:nth-child(1):nth-last-child(3) ~ li {width: calc(100% / 3);}
.tab-style01 > li:nth-child(1):nth-last-child(4), .tab-style01 > li:nth-child(1):nth-last-child(4) ~ li {width: calc(100% / 4);}
.tab-style01 > li:nth-child(1):nth-last-child(5), .tab-style01 > li:nth-child(1):nth-last-child(5) ~ li {width: calc(100% / 5);}
.tab-style01 > li:nth-child(1):nth-last-child(6), .tab-style01 > li:nth-child(1):nth-last-child(6) ~ li {width: calc(100% / 6);}
.tab-style01 > li + li::before {content: ''; display: block; width: 1px; height: 1.2rem; background-color: var(--gray400); position: absolute; left: 0;}

.tab-style01 > li.active {border-color: var(--yr500); background-color: var(--yr50); color: var(--yr500); font-weight: 600;}
.tab-style01 > li.active > a {color: var(--yr500); font-weight: 600;}
.tab-style01 > li.active::before {content: none;}
.tab-style01 > li.active + li::before {content: none;}

/* 구단별 tab 색상 */
.db .tab-style01 > li.active {border-color: var(--db-point); background-color: rgba(0, 57, 159, .16); color: var(--db-point);}
.ss .tab-style01 > li.active {border-color: var(--ss-point); background-color: rgba(255, 184, 28, .16); color: var(--ss-point);}
.sk .tab-style01 > li.active {border-color: var(--sk-point); background-color: rgba(255, 150, 0, .16); color: var(--sk-point);}
.lg .tab-style01 > li.active {border-color: var(--lg-point); background-color: rgba(255, 186, 0, .16); color: var(--lg-point);}
.el .tab-style01 > li.active {border-color: var(--el-point); background-color: rgba(190, 21, 64, .16); color: var(--el-point);}
.kcc .tab-style01 > li.active {border-color: var(--kcc-point); background-color: rgba(255, 157, 0, .16); color: var(--kcc-point);}
.or .tab-style01 > li.active {border-color: var(--or-point); background-color: rgba(30, 0, 0, .16); color: var(--or-point);}
.kgc .tab-style01 > li.active {border-color: var(--kgc-point); background-color: rgba(23, 23, 23, .16); color: var(--kgc-point);}
.kt .tab-style01 > li.active {border-color: var(--kt-point); background-color: rgba(240, 124, 34, .16); color: var(--kt-point);}
.hd .tab-style01 > li.active {border-color: var(--hd-point); background-color: rgba(237, 28, 36, .16); color: var(--hd-point);}

@media (prefers-color-scheme: dark) {
    .tab-style01 > li.active {background-color: var(--gray800);}
    .kgc .tab-style01 > li.active {border-color: var(--kgc-sub01); background-color: rgba(241, 203, 209, .16); color: var(--kgc-sub01);}
    .or .tab-style01 > li.active {border-color: var(--or-sub01); background-color: rgba(255, 211, 194, .16); color: var(--or-sub01);}
}
[data-theme="dark"] .tab-style01 > li.active {background-color: var(--gray800);}
[data-theme="dark"] .kgc .tab-style01 > li.active {border-color: var(--kgc-sub01); background-color: rgba(241, 203, 209, .16); color: var(--kgc-sub01);}
[data-theme="dark"] .or .tab-style01 > li.active {border-color: var(--or-sub01); background-color: rgba(255, 211, 194, .16); color: var(--or-sub01);}

@media screen and (max-width: 1024px) {
    .con-box + .tab-style01 {margin-top: 4rem;}
    .tab-style01 {margin-bottom: 4rem; border-radius: .4rem;}
    .tab-style01::before {border-radius: .4rem;}
    .tab-style01 > li {height: 4.8rem; font-size: 1.4rem; border-radius: .4rem;}
}
@media screen and (max-width: 820px) {
    .con-box + .tab-style01 {margin-top: 3.2rem;}
    .tab-style01 {margin-bottom: 3.2rem;}
    .tab-style01 > li {min-height: 4.4rem; font-size: 1.3rem; border-radius: 0; padding: 1.2rem 0;}
    .tab-style01 > li + li::before {height: .8rem;}

    .tab-style01 > li:first-child {border-top-left-radius: .4rem; border-bottom-left-radius: .4rem;}
    .tab-style01 > li:last-child {border-top-right-radius: .4rem; border-bottom-right-radius: .4rem;}
}

.tab-con {display: none;}
.tab-con.active {display: block;}

/* 구단별 filter 색상 */
.db .filter-wrap {background-color: var(--db-sub01);}
.db .filter-wrap > li:not(:nth-child(4))::after {background-color: var(--db-sub02);}
.db .filter-wrap > li h6 {color: var(--db-sub02);}
.ss .filter-wrap {background-color: var(--ss-sub01);}
.ss .filter-wrap > li:not(:nth-child(4))::after {background-color: var(--ss-sub02);}
.ss .filter-wrap > li h6 {color: var(--ss-sub02);}
.sk .filter-wrap {background-color: var(--sk-sub01);}
.sk .filter-wrap > li:not(:nth-child(4))::after {background-color: var(--sk-sub02);}
.sk .filter-wrap > li h6 {color: var(--sk-sub02);}
.lg .filter-wrap {background-color: var(--lg-sub01);}
.lg .filter-wrap > li:not(:nth-child(4))::after {background-color: var(--lg-sub02);}
.lg .filter-wrap > li h6 {color: var(--lg-sub02);}
.el .filter-wrap {background-color: var(--el-sub01);}
.el .filter-wrap > li:not(:nth-child(4))::after {background-color: var(--el-sub02);}
.el .filter-wrap > li h6 {color: var(--el-sub02);}
.kcc .filter-wrap {background-color: var(--kcc-sub01);}
.kcc .filter-wrap > li:not(:nth-child(4))::after {background-color: var(--kcc-sub02);}
.kcc .filter-wrap > li h6 {color: var(--kcc-sub02);}
.or .filter-wrap {background-color: var(--or-sub01);}
.or .filter-wrap > li:not(:nth-child(4))::after {background-color: var(--or-sub02);}
.or .filter-wrap > li h6 {color: var(--or-sub02);}
.kgc .filter-wrap {background-color: var(--kgc-sub01);}
.kgc .filter-wrap > li:not(:nth-child(4))::after {background-color: var(--kgc-sub02);}
.kgc .filter-wrap > li h6 {color: var(--kgc-sub02);}
.kt .filter-wrap {background-color: var(--kt-sub01);}
.kt .filter-wrap > li:not(:nth-child(4))::after {background-color: var(--kt-sub02);}
.kt .filter-wrap > li h6 {color: var(--kt-sub02);}
.hd .filter-wrap {background-color: var(--hd-sub01);}
.hd .filter-wrap > li:not(:nth-child(4))::after {background-color: var(--hd-sub02);}
.hd .filter-wrap > li h6 {color: var(--hd-sub02);}
.youth .filter-wrap {background-color: var(--pb700);}
.youth .filter-wrap > li:not(:nth-child(4))::after {background-color: var(--pb300);}
.youth .filter-wrap > li h6 {color: var(--pb200);}

/* filter wrap */
.tab-style01 ~ .filter-wrap, .tab-style01 ~ * .filter-wrap {margin-top: -4rem;}

.filter-wrap {width: 100%; background-color: var(--pb500); border-radius: .8rem; margin-bottom: 6rem; display: flex; align-items: center;}
.filter-wrap > li {position: relative; width: calc(100% / 4); height: 6rem; padding: 0 2rem; display: flex; align-items: center;}
.filter-wrap > li:nth-child(1):nth-last-child(5), .filter-wrap > li:nth-child(1):nth-last-child(5) ~ li {width: calc(100% / 5);}
.filter-wrap > li:not(:last-child)::after {content: ''; width: 1px; height: 1.2rem; display: block; background-color: var(--pb300); position: absolute; right: 0;}
.filter-wrap > li h6 {font-size: 1.4rem; font-weight: 500; color: var(--pb200); white-space: nowrap;}
.filter-wrap > li select {border: transparent; height: 100%; padding: 0 2.8rem 0 1.6rem; font-size: 1.8rem; font-weight: 600; background-color: transparent; color: #fff; background-image: url(/imgs/ico/ic-select-arrow-16-wh.svg); background-position: center right;}
.filter-wrap > li select option {background-color: var(--white01); color: var(--black);}

@media screen and (max-width: 1024px) {
    .tab-style01 ~ .filter-wrap, .tab-style01 ~ * .filter-wrap {margin-top: -2.4rem;}

    .filter-wrap {border-radius: .4rem; margin-bottom: 4rem;}
    .filter-wrap > li {height: 4.8rem;}
    .filter-wrap > li h6 {font-size: 1.2rem;}
    .filter-wrap > li select {font-size: 1.4rem;}
}
@media screen and (max-width: 820px) {
    .tab-style01 ~ .filter-wrap, .tab-style01 ~ * .filter-wrap {margin-top: -2rem;}
    
    .filter-wrap {margin-bottom: 3.2rem; flex-flow: wrap;}
    .filter-wrap > li {width: 100%; height: 4.4rem; padding: 0 1.2rem;}
    .filter-wrap > li:not(:nth-child(4))::after {content: none;}
    .filter-wrap > li h6 {display: none;}
    .filter-wrap > li select {padding: 0 2.8rem 0 0; font-size: 1.4rem;}

    .filter-wrap > li:nth-child(1):nth-last-child(2), .filter-wrap > li:nth-child(1):nth-last-child(2) ~ li {width: 50%;} 
    .filter-wrap > li:nth-child(1):nth-last-child(2) ~ li:nth-child(2)::before {content: ''; width: 1px; height: 1.2rem; background-color: var(--pb300); position: absolute; left: 0;}

    .filter-wrap > li:nth-child(1):nth-last-child(3) {width: 100%; border-bottom: 1px solid var(--pb300);} 
    .filter-wrap > li:nth-child(1):nth-last-child(3) ~ li:nth-child(2) {width: 50%;} 
    .filter-wrap > li:nth-child(1):nth-last-child(3) ~ li:nth-child(3) {width: 50%;} 
    .filter-wrap > li:nth-child(1):nth-last-child(3) ~ li:nth-child(3)::before {content: ''; width: 1px; height: 1.2rem; background-color: var(--pb300); position: absolute; left: 0;}

    .filter-wrap > li:nth-child(1):nth-last-child(4), .filter-wrap > li:nth-child(1):nth-last-child(4) ~ li {width: 50%;} 
    .filter-wrap > li:nth-child(1):nth-last-child(4) ~ li:nth-child(2)::before {content: ''; width: 1px; height: 1.2rem; background-color: var(--pb300); position: absolute; left: 0;}
    .filter-wrap > li:nth-child(1):nth-last-child(4) ~ li:nth-child(4)::before {content: ''; width: 1px; height: 1.2rem; background-color: var(--pb300); position: absolute; left: 0;}


    .filter-wrap > li:nth-child(1):nth-last-child(5), .filter-wrap > li:nth-child(1):nth-last-child(5) ~ li {width: 50%;}
    .filter-wrap > li:nth-child(1):nth-last-child(5) ~ li:nth-child(2)::before {content: ''; width: 1px; height: 1.2rem; background-color: var(--pb300); position: absolute; left: 0;}
    .filter-wrap > li:nth-child(1):nth-last-child(5) ~ li:nth-child(4)::before {content: ''; width: 1px; height: 1.2rem; background-color: var(--pb300); position: absolute; left: 0;}
}

/* sticky wrap */
.sticky-wrap {position: sticky; position: -webkit-sticky; z-index: 50; top: 14.925rem; background-color: #FFFFFF}
@media screen and (max-width: 1024px) {
    .sticky-wrap {top: 6rem;}
}
@media (prefers-color-scheme: dark) {
    .sticky-wrap {background-color: #000;}
}
[data-theme="dark"] .sticky-wrap {background-color: #000;}
    /* search con */
.filter-wrap ~ .con-search {margin-top: -4rem;}

.con-search {width: 100%; margin-bottom: 6rem; display: flex; align-items: center; justify-content: space-between;}
.search-input {position: relative;}
.search-input input {width: 32rem; padding-right: 5.2rem;}
.search-input button {width: fit-content; height: fit-content; background-color: transparent; border-color: transparent; position: absolute; top: 50%; right: 1.6rem; -webkit-transform: translateY(-50%); -moz-transform: translateY(-50%); -ms-transform: translateY(-50%); -o-transform: translateY(-50%); transform: translateY(-50%);}

.con-search .sort-wrap {width: calc(100% - 34rem); height: 4.8rem; padding: 0 1.6rem; border-radius: .4rem; background-color: var(--gray100); display: flex; align-items: center;}
.con-search .sort-wrap > li {position: relative; display: flex; align-items: center; justify-content: center; font-size: 1.6rem; font-weight: 500; color: var(--gray400); cursor: pointer;}
.con-search .sort-wrap > li + li::before {content: ''; width: 1px; height: 1.6rem; background-color: var(--gray300); margin: 0 .8rem;}
.con-search .sort-wrap > li.active {font-weight: 600; color: var(--black);}

@media (prefers-color-scheme: dark) {
    .search-input button i {filter: invert(1);}
}
[data-theme="dark"] .search-input button i {filter: invert(1);}
@media screen and (max-width: 1024px) {
    .filter-wrap ~ .con-search {margin-top: -2.4rem;}
    
    .con-search {margin-bottom: 4rem;}
    .search-input input {width: 24rem; padding-right: 4.4rem;}
    .search-input button {right: 1.2rem;}
    
    .con-search .sort-wrap {width: calc(100% - 25.2rem); height: 4.4rem;}
    .con-search .sort-wrap > li {font-size: 1.4rem;}
    .con-search .sort-wrap > li + li::before {height: 1.2rem;}
}
@media screen and (max-width: 820px) {
    .filter-wrap ~ .con-search {margin-top: -2rem;}
    
    .con-search {margin-bottom: 3.2rem; flex-flow: column;}
    .search-input {width: 100%;}
    .search-input input {width: 100%; padding-right: 4.4rem;}
    .search-input button {right: 1.2rem;}
    .search-input button i {width: 1.6rem; height: 1.6rem;}
    
    .con-search .sort-wrap {width: 100%; height: auto; display: grid; grid-template-columns: repeat(11, 1fr); padding: 1.6rem 1.2rem; row-gap: 1.2rem; margin-bottom: 1.2rem;}
    .con-search .sort-wrap > li {font-size: 1.4rem;}
    .con-search .sort-wrap > li + li::before {margin: 0; position: absolute; left: 0;}
    .con-search .sort-wrap > li:nth-of-type(12n)::before {content: none;}
    .con-search .sort-wrap > li:nth-child(1):nth-last-child(11), .con-search .sort-wrap > li:nth-child(1):nth-last-child(11) ~ li {width: calc(100% / 11);}
}

/* table */
.table-desc {display: flex; align-items: center; justify-content: end; margin-bottom: 1.6rem;}
.table-desc p {font-size: 1.4rem; color: var(--gray500); line-height: 1.6;}

table {width: 100%; border-collapse: collapse; border-spacing: 0; position: relative;}
table::before {content: ''; width: 100%; height: .1rem; background-color: var(--black); position: absolute; top: 0; left: 0;}
table thead th {height: 4rem; padding: 1.2rem 1.6rem; text-align: center; font-size: 1.4rem; font-weight: 500; line-height: 1.4; border-bottom: 1px solid var(--gray100); background-color: var(--gray100); vertical-align: middle;}
table thead th[rowspan]:not(:last-child) {border-right: 1px solid var(--gray200);}
table thead th[colspan] {border-bottom: 1px solid var(--gray200);}
table thead th[colspan] {border-bottom: 1px solid var(--gray200);}
table thead th + * {border-left: 1px solid var(--gray200);}
table thead + tbody td {height: 6rem; padding: 1.2rem 1.6rem; text-align: center; font-size: 1.6rem; line-height: 1.4; border-bottom: 1px solid var(--gray200); background-color: var(--white01); vertical-align: middle; word-break: keep-all;}
table thead + tbody td + * {border-left: 1px solid var(--gray200);}
table tbody th {height: 6rem; padding: 1.2rem 1.6rem; text-align: center; font-size: 1.6rem; font-weight: 500; line-height: 1.4; border-bottom: 1px solid var(--gray200); background-color: var(--gray100); vertical-align: middle; word-break: keep-all;}
table tbody th[rowspan] {border-right: 1px solid var(--gray200);}
table tbody th + * {border-left: 1px solid var(--gray200);}
table tbody td {height: 6rem; padding: 1.2rem 1.6rem; text-align: center; text-align: center; font-size: 1.6rem; line-height: 1.4; border-bottom: 1px solid var(--gray200); background-color: var(--white01); vertical-align: middle; word-break: keep-all;}
table tbody td input {height: 4.4rem;}
table tbody td button {margin: 0 auto;}
table .title-ellipsis {width: 60rem; display: block; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
table input + label::before {margin: 0 auto;} 

table.date-table tbody td {height: 4rem;}

.table-style01 thead th + * {border-left: 0;}
.table-style01 thead + tbody td + * {border-left: 0;}
.table-style01 tbody th + * {border-left: 0;}

@media (prefers-color-scheme: dark) {
    table::before {background-color: var(--gray600);}
}
[data-theme="dark"] table::before {background-color: var(--gray600);}
/* default scroll-table */
.scroll-table {width: 100%; overflow-y: auto;}
.scroll-table table td[rowspan]:not(:last-child) {border-right: 1px solid var(--gray200);}
.scroll-table table th,
.scroll-table table td {white-space: nowrap;}
.scroll-table th i {display: inline-flex; margin-left: .4rem; cursor: pointer;}
@media screen and (max-width: 820px) {
    .scroll-table {width: 100vw; margin: 0 -2rem;}
}

/* top scroll-table */
.top-scroll-table {width: 100%; overflow-y: auto; transform: rotateX(180deg);}
.top-scroll-table table {transform: rotateX(180deg);}
.top-scroll-table table td[rowspan]:not(:last-child) {border-right: 1px solid var(--gray200);}
.top-scroll-table table th,
.top-scroll-table table td {white-space: nowrap;}
.top-scroll-table th i {display: inline-flex; margin-left: .4rem; cursor: pointer;}

@media screen and (max-width: 820px) {
    .con-box > .top-scroll-table {width: 100vw; margin: 0 -1.6rem;}
}
@media (prefers-color-scheme: dark) {
    table thead th {background-color: var(--gray200);}
}
[data-theme="dark"] table thead th {background-color: var(--gray200);}
@media screen and (max-width: 1024px) {
    table thead th {height: 2.8rem; padding: .8rem 1.2rem; font-size: 1.2rem;}
    table thead + tbody td {height: 4.4rem; padding: .8rem 1.2rem; font-size: 1.4rem;}
    table tbody th {height: 4.4rem; padding: .8rem 1.2rem; font-size: 1.4rem;}
    table tbody td {height: 4.4rem; padding: .8rem 1.2rem; font-size: 1.4rem;}
    table .title-ellipsis {width: 40rem;}
}
@media screen and (max-width: 820px) {
    table .title-ellipsis {width: 20rem;}
    table .title-ellipsis + * {margin-top: .4rem;}
}

/* team name */
table .team-name {justify-content: center;}
table .team-name p {font-size: 1.6rem; font-weight: initial; text-align: left;}

.team-name {display: flex; align-items: center;}
.team-name > i {width: 4rem; height: 2.6rem; margin-right: .4rem;}
.team-name > p {font-size: 1.4rem; font-weight: 500; min-width: calc(100% - 4.4rem);}
.team-name.lg > p {font-size: 2.4rem; font-weight: 600; line-height: 1.2; min-width: calc(100% - 4.4rem);}
.team-name.sm > i {width: 3rem; height: 2rem;}
.team-name.sm > p {font-size: 1.2rem; min-width: calc(100% - 3rem);}

@media screen and (max-width: 1024px) {
    table .team-name p {font-size: 1.4rem;}

    .team-name > i {width: 3rem; height: 2rem;}
    .team-name.lg > p {font-size: 1.6rem;}
}

.pagination {margin-top: 6rem; display: flex; align-items: center; justify-content: center;}
.pagination > li {width: 3.2rem; height: 3.2rem; font-size: 1.4rem; font-weight: 500; color: var(--gray400); background-color: var(--white01); display: flex; align-items: center; justify-content: center; background-repeat: no-repeat; background-size: 1.6rem; background-position: center; cursor: pointer; border-top: 1px solid var(--gray200); border-bottom: 1px solid var(--gray200);}
.pagination > li + li {border-left: 1px solid var(--gray200);}
.pagination > li.active {border-color: var(--yr500)!important; color: var(--yr500); font-weight: 600; background-color: var(--yr50);}
.pagination > li.active + li {border-left-color: var(--yr500);}

.pagination > li.first {background-image: url(/imgs/ico/ic-pagination-first.svg); border-left: 1px solid var(--gray200)!important; border-radius: .4rem 0 0 .4rem;}
.pagination > li.prev {background-image: url(/imgs/ico/ic-pagination-prev.svg); border-right: 1px solid var(--gray200)!important; border-radius: 0 .4rem .4rem 0; margin-right: 1.2rem;}
.pagination > li.next {background-image: url(/imgs/ico/ic-pagination-next.svg); border-left: 1px solid var(--gray200)!important; border-radius: .4rem 0 0 .4rem; margin-left: 1.2rem;}
.pagination > li.last {background-image: url(/imgs/ico/ic-pagination-last.svg); border-right: 1px solid var(--gray200)!important; border-radius: 0 .4rem .4rem 0;}
.pagination > li.first.disabled {background-image: url(/imgs/ico/ic-pagination-first-disabled.svg); cursor: default;}
.pagination > li.prev.disabled {background-image: url(/imgs/ico/ic-pagination-prev-disabled.svg); cursor: default;}
.pagination > li.next.disabled {background-image: url(/imgs/ico/ic-pagination-next-disabled.svg); cursor: default;}
.pagination > li.last.disabled {background-image: url(/imgs/ico/ic-pagination-last-disabled.svg); cursor: default;}
.pagination > li:nth-child(3) {border-radius: .4rem 0 0 .4rem;}
.pagination > li:nth-last-child(3) {border-radius: 0 .4rem .4rem 0; border-right: 1px solid var(--gray200);}

@media (prefers-color-scheme: dark) {
    .pagination > li.active {background-color: var(--gray800);}

    .pagination > li.first {background-image: url(/imgs/ico/ic-pagination-first-disabled.svg);}
    .pagination > li.prev {background-image: url(/imgs/ico/ic-pagination-prev-disabled.svg);}
    .pagination > li.next {background-image: url(/imgs/ico/ic-pagination-next-disabled.svg);}
    .pagination > li.last {background-image: url(/imgs/ico/ic-pagination-last-disabled.svg);}
    .pagination > li.first.disabled {background-image: url(/imgs/ico/ic-pagination-first.svg);}
    .pagination > li.prev.disabled {background-image: url(/imgs/ico/ic-pagination-prev.svg);}
    .pagination > li.next.disabled {background-image: url(/imgs/ico/ic-pagination-next.svg);}
    .pagination > li.last.disabled {background-image: url(/imgs/ico/ic-pagination-last.svg);}
}
[data-theme="dark"] .pagination > li.active {background-color: var(--gray800);}

[data-theme="dark"] .pagination > li.first {background-image: url(/imgs/ico/ic-pagination-first-disabled.svg);}
[data-theme="dark"] .pagination > li.prev {background-image: url(/imgs/ico/ic-pagination-prev-disabled.svg);}
[data-theme="dark"] .pagination > li.next {background-image: url(/imgs/ico/ic-pagination-next-disabled.svg);}
[data-theme="dark"] .pagination > li.last {background-image: url(/imgs/ico/ic-pagination-last-disabled.svg);}
[data-theme="dark"] .pagination > li.first.disabled {background-image: url(/imgs/ico/ic-pagination-first.svg);}
[data-theme="dark"] .pagination > li.prev.disabled {background-image: url(/imgs/ico/ic-pagination-prev.svg);}
[data-theme="dark"] .pagination > li.next.disabled {background-image: url(/imgs/ico/ic-pagination-next.svg);}
[data-theme="dark"] .pagination > li.last.disabled {background-image: url(/imgs/ico/ic-pagination-last.svg);}
@media screen and (max-width: 1024px) {
    .pagination {margin-top: 4rem;}
}
@media screen and (max-width: 820px) {
    .pagination {margin-top: 3.2rem;}
}

/* empty con */
.con-empty {width: 100%; padding: 8rem 0; display: flex; flex-flow: column; align-items: center; justify-content: center;}
.con-empty * + * {margin-top: 1.2rem;}
.con-empty i {width: 12rem; height: 12rem;}
.con-empty h6 {font-size: 1.6rem; font-weight: 500; line-height: 1.6; text-align: center;}
.con-empty p {font-size: 1.4rem; color: var(--gray500); line-height: 1.6; text-align: center;}
.con-empty .logo {width: 38.5rem; height: 16rem; background-image: url(/imgs/logo/logo-kbl.svg); background-repeat: no-repeat; background-size: 20rem; background-position: center;}
.con-empty .notice h6 {font-size: 2.5rem; color: black;}
.con-empty .notice p {font-size: 2rem; color: black;}

@media screen and (max-width: 820px) {
    .con-empty {padding: 10rem 0;}
    .con-empty i {width: 8rem; height: 8rem;}
    .con-empty h6 {font-size: 1.4rem;}
    .con-empty p {font-size: 1.2rem;}
    .con-empty .notice h6 {font-size: 2.25rem; color: black;}
    .con-empty .notice p {font-size: 1.8rem; color: black;}
}

/* popup */
.popup {width: 100vw; height: 100vh; position: fixed!important; top: 0; left: 0; background-color: rgba(0, 0, 0, .6); z-index: 999!important;}
.popup-top {z-index: 9999!important;}
@media (prefers-color-scheme: dark) {
    .popup {width: 100vw; height: 100vh; position: fixed!important; top: 0; left: 0; background-color: rgba(0, 0, 0, .6); z-index: 999!important;}
    .popup-top {z-index: 9999!important;}
}
[data-theme="dark"] .popup {width: 100vw; height: 100vh; position: fixed!important; top: 0; left: 0; background-color: rgba(0, 0, 0, .6); z-index: 999!important;}
[data-theme="dark"] .popup-top {z-index: 9999!important;}

.popup .pop-wrap {width: 44rem; border-radius: 1.2rem; background-color: var(--white02); position: absolute; top: 50%; left: 50%; -webkit-transform: translate(-50%, -50%); -moz-transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%); -o-transform: translate(-50%, -50%); transform: translate(-50%, -50%);}
.popup .pop-wrap.large {width: 72rem;}

.popup .pop-tit {position: relative; padding: 2.4rem; display: flex; align-items: center; justify-content: center; border-bottom: 1px solid var(--gray200);}
.popup .pop-tit h4 {font-size: 2.4rem; font-weight: 600;}
.popup .pop-tit i {position: absolute; right: 2.4rem; cursor: pointer;}
.popup .pop-tit + .pop-con {padding: 3.2rem 2.4rem;}

.popup .pop-con {max-height: calc(80vh - 7.3rem - 7.6rem); overflow-y: auto; padding: 4rem 2.4rem;}
.popup .pop-con > * + * {margin-top: 3.2rem;}
.popup .pop-con img {width: 100%;}
.popup .pop-con p {text-align: center; font-size: 1.6rem; line-height: 1.6; font-weight: 500;}
.popup .pop-con p + p {margin-top: .2rem;}
.popup .pop-con .pop-box {position: relative; width: 100%;}
.popup .pop-con .pop-box + .pop-box.line {margin-top: 3.2rem; padding-top: 4rem;}
.popup .pop-con .pop-box + .pop-box.line::before {content: ''; display: block; width: calc(100% + 4.8rem); height: .8rem; background-color: var(--gray100); position: absolute; top: 0; left: 50%; -webkit-transform: translateX(-50%); -moz-transform: translateX(-50%); -ms-transform: translateX(-50%); -o-transform: translateX(-50%); transform: translateX(-50%);}
.popup .pop-con .pop-box .pop-box-tit {display: flex; align-items: center; justify-content: space-between;}
.popup .pop-con .pop-box .pop-box-tit h6 {font-size: 2rem; font-weight: 600; margin-bottom: 1.6rem;}
.popup .pop-con .pop-box .pop-box-tit + span {font-size: 1.4rem; font-weight: 500; color: var(--red); line-height: 1.4;}
.popup .pop-con .table-desc select {width: fit-content; height: 3.6rem; font-size: 1.4rem; background-size: 1.2rem; padding: 0 3.2rem 0 1rem; background-position: center right 1rem;}
.popup .pop-con table th, .popup .pop-con table td {height: auto; padding: .8rem; font-size: 1.2rem;}
.popup .pop-btn {padding: 0 2.4rem 2.4rem; display: flex; align-items: center; justify-content: center;}

.pop-empty {display: flex; flex-flow: column; align-items: center; justify-content: center; row-gap: 1.6rem;}
.pop-empty > h6 {font-size: 2rem; font-weight: 600;}
.pop-empty > p {text-align: center; font-size: 1.6rem; font-weight: 500; line-height: 1.4;}

.popup .pop-btn button {width: 20rem;}
.popup .pop-btn button:nth-child(1):nth-last-child(2), .popup .pop-btn button:nth-child(1):nth-last-child(2) ~ button {width: calc((100% - 1.2rem) / 2);}
.popup .pop-btn button + button {margin-left: 1.2rem;}

@media (prefers-color-scheme: dark) {
    .popup {width: 100vw; height: 100vh; position: fixed!important; top: 0; left: 0; background-color: rgba(0, 0, 0, .6); z-index: 999!important;}
    .popup-top {z-index: 9999!important;}
}
@media screen and (max-width: 820px) {
    .popup .pop-wrap {width: calc(100% - 3.2rem); border-radius: .8rem;}
    .popup .pop-wrap.large {width: calc(100% - 3.2rem);}

    .popup .pop-tit {padding: 2rem;}
    .popup .pop-tit h4 {font-size: 1.8rem;}
    .popup .pop-tit i {right: 2rem;}
    .popup .pop-tit + .pop-con {padding: 2rem 1.6rem 3.2rem;}

    .popup .pop-con {padding: 2rem 1.6rem; max-height: calc(80vh - 11.6rem - 3.2rem); overflow-y: auto;}
    .popup .pop-con > * + * {margin-top: 2rem;}
    .popup .pop-con p {font-size: 1.4rem;}
    .popup .pop-con .pop-box + .pop-box.line {margin-top: 2rem; padding-top: 2.8rem;}
    .popup .pop-con .pop-box + .pop-box.line::before {width: calc(100% + 3.2rem);}
    .popup .pop-con .pop-box .pop-box-tit h6 {font-size: 1.8rem; margin-bottom: 1.2rem;}

    .popup .pop-btn {padding: 0 1.6rem 1.6rem;}
    .popup .pop-btn button {width: 100%;}
    .popup .pop-btn button:nth-child(1):nth-last-child(2), .popup .pop-btn button:nth-child(1):nth-last-child(2) ~ button {width: calc((100% - .8rem) / 2);}
    .popup .pop-btn button + button {margin-left: .8rem;}
}

.toast-pop {position: fixed; bottom: 8rem; opacity: 0; transition: opacity .3s ease-in-out; width: 120rem; padding: 1.6rem; display: flex; align-items: center; justify-content: center; background-color: rgba(0,0,0,.65); border-radius: .4rem; z-index: 10; left: 50%; -webkit-transform: translateX(-50%); -moz-transform: translateX(-50%); -ms-transform: translateX(-50%); -o-transform: translateX(-50%); transform: translateX(-50%);}
.toast-pop.active {opacity: 1;}
.toast-pop > p {font-size: 1.6rem; font-weight: 500; color: var(--white01);}

@media screen and (max-width: 1024px) {
    .toast-pop {width: calc(100% - 6.4rem);}
}

@media screen and (max-width: 820px) {
    .toast-pop {bottom: 4rem; width: calc(100% - 3.2rem);}
    .toast-pop > p {font-size: 1.4rem;;}
}

/* not found */
.not-found {width: 100vw; height: 100vh; display: flex; flex-flow: column; align-items: center; justify-content: center; position: fixed; top: 0; left: 0; background-color: var(--white02); background-image: url(/imgs/error-con-pattern.svg); background-repeat: no-repeat; background-size: cover; background-position: center;}
.not-found img {width: 12rem; height: 12rem;}
.not-found h4 {font-size: 2.4rem; font-weight: 600; margin-top: 2.4rem;}
.not-found p {margin-top: 2.4rem; font-size: 1.6rem; color: var(--gray500); line-height: 1.6; text-align: center;}
.not-found button {width: 34rem; margin-top: 6rem;}

@media (prefers-color-scheme: dark) {
    .not-found img {filter: invert(.9);}
}
[data-theme="dark"] .not-found img {filter: invert(.9);}
@media screen and (max-width: 820px) {
    .not-found {background-image: url(/imgs/error-con-pattern-mb.svg); padding: 1.6rem;}
    .not-found img {width: 10rem; height: 10rem;}
    .not-found h4 {font-size: 2rem; margin-top: 2rem;}
    .not-found p {margin-top: 1.2rem; font-size: 1.4rem;}
    .not-found button {width: 100%; margin-top: 4rem;}
}



#container.mobile-view .hide-mobile-app {display: none;}
#root > header.mobile-view {display: none;}
#root >.mobile-view > footer {display: none;}