
/* root --------------------------- */
:root {
    /* ------------ brand color */
    --pb50: #E3E6ED;
    --pb100: #BAC0D4;
    --pb200: #8D97B7;
    --pb300: #63709A;
    --pb400: #445387;
    --pb500: #223976; /* kbl sub color */
    --pb600: #1C326E;
    --pb700: #142A63;
    --pb800: #0B2157;
    --pb900: #011241; /* kbl main color */

    --yr50: #FBEAE7;
    --yr100: #FFCEBC;
    --yr200: #FFAE90;
    --yr300: #FF8F63;
    --yr400: #FF7740;
    --yr500: #FF601C; /* kbl point color */
    --yr600: #F45A18;
    --yr700: #E65313;
    --yr800: #D84C0F;
    --yr900: #BF3F06;

    /* ------------ greyscale color */
    --gray100: #F2F3F6;
    --gray200: #E3E5E9;
    --gray300: #D7D8DA;
    --gray400: #BDBEC0;
    --gray500: #747479;
    --gray600: #424243;
    --gray700: #2B2B2B;
    --gray800: #1C1C1C;
    --gray900: #121212;

    /* ------------ system color */
    --red: #FF3529;
    --blue: #236CD9;
    --white01: #FFFFFF;
    --white02: #FFFFFF;
    --black: #000000;

    /* ------------ club color */
    /* 원주 DB 프로미 : db */
    --db-main: #03662C;
    --db-point: #00399F;
    --db-sub01: #71BB98;
    --db-sub02: #C6EAD9;
    /* 서울 삼성 썬더스 : ss */
    --ss-main: #0032A0;
    --ss-point: #FFB81C;
    --ss-sub01: #6D94C7;
    --ss-sub02: #CEDDF1;
    /* 서울 SK 나이츠 : sk */
    --sk-main: #DB0028;
    --sk-point: #FF9600;
    --sk-sub01: #EC696F;
    --sk-sub02: #F9D3DA;
    /* 창원 LG 세이커스 : lg */
    --lg-main: #550E10;
    --lg-point: #FFBA00;
    --lg-sub01: #996E70;
    --lg-sub02: #D3B0B2;
    /* 고양 소노 스카이거너스 : or (오리온) */
    --or-main: #283D60;
    --or-point: #7DA1C9;
    --or-sub01: #63748D;
    --or-sub02: #CBD1D9;
    /* 전수 KCC 이지스 : kcc */
    --kcc-main: #07215A;
    --kcc-point: #FF9D00;
    --kcc-sub01: #666E80;
    --kcc-sub02: #ACB3C3;
    /* 안양 KGC 인삼공사 : kgc */
    --kgc-main: #D61D38;
    --kgc-point: #171717;
    --kgc-sub01: #E9667E;
    --kgc-sub02: #F2CBD2;
    /* 수원 KT 소닉붐 : kt */
    --kt-main: #E21820;
    --kt-point: #F07C22;
    --kt-sub01: #F36E6B;
    --kt-sub02: #F1CBD1;
    /* 대구 한국가스공사 : el */
    --el-main: #201351;
    --el-point: #BE1540;
    --el-sub01: #797197;
    --el-sub02: #BEBACF;
    /* 울산 현대모비스 피버스 : hd */
    --hd-main: #00287A;
    --hd-point: #ED1C24;
    --hd-sub01: #617196;
    --hd-sub02: #BFC7D8;

    /* ------------ box shadow */
    --shadow-gr: .2rem .2rem 2rem rgba(1, 18, 65, 0.04);
    --shadow-pb: .4rem .4rem 1.2rem rgba(1, 18, 65, 0.16);
    --shadow-yr: .4rem .4rem 3.2rem rgba(255, 96, 28, 0.2);
}

@media (prefers-color-scheme: dark) {
    :root {
        /* ------------ greyscale color */
        --gray100: #1C1C1C;
        --gray200: #2B2B2B;
        --gray300: #424243;
        --gray400: #424243;

        /* ------------ system color */
        --white01: #1C1C1C;
        --white02: #121212;
        --black: #FFFFFF;
    }
}
[data-theme="dark"] {
    /* ------------ greyscale color */
    --gray100: #1C1C1C;
    --gray200: #2B2B2B;
    --gray300: #424243;
    --gray400: #424243;

    /* ------------ system color */
    --white01: #1C1C1C;
    --white02: #121212;
    --black: #FFFFFF;
}

/* 소개 인사말 */
.greeting-db {display: flex; align-items: center; justify-content: space-between;}
.greeting-db > * + * {margin-left: 6rem;}
.greeting-db .img {width: 46rem; height: 54.8rem; background-repeat: no-repeat; background-size: contain; background-position: center;}
.greeting-db .txt {width: calc(100% - 52rem);}
.greeting-db .txt * + * {margin-top: 2.4rem;}
.greeting-db .txt h6 {font-size: 3rem; font-weight: 600; line-height: 1.4;}
.greeting-db .txt p {font-size: 1.6rem; line-height: 1.6;}

@media (prefers-color-scheme: dark) {
    .db .organization-wrap img {filter: invert(0);}
}
[data-theme="dark"] .db .organization-wrap img {filter: invert(0);}

@media screen and (max-width: 1024px) {
    .greeting-db > * + * {margin-left: 4rem;}
    .greeting-db .txt * + * {margin-top: 1.6rem;}
    .greeting-db .txt h6 {font-size: 2.4rem;}
    .greeting-db .txt p {font-size: 1.4rem; word-break: keep-all;}
}
@media screen and (max-width: 820px) {
    .con-box:not(:last-child) .greeting-db {flex-direction: column-reverse;}
    .greeting-db {flex-flow: column; align-items: center;}
    .greeting-db > * + * {margin-left: 0;}
    .greeting-db .img {width: 16.4rem; height: 19.5rem;}
    .greeting-db .txt {width: 100%; margin-top: 2rem;}
    .greeting-db .txt h6 {font-size: 1.6rem; word-break: keep-all;}
}

/* 엠블럼 */
.story-emblem-wrap {display: grid; grid-template-columns: repeat(2, 1fr); grid-gap: 2rem;}
.story-emblem-wrap > li {border: 1px solid var(--gray200); border-radius: 1.2rem; background-color: #fff;}
.story-emblem-wrap > li img {width: 100%;}
.brand-desc .story-emblem-wrap {margin-top: 4rem;}

@media screen and (max-width: 1024px) {
    .story-emblem-wrap > li {border-radius: .8rem;}

    .brand-desc .story-emblem-wrap {margin-top: 2rem;}
}
@media screen and (max-width: 820px) {
    .story-emblem-wrap {grid-template-columns: repeat(1, 1fr); grid-gap: 1.6rem;}
}

.brand-color-wrap .color-box + .color-box {margin-top: 6rem;}
.brand-color-wrap .color-box > h6 {font-size: 2rem; font-weight: 600; margin-bottom: 2rem;}
.brand-color-wrap .color-box .color {display: grid; grid-template-columns: repeat(2, 1fr); grid-gap: 2rem;}
.brand-color-wrap .color-box .color > li div {width: 100%; border-radius: .8rem; padding: 2rem; height: 7.2rem; display: flex; align-items: center; font-size: 2rem; font-weight: 600; color: #fff;}
.brand-color-wrap .color-box .color > li ul {margin-top: 2rem;}
.brand-color-wrap .color-box .color > li ul > li {display: flex; align-items: center;}
.brand-color-wrap .color-box .color > li ul > li + li {margin-top: 1.2rem;}
.brand-color-wrap .color-box .color > li ul > li h6 {font-size: 1.6rem; font-weight: 500; width: 10rem;}
.brand-color-wrap .color-box .color > li ul > li p {font-size: 1.6rem; color: var(--gray500); width: calc(100% - 10rem);}
.brand-color-wrap .color-box .background {display: grid; grid-template-columns: repeat(4, 1fr); grid-gap: 2rem;}
.brand-color-wrap .color-box .background > li:nth-child(1) .img {background-color: #fff;}
.brand-color-wrap .color-box .background > li:nth-child(-n+2) .img i {background-image: url(/imgs/logo/emblem-ss.svg);}
.brand-color-wrap .color-box .background > li:nth-last-child(-n+2) .img i {background-image: url(/imgs/logo/emblem-ss-dark.svg);}
.brand-color-wrap .color-box .background .img {border: 1px solid var(--gray200); border-radius: .8rem; height: 16rem; display: flex; align-items: center; justify-content: center;}
.brand-color-wrap .color-box .background .img i {width: 18rem; height: 10rem;}
.brand-color-wrap .color-box .background p {font-size: 1.6rem; font-weight: 500; margin-top: 1.6rem; text-align: center;}

@media screen and (max-width: 1024px) {
    .brand-color-wrap .color-box + .color-box {margin-top: 4rem;}
    .brand-color-wrap .color-box > h6 {font-size: 1.6rem; margin-bottom: 1.6rem;}
    .brand-color-wrap .color-box .color {grid-gap: 1.2rem;}
    .brand-color-wrap .color-box .color > li div {border-radius: .4rem; padding: 1.2rem; height: 4.4rem; font-size: 1.6rem;}
    .brand-color-wrap .color-box .color > li ul {margin-top: 1.6rem;}
    .brand-color-wrap .color-box .color > li ul > li + li {margin-top: .8rem;}
    .brand-color-wrap .color-box .color > li ul > li h6 {font-size: 1.4rem; font-weight: 500; width: 8rem;}
    .brand-color-wrap .color-box .color > li ul > li p {font-size: 1.4rem; width: calc(100% - 8rem);}
    .brand-color-wrap .color-box .background {grid-template-columns: repeat(2, 1fr); grid-gap: 2.4rem 1.2rem;}
    .brand-color-wrap .color-box .background .img {border-radius: .4rem; height: 9.6rem;}
    .brand-color-wrap .color-box .background .img i {width: 10.4rem; height: 6rem;}
    .brand-color-wrap .color-box .background p {font-size: 1.4rem; margin-top: 1.2rem;}
}
@media screen and (max-width: 820px) {
    .brand-color-wrap .color-box + .color-box {margin-top: 3.2rem;}
    .brand-color-wrap .color-box .color {grid-template-columns: repeat(1, 1fr); grid-gap: 2.4rem;}
}

/* 구장안내 */
.stadium-wrap {width: 100%; border: 1px solid var(--gray200); border-radius: 1.2rem; overflow: hidden; display: flex; flex-flow: column; align-items: center; justify-content: center;}
.stadium-wrap .txt {width: 100%; display: flex; flex-flow: column; align-items: center; justify-content: center; padding: 8rem}
.stadium-wrap .txt > * + * {margin-top: 3.2rem}
.stadium-wrap .txt > h6 {font-size: 2.4rem; font-weight: 600;}
.stadium-wrap .txt > p {font-size: 1.6rem; line-height: 1.6;}
.stadium-wrap .txt > p + p {margin-top: 1.6rem!important;}
.stadium-wrap .txt > ul.flex-txt {width: 100%; padding: 2rem 4rem; border-radius: .4rem; background-color: var(--gray100); justify-content: center; flex-wrap: wrap;}
.stadium-wrap .txt > ul.flex-txt > li {line-height: 2;}
.stadium-wrap .txt > ul.flex-txt > li + li::before {margin: 0 1.2rem!important;}
.stadium-wrap .img {width: 100%; height: 55rem; background-repeat: no-repeat; background-size: cover; background-position: center;}

.seat-wrap {width: 100%; border: 1px solid var(--gray200); border-radius: 1.2rem; overflow: hidden;}
.seat-wrap img {width: 100%;}

@media screen and (max-width: 1024px) {
    .stadium-wrap {border-radius: .8rem;}
    .stadium-wrap .txt {padding: 4rem;}
    .stadium-wrap .txt > * + * {margin-top: 2rem;}
    .stadium-wrap .txt > h6 {font-size: 1.6rem;}
    .stadium-wrap .txt > p {font-size: 1.4rem;}
    .stadium-wrap .txt > p + p {margin-top: 1.2rem!important;}
    .stadium-wrap .img {height: 40vw;}
    
    .seat-wrap {border-radius: .8rem;}    
}
@media screen and (max-width: 820px) {
    .stadium-wrap {border: 0;}
    .stadium-wrap .txt {padding: 0; align-items: flex-start;}
    .stadium-wrap .txt > * + * {margin-top: 1.2rem;}
    .stadium-wrap .txt > p + p {margin-top: .8rem!important;}
    .stadium-wrap .txt > ul.flex-txt {flex-flow: column; align-items: flex-start; height: auto; padding: 2rem;}
    .stadium-wrap .txt > ul.flex-txt > li {align-items: flex-start;}
    .stadium-wrap .txt > ul.flex-txt > li h6 {width: 6rem;}
    .stadium-wrap .txt > ul.flex-txt > li p {width: calc(100% - 6rem);}
    .stadium-wrap .txt > ul.flex-txt > li + li {margin-top: .8rem;}
    .stadium-wrap .txt > ul.flex-txt > li + li::before {content: none;}
    .stadium-wrap .img {margin-top: 1.6rem; border-radius: .8rem;}
}

/* 사회공헌활동 : DB */
.preparing-wrap {border-radius: 1.2rem; border: 1px solid var(--gray200); padding: 8rem; display: grid; grid-template-columns: repeat(2, 1fr); column-gap: 8rem; align-items: center;}
.preparing-wrap .img {width: 100%; height: 32rem; border-radius: 1.2rem; background-repeat: no-repeat; background-size: cover; background-position: center;}
.preparing-wrap .txt > div + div {margin-top: 4rem;}
.preparing-wrap .txt > div > h6 {font-size: 2rem; font-weight: 600; margin-bottom: 1.6rem;}
.preparing-wrap .txt > div > p {font-size: 1.6rem; line-height: 1.6;}
.preparing-wrap .txt > div > ul {background-color: var(--gray100); border-radius: .4rem; padding: 3.2rem;}
.preparing-wrap .txt > div > ul > li {display: flex; align-items: center;}
.preparing-wrap .txt > div > ul > li + li {margin-top: 1.2rem;}
.preparing-wrap .txt > div > ul > li > h6 {font-size: 1.6rem; font-weight: 600; width: 10rem;}
.preparing-wrap .txt > div > ul > li > p {font-size: 1.6rem; width: calc(100% - 10rem);}

@media screen and (max-width: 1024px) {
    .preparing-wrap {border-radius: .8rem; padding: 4rem; column-gap: 4rem;}
    .preparing-wrap .img {width: 100%; height: 24vw; border-radius: .8rem;}
    .preparing-wrap .txt > div + div {margin-top: 2.4rem;}
    .preparing-wrap .txt > div > h6 {font-size: 1.6rem;}
    .preparing-wrap .txt > div > p {font-size: 1.4rem; line-height: 1.4;}
    .preparing-wrap .txt > div > ul {padding: 2rem;}
    .preparing-wrap .txt > div > ul > li {align-items: flex-start;}
    .preparing-wrap .txt > div > ul > li + li {margin-top: .8rem;}
    .preparing-wrap .txt > div > ul > li > h6 {font-size: 1.4rem; width: 10rem; line-height: 1.4;}
    .preparing-wrap .txt > div > ul > li > p {font-size: 1.4rem; width: calc(100% - 10rem); line-height: 1.4;}
}
@media screen and (max-width: 820px) {
    .preparing-wrap {border-radius: 0; border: none; padding: 0; grid-template-columns: repeat(1, 1fr); row-gap: 1.6rem;}
    .preparing-wrap .img {width: 100%; height: 60vw;}
}

/* 사회공헌활동 : SS */
.happypoint-desc {border: 1px solid var(--gray200); border-radius: 1.2rem; padding: 8rem; display: flex; align-items: center; justify-content: space-between;}
.happypoint-desc .txt {width: calc(100% - 50.2rem);}
.happypoint-desc .txt h6 {font-size: 2.4rem; font-weight: 600;}
.happypoint-desc .txt h6 + * {margin-top: 4rem;}
.happypoint-desc .txt p {font-size: 1.6rem; line-height: 1.6;}
.happypoint-desc .txt p + * {margin-top: 1.6rem;}
.happypoint-desc img {width: 44.2rem; margin-left: 6rem; border-radius: 1.2rem;}

.happypoint-saving {display: flex; align-items: center; justify-content: space-between; height: 12rem; padding: 0 4rem; background-color: var(--ss-main); border-radius: 1.2rem;}
.happypoint-saving h6 {font-size: 2.4rem; font-weight: 600; color: #fff;}
.happypoint-saving div {display: flex; align-items: center;}
.happypoint-saving div * + * {margin-left: .8rem;}
.happypoint-saving div > p {font-size: 4.8rem; font-weight: 600; display: flex; align-items: center; color: #fff;}
.happypoint-saving div > p > span {font-size: 2rem; font-weight: 500; display: block; margin-left: .8rem; margin-top: 1.4rem;}

.happypoint-player {margin-top: 2rem; display: grid; grid-template-columns: repeat(4, 1fr); grid-gap: 2rem;}
.happypoint-player .img {height: 21.4rem; border-radius: 1.2rem; background-repeat: no-repeat; background-size: cover; background-position: center top;}
.happypoint-player .txt {margin-top: 1.6rem;}
.happypoint-player .txt h6 {font-size: 2rem; font-weight: 600;}
.happypoint-player .txt p {margin-top: .8rem; color: var(--gray500); font-size: 1.4rem;}
.happypoint-player .txt i {font-size: 1.4rem;}
.happypoint-player .txt p span {color: black; font-size: 1.6rem; line-height: 2.5rem; font-weight: 500;}
.happypoint-player .txt div {border: 1px solid var(--ss-main); border-radius: .4rem; background-color: var(--white01); height: 5.2rem; margin-top: 3.2rem; display: flex; align-items: center; justify-content: center; font-size: 1.8rem; font-weight: 600; color: var(--ss-main);}

.ss-happypoint-table01 table td[rowspan]:not(:last-child) {border-right: 1px solid var(--gray200);}
.ss-happypoint-table01 table th, 
.ss-happypoint-table01 table td {white-space: nowrap!important;}

@media screen and (max-width: 1024px) {
    .happypoint-desc {border-radius: .8rem; padding: 4rem;}
    .happypoint-desc .txt {width: calc(100% - 36.3rem);}
    .happypoint-desc .txt h6 {font-size: 1.6rem;}
    .happypoint-desc .txt h6 + * {margin-top: 1.6rem;}
    .happypoint-desc .txt p {font-size: 1.4rem;}
    .happypoint-desc .txt p + * {margin-top: .8rem;}
    .happypoint-desc img {width: 34.3rem; margin-left: 2rem; border-radius: .8rem;}
    
    .happypoint-saving {height: 7rem; padding: 0 2rem; border-radius: .8rem;}
    .happypoint-saving h6 {font-size: 1.8rem;}
    .happypoint-saving div {display: flex; align-items: center;}
    .happypoint-saving div i {width: 3.2rem; height: 3.2rem;}
    .happypoint-saving div > p {font-size: 3rem;}
    .happypoint-saving div > p > span {font-size: 1.4rem; margin-top: .8rem;}
    
    .happypoint-player {margin-top: 1.2rem; grid-gap: 1.2rem;}
    .happypoint-player .img {height: 20vw; border-radius: .8rem;}
    .happypoint-player .txt {margin-top: 1.2rem;}
    .happypoint-player .txt h6 {font-size: 1.6rem;}
    .happypoint-player .txt p {font-size: 1.2rem;}
    .happypoint-player .txt div {height: 4.4rem; margin-top: 2rem; font-size: 1.4rem;}
}
@media screen and (max-width: 820px) {
    .happypoint-desc {border: 0; padding: 0; flex-flow: column;}
    .happypoint-desc .txt {width: 100%;}
    .happypoint-desc .txt h6 {font-size: 1.6rem;}
    .happypoint-desc .txt h6 + * {margin-top: 1.6rem;}
    .happypoint-desc .txt p {font-size: 1.4rem;}
    .happypoint-desc .txt p + * {margin-top: .8rem;}
    .happypoint-desc img {width: 100%; margin-left: 0; margin-top: 1.2rem;}
    
    .happypoint-saving {height: auto; padding: 2rem; flex-flow: column; align-items: center;}
    .happypoint-saving div {margin-top: 1.2rem;}
    
    .happypoint-player {grid-template-columns: repeat(2, 1fr); grid-gap: 2rem 1.2rem;}
    .happypoint-player .img {height: 32vw;}
}

/* 명예의 전당 */
.story-honors-play {position: relative; padding: 6rem 0; display: flex; align-items: center; justify-content: center;}
.story-honors-play::before {content: ''; width: 100vw; min-width: 128rem; height: 100%; background-color: var(--gray100); position: absolute; top: 0; left: 50%; -webkit-transform: translateX(-50%); -moz-transform: translateX(-50%); -ms-transform: translateX(-50%); -o-transform: translateX(-50%); transform: translateX(-50%); z-index: 0!important;}
.story-honors-play > * {position: relative; z-index: 1;}
.story-honors-play > li {display: flex; align-items: center; justify-content: center;}
.story-honors-play > li.team {width: calc((100% - 20rem) / 2);}
.story-honors-play > li.team:nth-child(1) {justify-content: end;}
.story-honors-play > li.team:nth-last-child(1) {justify-content: start;}
.story-honors-play > li.team > * + * {margin-left: 1.2rem;}
.story-honors-play > li.team h6 {font-size: 2rem; font-weight: 600;}
.story-honors-play > li.team i {width: 12rem; height: 8rem;}
.story-honors-play > li:not(.team) {flex-flow: column; padding: 0 4rem; width: 20rem;}
.story-honors-play > li:not(.team) p {font-size: 4.8rem; font-weight: 600; color: var(--gray400);}
.story-honors-play > li:not(.team) span {display: block; margin-top: 1.2rem; height: 3rem; padding: 0 .8rem; border-radius: .4rem; background-color: #fff; font-size: 1.4rem; font-weight: 500; color: #000; display: flex; align-items: center; justify-content: center;}

.story-honors-history {margin-top: 6rem;}
.story-honors-history > li {display: flex; justify-content: space-between; border: 1px solid var(--gray100); border-radius: .8rem; box-shadow: var(--shadow-gr);}
.story-honors-history > li + li {margin-top: 2rem;}
.story-honors-history > li .tit {width: 24rem; display: flex; flex-flow: column; align-content: center; justify-content: center; padding: 0 3.2rem; margin: 3.2rem 0; border-right: 1px solid var(--gray200);}
.story-honors-history > li .tit h6 {text-align: center; font-size: 1.6rem; font-weight: 600;}
.story-honors-history > li .tit p {text-align: center; font-size: 1.6rem; margin-top: .8rem;}

.story-honors-history > li .score-wrap {width: calc(100% - 24rem - 23.7rem); padding: 3.2rem; background-image: url(/imgs/bg-honors-con-pattern.svg); background-repeat: no-repeat; background-size: auto 100%; background-position: right center; display: flex; align-items: center; justify-content: center; flex-flow: column;}
.story-honors-history > li .score-wrap > li {width: 100%; display: flex; align-items: center; justify-content: space-between;}
.story-honors-history > li .score-wrap > li + li {margin-top: .8rem;}
.story-honors-history > li .score-wrap > li .team {display: flex; align-items: center;}
.story-honors-history > li .score-wrap > li .team i {width: 6rem; height: 4rem; margin-right: .4rem;}
.story-honors-history > li .score-wrap > li .team p {font-size: 1.8rem; font-weight: 600;}
.story-honors-history > li .score-wrap > li .team .tag {margin-left: .4rem;}
.story-honors-history > li .score-wrap > li .score {font-size: 2.4rem; font-weight: 600;}
.story-honors-history > li .button {display: flex; flex-flow: column; align-items: center; justify-content: center; padding: 0 3.2rem; margin: 3.2rem 0; border-left: 1px solid var(--gray200);}
.story-honors-history > li .button button {width: 17.2rem; height: 4.8rem; font-size: 1.6rem;}
.story-honors-history > li .button button + button {margin-top: .8rem;}

button.ss-yellow {background-color: var(--ss-point); border-color: var(--ss-point); color: #fff;}
button.ss-blue {background-color: var(--ss-main); border-color: var(--ss-main); color: #fff;}
.tag.ss-yellow {border-color: var(--ss-point); color: var(--ss-point);}

@media screen and (max-width: 1024px) {
    .story-honors-play {padding: 4rem 0;}
    .story-honors-play::before {min-width: auto;}
    
    .story-honors-history {margin-top: 4rem;display: grid; grid-gap: 2rem; grid-template-columns: repeat(2, 1fr); align-items: flex-start;}
    .story-honors-history > li {flex-flow: column; background-image: url(/imgs/bg-schedule-con-pattern-mb.svg); background-repeat: no-repeat; background-size: contain; background-position: right bottom;}
    .story-honors-history > li + li {margin-top: 0;}
    .story-honors-history > li .tit {position: relative; width: 100%; padding: 2rem 0; margin: 0 2rem; border-right: 0; border-bottom: 1px solid var(--gray200);}
    .story-honors-history > li .tit h6 {font-size: 1.4rem;}
    .story-honors-history > li .tit p {font-size: 1.4rem; margin-top: .4rem;}
    .story-honors-history > li .score-wrap {position: relative; width: 100%; padding: 2rem; background-image: none;}
    .story-honors-history > li .score-wrap > li .team p {font-size: 1.4rem;}
    .story-honors-history > li .score-wrap > li .score {font-size: 2rem;}
    .story-honors-history > li .button {padding: 0 2rem 2rem; margin: 0; border-left: 0;}
    .story-honors-history > li .button button {width: 100%; height: 4.4rem; font-size: 1.4rem;}
    .story-honors-history > li .button button + button {margin-top: .8rem;}
}
@media screen and (max-width: 820px) {
    .story-honors-play {padding: 3.2rem 0;}
    .story-honors-play > li {flex-flow: column; justify-content: center!important;}
    .story-honors-play > li.team:nth-child(1) {flex-direction: column-reverse;}
    .story-honors-play > li.team {width: calc((100% - 12rem) / 2);}
    .story-honors-play > li.team > * + * {margin-left: 0; margin-top: .8rem;}
    .story-honors-play > li.team h6 {font-size: 1.2rem;}
    .story-honors-play > li.team i {width: 7.2rem; height: 4.8rem;}
    .story-honors-play > li:not(.team) {padding: 0 2rem; width: 12rem;}
    .story-honors-play > li:not(.team) p {font-size: 2.4rem;}
    .story-honors-play > li:not(.team) span {margin-top: .8rem; height: 2.6rem; padding: 0 .6rem; font-size: 1rem;}
    
    .story-honors-history {margin-top: 3.2rem; grid-template-columns: repeat(1, 1fr);}
    .story-honors-history > li {flex-flow: column;}
    .story-honors-history > li + li {margin-top: 1.2rem;}
    .story-honors-history > li .tit {width: 100%; padding: 2rem; margin: 0; border-right: 0; border-bottom: 1px solid var(--gray200); flex-flow: initial;}
    .story-honors-history > li .tit p {margin-top: 0; margin-left: .6rem;}
    .story-honors-history > li .score-wrap {width: 100%; padding: 2rem; background-image: none;}
    .story-honors-history > li .score-wrap > li {width: 100%;}
    .story-honors-history > li .score-wrap > li + li {margin-top: .6rem;}
    .story-honors-history > li .score-wrap > li .team .tag {margin-left: .4rem;}
    .story-honors-history > li .score-wrap > li .score {font-size: 1.8rem;}
    .story-honors-history > li .button {padding: 0 2rem 2rem 2rem; margin: 0; border-left: 0;}
    .story-honors-history > li .button button {width: 100%;}
}

.honors-swiper {border-radius: 1.2rem; overflow: hidden;}
.honors-swiper * {width: 100%;}
.honors-swiper-list-wrap {position: relative;}
.honors-swiper-list-wrap .swiper-btn * {z-index: 2; width: 6rem; height: 6rem; border-radius: 100%; background-repeat: no-repeat; background-size: contain; background-position: center; cursor: pointer;  position: absolute; top: 50%; -webkit-transform: translateY(-50%); -moz-transform: translateY(-50%); -ms-transform: translateY(-50%); -o-transform: translateY(-50%); transform: translateY(-50%);}
.honors-swiper-list-wrap .swiper-btn .swiper-btn-prev {background-image: url(/imgs/ico/ic-arrow-left-60.svg); left: -3rem;}
.honors-swiper-list-wrap .swiper-btn .swiper-btn-next {background-image: url(/imgs/ico/ic-arrow-right-60.svg); right: -3rem}
.honors-swiper-list {margin-top: 2rem;}
.honors-swiper-list * {width: 100%;}
.honors-swiper-list .swiper-slide {border-radius: .8rem; overflow: hidden; cursor: pointer;}

@media screen and (max-width: 1024px) {
    .honors-swiper {border-radius: .8rem;}
    .honors-swiper-list-wrap .swiper-btn {height: 4rem;}
    .honors-swiper-list-wrap .swiper-btn .swiper-btn-prev {width: 4rem; height: 4rem; margin-left: -2rem;}
    .honors-swiper-list-wrap .swiper-btn .swiper-btn-next {width: 4rem; height: 4rem; margin-right: -2rem;}
    .honors-swiper-list {margin-top: 1.2rem;}
    .honors-swiper-list .swiper-slide {border-radius: .43rem;}
}
@media screen and (max-width: 820px) {
    .honors-swiper-list .swiper-slide {width: 19.2rem; height: 10.8rem;}
}

/* legacy */
.honors-history-para .para > p {float: left;}
.honors-history-para .img.fl,
.honors-history-para .img.fr {float: right; width: fit-content; padding-left: 1rem; width: calc(40% - 1rem);}
.honors-history-para .img img {padding-bottom: 1rem; width: 100%;}

.honors-history-para h6 {font-size: 2.4rem; font-weight: 700; line-height: 1.6; margin-bottom: 1rem;}
.honors-history-para h6 > br {display: none;}
.honors-history-para .rect,
.honors-history-para .rec {margin-top: 2rem; position: relative;}
.honors-history-para caption {display: none;}
.honors-history-para .skillAbout_list {position: absolute; right: 0; top: -1rem; line-height: 1.6;}
.para, .para-fl {float: left; width: 60%;}

.lst_tb_seasonsummary {width: 100%; overflow-x: auto;}

.wrp_lst_cont .lst_tb_seasonreport:first-child {width: calc(50% - 1rem); float: left;}
.wrp_lst_cont .lst_tb_seasonreport:last-child {width: calc(50% - 1rem); float: right;}
.content .body .lst_tb_seasonreport table thead th {border-top: 2px solid #011241;}
.content .body .lst_tb_seasonreport table tbody td img {float: none; padding-bottom: 0; width: auto;}
.honors-history-para .para > p {float: none;}
.honors-history-para .img.fl,
.honors-history-para .img.fr {display: none;}

.skillAbout_title {display: none;}
.skillAbout_list {display: flex; align-items: center;}
.skillAbout_list > * + * {margin-left: 2rem;}

@media screen and (max-width: 1024px) {
    .skillAbout_list {display: none;}
}
@media screen and (max-width: 820px) {
    .honors-history-para h6 {font-size: 1.6rem; margin-bottom: 1rem; margin-top: 2rem;}
    .honors-history-para .rect,
    .honors-history-para .rec {margin-top: 1rem;}
    .honors-history-para .skillAbout_list {display: none;}
    .para, .para-fl {float: none; width: 100%;}
    
    .wrp_lst_cont .lst_tb_seasonreport:first-child {width: 100%; float: none; margin-bottom: 1rem;}
    .wrp_lst_cont .lst_tb_seasonreport:last-child {width: 100%; float: none;}    
}

/* 삼성 : 김현준 농구 장학금 */
.ss-scholarship-para p {font-size: 1.6rem; line-height: 1.6;}
.ss-scholarship-para + .ss-scholarship-wrap {margin-top: 4rem;}
.ss-scholarship-wrap + .ss-scholarship-para {margin-top: 4rem;}
.ss-scholarship-wrap {display: flex; align-items: center; justify-content: space-between; border-radius: 1.2rem; border: 1px solid var(--gray200); padding: 8rem;}
.ss-scholarship-wrap .txt {width: calc(100% - 48rem);}
.ss-scholarship-wrap .txt > h4 {font-size: 2.4rem; font-weight: 600; line-height: 1.6; word-break: keep-all;}
.ss-scholarship-wrap .txt > h6 {font-size: 2rem; font-weight: 600; line-height: 1.6; word-break: keep-all;}
.ss-scholarship-wrap .txt > h6 + * {margin-top: 1.2rem;}
.ss-scholarship-wrap .txt > * + h6 {margin-top: 4rem;}
.ss-scholarship-wrap .txt > p {font-size: 1.6rem; line-height: 1.6; margin-top: 4rem; word-break: keep-all;}
.ss-scholarship-wrap .txt > ul > li {display: flex; align-items: flex-start;}
.ss-scholarship-wrap .txt > ul > li + li {margin-top: 1.2rem;}
.ss-scholarship-wrap .txt > ul > li h6 {font-size: 1.6rem; font-weight: 500; width: 12rem; line-height: 1.6;}
.ss-scholarship-wrap .txt > ul > li p {font-size: 1.6rem; width: calc(100% - 12rem); line-height: 1.6;}
.ss-scholarship-wrap .img img {width: 44rem;}

.ss-scholarship-player {margin-top: 4rem;}
.ss-scholarship-player > li {cursor: default;}

@media screen and (max-width: 1024px) {
    .ss-scholarship-para p {font-size: 1.4rem;}
    .ss-scholarship-para + .ss-scholarship-wrap {margin-top: 2rem;}
    .ss-scholarship-wrap + .ss-scholarship-para {margin-top: 2rem;}
    .ss-scholarship-wrap {border-radius: .8rem; padding: 4rem;}
    .ss-scholarship-wrap .txt {width: calc(100% - 22rem);}
    .ss-scholarship-wrap .txt > h4 {font-size: 2rem;}
    .ss-scholarship-wrap .txt > h6 {font-size: 1.6rem;}
    .ss-scholarship-wrap .txt > h6 + * {margin-top: .8rem;}
    .ss-scholarship-wrap .txt > * + h6 {margin-top: 2rem;}
    .ss-scholarship-wrap .txt > p {font-size: 1.4rem; margin-top: 2rem;}
    .ss-scholarship-wrap .txt > ul > li + li {margin-top: .8rem;}
    .ss-scholarship-wrap .txt > ul > li h6 {font-size: 1.4rem; width: 10rem;}
    .ss-scholarship-wrap .txt > ul > li p {font-size: 1.4rem; width: calc(100% - 10rem);}
    .ss-scholarship-wrap .img img {width: 20rem;}
    
    .ss-scholarship-player {margin-top: 2rem;}
}
@media screen and (max-width: 820px) {
    .ss-scholarship-wrap {border: 0; padding: 0; flex-flow: column;}
    .ss-scholarship-wrap .txt {width: 100%;}
    .ss-scholarship-wrap .txt > h4 {font-size: 1.6rem;}
    .ss-scholarship-wrap .txt > h6 {font-size: 1.4rem;}
    .ss-scholarship-wrap .txt > p {margin-top: 1.2rem;}
    .ss-scholarship-wrap .img {margin-top: 2rem;}
    .ss-scholarship-wrap .img img {width: 100%;}
    
    .ss-scholarship-player {margin-top: 2rem;}
}

/* 삼성 : 명예기자 */
.ss-story-emblem-wrap {display: grid; grid-template-columns: repeat(3, 1fr); grid-gap: 2rem;}
.ss-story-emblem-wrap > li {border: none; background-color: #fff;}
.ss-story-emblem-wrap > li img {width: 100%;}
.brand-desc .ss-story-emblem-wrap {margin-top: 4rem;}
.reporter-desc h6{display: flex; align-items: center; }

@media screen and (max-width: 1024px) {
    .ss-story-emblem-wrap > li {border-radius: .8rem;}

    .brand-desc .ss-story-emblem-wrap {margin-top: 2rem;}
}
@media screen and (max-width: 820px) {
    .ss-story-emblem-wrap {grid-template-columns: repeat(1, 1fr); grid-gap: 1.6rem;}
}

/* 삼성 : 임직원관람신청 */
.employee-reserve-guide > ul > li {font-size: 1.6rem; line-height: 1.6; text-indent: -1.6rem; padding-left: 1.6rem; word-break: keep-all;}
.employee-reserve-guide > ul > li + li {margin-top: .8rem;}
.employee-reserve-guide > ul > li span {font-size: 1.4rem;}
.employee-reserve-guide > * + h6 {margin-top: 3.6rem;}
.employee-reserve-guide > h6 {font-size: 1.6rem; font-weight: 600; margin-bottom: 1.2rem; line-height: 1.6;}
.employee-reserve-guide > p {font-size: 1.6rem; line-height: 1.6; word-break: keep-all;}
.employee-reserve-guide > input + label > span {font-size: 1.6rem; font-weight: initial; color: var(--black);}
.employee-reserve-guide > span {display: block; font-size: 1.4rem; line-height: 1.6; color: var(--yr500); margin-top: .6rem;}

.employee-reserve-input {display: grid; grid-template-columns: repeat(2, 1fr); grid-gap: 4rem 2rem;}
.employee-reserve-input > li > h6 {font-size: 1.8rem; font-weight: 600; margin-bottom: 1.6rem;}
.employee-reserve-input > li > p {margin-top: 1.2rem; font-size: 1.4rem; color: var(--gray500); line-height: 1.6; text-indent: -1rem; padding-left: 1rem; word-break: keep-all;}
.employee-reserve-input > li .radio-wrap {display: flex; align-items: center;}
.employee-reserve-input > li .radio-wrap > li + li {margin-left: 4rem;}

@media screen and (max-width: 1024px) {
    .employee-reserve-guide > ul > li {font-size: 1.4rem; text-indent: -1.4rem; padding-left: 1.4rem;}
    .employee-reserve-guide > ul > li span {font-size: 1.2rem;}
    .employee-reserve-guide > * + h6 {margin-top:2rem;}
    .employee-reserve-guide > h6 {font-size: 1.4rem;}
    .employee-reserve-guide > p {font-size: 1.4rem;}
    .employee-reserve-guide > input + label {align-items: flex-start;}
    .employee-reserve-guide > input + label > span {font-size: 1.4rem; line-height: 1.4;}
    .employee-reserve-guide > span {font-size: 1.2rem;}
    
    .employee-reserve-input {grid-gap: 2rem;}
    .employee-reserve-input > li > h6 {font-size: 1.6rem; margin-bottom: 1.2rem;}
    .employee-reserve-input > li > p {margin-top: .8rem; font-size: 1.2rem; text-indent: -.8rem; padding-left: .8rem;}
    .employee-reserve-input > li .radio-wrap > li + li {margin-left: 2.4rem;}
}
@media screen and (max-width: 820px) {
    .employee-reserve-input {display: grid; grid-template-columns: repeat(1, 1fr);}
    .employee-reserve-input > li:nth-child(2) {display: none;}
}
