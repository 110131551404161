

/* root --------------------------- */
:root {
    --vh: 100%;
    
    /* ------------ brand color */
    --pb50: #E3E6ED;
    --pb100: #BAC0D4;
    --pb200: #8D97B7;
    --pb300: #63709A;
    --pb400: #445387;
    --pb500: #223976; /* kbl sub color */
    --pb600: #1C326E;
    --pb700: #142A63;
    --pb800: #0B2157;
    --pb900: #011241; /* kbl main color */

    --yr50: #FBEAE7;
    --yr100: #FFCEBC;
    --yr200: #FFAE90;
    --yr300: #FF8F63;
    --yr400: #FF7740;
    --yr500: #FF601C; /* kbl point color */
    --yr600: #F45A18;
    --yr700: #E65313;
    --yr800: #D84C0F;
    --yr900: #BF3F06;

    /* ------------ greyscale color */
    --gray100: #F2F3F6;
    --gray200: #E3E5E9;
    --gray300: #D7D8DA;
    --gray400: #BDBEC0;
    --gray500: #747479;
    --gray600: #424243;
    --gray700: #2B2B2B;
    --gray800: #1C1C1C;
    --gray900: #121212;

    /* ------------ system color */
    --red: #FF3529;
    --blue: #236CD9;
    --white01: #FFFFFF;
    --white02: #FFFFFF;
    --black: #000000;

    /* ------------ club color */
    /* 원주 DB 프로미 : db */
    --db-main: #03662C;
    --db-point: #00399F;
    --db-sub01: #71BB98;
    --db-sub02: #C6EAD9;
    /* 서울 삼성 썬더스 : ss */
    --ss-main: #0032A0;
    --ss-point: #FFB81C;
    --ss-sub01: #6D94C7;
    --ss-sub02: #CEDDF1;
    /* 서울 SK 나이츠 : sk */
    --sk-main: #DB0028;
    --sk-point: #FF9600;
    --sk-sub01: #EC696F;
    --sk-sub02: #F9D3DA;
    /* 창원 LG 세이커스 : lg */
    --lg-main: #550E10;
    --lg-point: #FFBA00;
    --lg-sub01: #996E70;
    --lg-sub02: #D3B0B2;
    /* 고양 소노 스카이거너스 : or (오리온) */
    --or-main: #283D60;
    --or-point: #7DA1C9;
    --or-sub01: #63748D;
    --or-sub02: #CBD1D9;
    /* 전수 KCC 이지스 : kcc */
    --kcc-main: #07215A;
    --kcc-point: #FF9D00;
    --kcc-sub01: #666E80;
    --kcc-sub02: #ACB3C3;
    /* 안양 KGC 인삼공사 : kgc */
    --kgc-main: #D61D38;
    --kgc-point: #171717;
    --kgc-sub01: #E9667E;
    --kgc-sub02: #F2CBD2;
    /* 수원 KT 소닉붐 : kt */
    --kt-main: #E21820;
    --kt-point: #F07C22;
    --kt-sub01: #F36E6B;
    --kt-sub02: #F1CBD1;
    /* 대구 한국가스공사 : el */
    --el-main: #201351;
    --el-point: #BE1540;
    --el-sub01: #797197;
    --el-sub02: #BEBACF;
    /* 울산 현대모비스 피버스 : hd */
    --hd-main: #00287A;
    --hd-point: #ED1C24;
    --hd-sub01: #617196;
    --hd-sub02: #BFC7D8;

    /* ------------ box shadow */
    --shadow-gr: .2rem .2rem 2rem rgba(1, 18, 65, 0.04);
    --shadow-pb: .4rem .4rem 1.2rem rgba(1, 18, 65, 0.16);
    --shadow-yr: .4rem .4rem 3.2rem rgba(255, 96, 28, 0.2);
}

@media (prefers-color-scheme: dark) {
    :root {
        /* ------------ greyscale color */
        --gray100: #1C1C1C;
        --gray200: #2B2B2B;
        --gray300: #424243;
        --gray400: #424243;

        /* ------------ system color */
        --white01: #1C1C1C;
        --white02: #121212;
        --black: #FFFFFF;
    }
}
[data-theme="dark"] {
    /* ------------ greyscale color */
    --gray100: #1C1C1C;
    --gray200: #2B2B2B;
    --gray300: #424243;
    --gray400: #424243;

    /* ------------ system color */
    --white01: #1C1C1C;
    --white02: #121212;
    --black: #FFFFFF;
}

/* header --------------------------- */
header {width: 100%; min-width: 128rem; background-color: var(--white02); position: sticky; top: 0; left: 0; z-index: 100;}

header .desktop-only .user-wrap {width: 100%; border-bottom: 1px solid var(--gray200);}
header .desktop-only .user-wrap .user-con {width: 128rem; height: 2.8rem; padding: 0 4rem; margin: 0 auto; display: flex; align-items: center; justify-content: flex-end;}
header .desktop-only .user-wrap .user-con .user {display: flex; align-items: center;}
header .desktop-only .user-wrap .user-con .user > li {display: flex; align-items: center; column-gap: .2rem; font-size: 1.2rem; font-weight: 500; color: var(--gray500); cursor: pointer;}
header .desktop-only .user-wrap .user-con .user > li > a {display: flex; align-items: center; column-gap: .2rem;}
header .desktop-only .user-wrap .user-con .user > li .cart-count {width: 1.6rem; height: 1.6rem; background-color: var(--yr500); border-radius: 100%; font-size: 1.2rem; font-weight: 500; color: var(--white01); display: flex; align-items: center; justify-content: center;}
header .desktop-only .user-wrap .user-con .user > li + li::before {content: ''; display: block; width: 1px; height: 1rem; margin: 0 .6rem; background-color: var(--gray300);}

header .desktop-only .header-wrap {width: 100%;}
header .desktop-only .header-wrap .header-con {width: 128rem; height: 7.2rem; padding: 0 4rem; margin: 0 auto; display: flex; align-items: center; justify-content: space-between;}
header .desktop-only .header-wrap .header-con i {width: 100%; height: 100%;}
header .desktop-only .header-wrap .header-con .logo {width: 7.2rem; height: 4.8rem; cursor: pointer;}
header .desktop-only .header-wrap .header-con .emblem-wrap {display: flex; align-items: center;}
header .desktop-only .header-wrap .header-con .emblem-wrap > li {width: 4.8rem; height: 3.2rem; cursor: pointer;}
header .desktop-only .header-wrap .header-con .emblem-wrap > li + li {margin-left: 2.4rem;}

/* 구단별 모바일 메뉴 색상 */
/* header : 엠블럼 이미지 */
/*header.kbl .logo-kbl {display: none;}*/
header.db .logo-kbl {background-image: url(/imgs/logo/emblem-db.svg);}
header.youth .logo-kbl {background-image: url(/imgs/logo/logo-kbl-youth.svg);}
header.ss .logo-kbl {background-image: url(/imgs/logo/emblem-ss.svg);}
header.sk .logo-kbl {background-image: url(/imgs/logo/emblem-sk.svg);}
header.lg .logo-kbl {background-image: url(/imgs/logo/emblem-lg.svg);}
header.or .logo-kbl {background-image: url(/imgs/logo/emblem-sono.svg);}
header.el .logo-kbl {background-image: url(/imgs/logo/emblem-gas.svg);}
header.gas .logo-kbl {background-image: url(/imgs/logo/emblem-gas.svg);}
header.kcc .logo-kbl {background-image: url(/imgs/logo/emblem-kcc.svg);}
header.kgc .logo-kbl {background-image: url(/imgs/logo/emblem-kgc.svg);}
header.kt .logo-kbl {background-image: url(/imgs/logo/emblem-kt.svg);}
header.hd .logo-kbl {background-image: url(/imgs/logo/emblem-hd.svg);}

@media (prefers-color-scheme: dark) {
    header.kbl .logo-kbl {background-image: url(/imgs/logo/logo-kbl-wh.svg);}
    header.youth .logo-kbl {background-image: url(/imgs/logo/logo-kbl-youth-dark.svg);}
    header.lg .logo-kbl {background-image: url(/imgs/logo/emblem-lg-dark.svg);}
    header.ss .logo-kbl {background-image: url(/imgs/logo/emblem-ss-dark.svg);}
    header.kgc .logo-kbl {background-image: url(/imgs/logo/emblem-kgc-dark.svg);}
    header.kt .logo-kbl {background-image: url(/imgs/logo/emblem-kt-dark.svg);}
}
[data-theme="dark"] header.kbl .logo-kbl {background-image: url(/imgs/logo/logo-kbl-wh.svg);}
[data-theme="dark"] header.youth .logo-kbl {background-image: url(/imgs/logo/logo-kbl-youth-dark.svg);}
[data-theme="dark"] header.lg .logo-kbl {background-image: url(/imgs/logo/emblem-lg-dark.svg);}
[data-theme="dark"] header.ss .logo-kbl {background-image: url(/imgs/logo/emblem-ss-dark.svg);}
[data-theme="dark"] header.kgc .logo-kbl {background-image: url(/imgs/logo/emblem-kgc-dark.svg);}
[data-theme="dark"] header.kt .logo-kbl {background-image: url(/imgs/logo/emblem-kt-dark.svg);}

header.kbl .emblem-wrap > li[data-team-code='kbl'] {display: none;}
header.youth .emblem-wrap > li[data-team-code='youth'] {display: none;}
header.db .emblem-wrap > li[data-team-code='db'] {display: none;}
header.ss .emblem-wrap > li[data-team-code='ss'] {display: none;}
header.sk .emblem-wrap > li[data-team-code='sk'] {display: none;}
header.lg .emblem-wrap > li[data-team-code='lg'] {display: none;}
header.or .emblem-wrap > li[data-team-code='car'] {display: none;}
header.or .emblem-wrap > li[data-team-code='sono'] {display: none;}
header.car .emblem-wrap > li[data-team-code='car'] {display: none;}
header.el .emblem-wrap > li[data-team-code='gas'] {display: none;}
header.gas .emblem-wrap > li[data-team-code='gas'] {display: none;}
header.kcc .emblem-wrap > li[data-team-code='kcc'] {display: none;}
header.kgc .emblem-wrap > li[data-team-code='kgc'] {display: none;}
header.kt .emblem-wrap > li[data-team-code='kt'] {display: none;}
header.hd .emblem-wrap > li[data-team-code='hd'] {display: none;}

@media screen and (max-width: 1024px) {
    header {min-width: initial;}

    header .desktop-only {display: none;}
    header .mobile-only {display: block;}
    header .mobile-only .header-wrap {width: 100%; height: 6rem; padding: 1.6rem 3.2rem; display: flex; align-items: center; justify-content: space-between; border-bottom: 1px solid var(--gray100); background-color: var(--white01);}
    header .mobile-only .header-wrap i {cursor: pointer;}
    header .mobile-only .header-wrap .logo {display: flex; align-items: center; position: absolute; top: 50%; left: 50%; -webkit-transform: translate(-50%, -50%); -moz-transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%); -o-transform: translate(-50%, -50%); transform: translate(-50%, -50%);}
    header .mobile-only .header-wrap .logo .logo-kbl {width: 7.2rem; height: 4.8rem;}
    header.youth .mobile-only .header-wrap .logo .logo-kbl {width: 14.2rem; height: 4.8rem;}
    header .mobile-only .header-wrap .icon {display: flex; align-items: center;}
    header .mobile-only .header-wrap .icon > li {position: relative;}
    header .mobile-only .header-wrap .icon .cart-count {width: 1.4rem; height: 1.4rem; background-color: var(--yr500); display: flex; align-items: center; justify-content: center; font-size: 1.2rem; font-weight: 500; color: var(--white01); position: absolute; right: -.4rem; bottom: -.2rem; border-radius: 100%;}
    header .mobile-only .header-wrap .icon > * + * {margin-left: 1.2rem;}
    
    header .mobile-only .header-wrap .mobile-menu.active .ic-menu-24 {background-image: url(/imgs/ico/ic-menu-close-24.svg);}

    header .mobile-only .mobile-menu-wrap {display: none; width: 51.2rem; height: calc(100vh - 6rem); position: absolute; top: 6rem; left: 0; z-index: 998; background-color: var(--white02);}
    header .mobile-only .mobile-menu-wrap::before {content: ''; width: 100vw; height: 100%; display: block; background-color: rgba(0, 0, 0, .8); position: absolute; top: 0; left: 0; z-index: -1;}
    header .mobile-only .mobile-menu-wrap.active {display: flex; align-items: center; justify-content: center; flex-flow: column;}
    
    header .mobile-only .mobile-menu-wrap .user-wrap {width: 100%; padding: 3.2rem 1.6rem; border-bottom: 1px solid var(--pb700); background-color: var(--pb900); display: flex; align-items: center; justify-content: center;}
    header .mobile-only .mobile-menu-wrap .user-wrap > ul {width: 100%; display: grid; grid-template-columns: repeat(2, 1fr); border-radius: .4rem; overflow: hidden;}
    header .mobile-only .mobile-menu-wrap .user-wrap > ul > li {height: 4.4rem; display: flex; align-items: center; justify-content: center; font-size: 1.4rem; font-weight: 600; color: #fff;}
    header .mobile-only .mobile-menu-wrap .user-wrap > ul > li a {color: #fff!important;}
    header .mobile-only .mobile-menu-wrap .user-wrap > ul > li:nth-child(1) {background-color: var(--pb700);}
    header .mobile-only .mobile-menu-wrap .user-wrap > ul > li:nth-child(2) {background-color: var(--pb400);}
    header .mobile-only .mobile-menu-wrap .user-wrap > div {display: flex; flex-flow: column; align-items: center; justify-content: center;}
    header .mobile-only .mobile-menu-wrap .user-wrap > div > p {font-size: 1.8rem; color: #fff;}
    header .mobile-only .mobile-menu-wrap .user-wrap > div > button {margin-top: 1.6rem; background-color: #142A63; border-color: #142A63;}
    
    header .mobile-only .mobile-menu-wrap .mobile-menu-con {position: relative; width: 100%; height: calc(100% - 10.8rem); overflow-y: auto; background-color: var(--pb900);}
    header .mobile-only .mobile-menu-wrap .mobile-menu-con::before {content: ''; height: 100%; width: 1px; background-color: var(--pb700); position: absolute; top: 50%; left: 50%; -webkit-transform: translate(-50%, -50%); -moz-transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%); -o-transform: translate(-50%, -50%); transform: translate(-50%, -50%);}
    header .mobile-only .mobile-menu-wrap .mobile-menu-con > li {width: 50%; height: fit-content; cursor: pointer;}
    header .mobile-only .mobile-menu-wrap .mobile-menu-con > li + li {border-top: 1px solid var(--pb700);}
    header .mobile-only .mobile-menu-wrap .mobile-menu-con > li > h6 {height: 5.2rem; font-size: 1.4rem; font-weight: 500; color: #fff; display: flex; align-items: center; justify-content: center;}
    header .mobile-only .mobile-menu-wrap .mobile-menu-con > li > ul {display: none; width: 50%; height: 100%; position: absolute; top: 0; right: 0; background-color: var(--white02);}
    header .mobile-only .mobile-menu-wrap .mobile-menu-con > li > ul > li {position: relative; height: 5.3rem; font-size: 1.4rem; font-weight: 500; color: var(--gray500); display: flex; align-items: center; justify-content: center;}
    header .mobile-only .mobile-menu-wrap .mobile-menu-con > li > ul > li.active {font-weight: 600; color: var(--black);}
    header .mobile-only .mobile-menu-wrap .mobile-menu-con > li > ul > li:last-child {border-bottom: 1px solid var(--gray200);}
    header .mobile-only .mobile-menu-wrap .mobile-menu-con > li > ul > li + li::before {content: ''; display: block; width: 100%; height: .1rem; background-color: var(--gray200); position: absolute; top: -1px; left: 0;}

    header .mobile-only .mobile-menu-wrap .mobile-menu-con > li.active > h6 {font-weight: 600; color: var(--yr500);}
    header .mobile-only .mobile-menu-wrap .mobile-menu-con > li.active > ul {display: block;}
    
    header .mobile-only .mobile-menu-wrap .mobile-menu-sns {width: fit-content; margin: 2rem auto; display: grid; grid-template-columns: repeat(3, 1fr); grid-template-rows: repeat(2, 1fr); grid-gap: 1.2rem;}
    header .mobile-only .mobile-menu-wrap .mobile-menu-sns > li {margin: 0 auto;}
    
    header.db .mobile-only .mobile-menu-wrap .user-wrap {background-color: var(--db-main); border-color: rgba(113, 187, 152, .4);}
    header.db .mobile-only .mobile-menu-wrap .mobile-menu-con {background-color: var(--db-main);}
    header.db .mobile-only .mobile-menu-wrap .mobile-menu-con::before {border-color: rgba(113, 187, 152, .4);}
    header.db .mobile-only .mobile-menu-wrap .mobile-menu-con > li + li {border-top-color: rgba(113, 187, 152, .4);}
    header.db .mobile-only .mobile-menu-wrap .user-wrap > ul > li:nth-child(1) {background-color: #3B8158;}
    header.db .mobile-only .mobile-menu-wrap .user-wrap > ul > li:nth-child(2) {background-color: #71BB98;}
    header.db .mobile-only .mobile-menu-wrap .mobile-menu-con > li.active > h6 {color: var(--pb900);}
    header.db .mobile-only .mobile-menu-wrap .mobile-menu-con > li > ul > li.active {color: var(--db-main);}

    header.ss .mobile-only .mobile-menu-wrap .user-wrap {background-color: var(--ss-main); border-color: rgba(109, 148, 199, .4);}
    header.ss .mobile-only .mobile-menu-wrap .mobile-menu-con {background-color: var(--ss-main);}
    header.ss .mobile-only .mobile-menu-wrap .mobile-menu-con::before {border-color: rgba(109, 148, 199, .4);}
    header.ss .mobile-only .mobile-menu-wrap .mobile-menu-con > li + li {border-top-color: rgba(109, 148, 199, .4);}
    header.ss .mobile-only .mobile-menu-wrap .user-wrap > ul > li:nth-child(1) {background-color: #1D65C4;}
    header.ss .mobile-only .mobile-menu-wrap .user-wrap > ul > li:nth-child(2) {background-color: #6D94C7;}
    header.ss .mobile-only .mobile-menu-wrap .mobile-menu-con > li.active > h6 {color: var(--ss-point);}
    header.ss .mobile-only .mobile-menu-wrap .mobile-menu-con > li > ul > li.active {color: var(--ss-main);}

    header.sk .mobile-only .mobile-menu-wrap .user-wrap {background-color: var(--sk-main); border-color: rgba(236, 105, 111, .4);}
    header.sk .mobile-only .mobile-menu-wrap .mobile-menu-con {background-color: var(--sk-main);}
    header.sk .mobile-only .mobile-menu-wrap .mobile-menu-con::before {border-color: rgba(236, 105, 111, .4);}
    header.sk .mobile-only .mobile-menu-wrap .mobile-menu-con > li + li {border-top-color: rgba(236, 105, 111, .4);}
    header.sk .mobile-only .mobile-menu-wrap .user-wrap > ul > li:nth-child(1) {background-color: #EC696F;}
    header.sk .mobile-only .mobile-menu-wrap .user-wrap > ul > li:nth-child(2) {background-color: #FF878C;}
    header.sk .mobile-only .mobile-menu-wrap .mobile-menu-con > li.active > h6 {color: var(--sk-point);}
    header.sk .mobile-only .mobile-menu-wrap .mobile-menu-con > li > ul > li.active {color: var(--sk-main);}

    header.lg .mobile-only .mobile-menu-wrap .user-wrap {background-color: var(--lg-main); border-color: rgba(153, 110, 112, .4);}
    header.lg .mobile-only .mobile-menu-wrap .mobile-menu-con {background-color: var(--lg-main);}
    header.lg .mobile-only .mobile-menu-wrap .mobile-menu-con::before {border-color: rgba(153, 110, 112, .4);}
    header.lg .mobile-only .mobile-menu-wrap .mobile-menu-con > li + li {border-top-color: rgba(153, 110, 112, .4);}
    header.lg .mobile-only .mobile-menu-wrap .user-wrap > ul > li:nth-child(1) {background-color: #713D3F;}
    header.lg .mobile-only .mobile-menu-wrap .user-wrap > ul > li:nth-child(2) {background-color: #996E70;}
    header.lg .mobile-only .mobile-menu-wrap .mobile-menu-con > li.active > h6 {color: var(--lg-point);}
    header.lg .mobile-only .mobile-menu-wrap .mobile-menu-con > li > ul > li.active {color: var(--lg-main);}

    header.or .mobile-only .mobile-menu-wrap .user-wrap {background-color: var(--or-main); border-color: rgba(254, 145, 102, .4);}
    header.or .mobile-only .mobile-menu-wrap .mobile-menu-con {background-color: var(--or-main);}
    header.or .mobile-only .mobile-menu-wrap .mobile-menu-con::before {border-color: rgba(254, 145, 102, .4);}
    header.or .mobile-only .mobile-menu-wrap .mobile-menu-con > li + li {border-top-color: rgba(254, 145, 102, .4);}
    header.or .mobile-only .mobile-menu-wrap .user-wrap > ul > li:nth-child(1) {background-color: #7DA1C9;}
    header.or .mobile-only .mobile-menu-wrap .user-wrap > ul > li:nth-child(2) {background-color: #63748D;}
    header.or .mobile-only .mobile-menu-wrap .mobile-menu-con > li.active > h6 {color: var(--or-point);}
    header.or .mobile-only .mobile-menu-wrap .mobile-menu-con > li > ul > li.active {color: var(--or-main);}

    header.kgc .mobile-only .mobile-menu-wrap .user-wrap {background-color: var(--kgc-main); border-color: rgba(231, 119, 136, .4);}
    header.kgc .mobile-only .mobile-menu-wrap .mobile-menu-con {background-color: var(--kgc-main);}
    header.kgc .mobile-only .mobile-menu-wrap .mobile-menu-con::before {border-color: rgba(231, 119, 136, .4);}
    header.kgc .mobile-only .mobile-menu-wrap .mobile-menu-con > li + li {border-top-color: rgba(231, 119, 136, .4);}
    header.kgc .mobile-only .mobile-menu-wrap .user-wrap > ul > li:nth-child(1) {background-color: #E77788;}
    header.kgc .mobile-only .mobile-menu-wrap .user-wrap > ul > li:nth-child(2) {background-color: #FF92A2;}
    header.kgc .mobile-only .mobile-menu-wrap .mobile-menu-con > li.active > h6 {color: var(--kgc-point);}
    header.kgc .mobile-only .mobile-menu-wrap .mobile-menu-con > li > ul > li.active {color: var(--kgc-main);}

    header.kcc .mobile-only .mobile-menu-wrap .user-wrap {background-color: var(--kcc-main); border-color: rgba(102, 110, 128, .4);}
    header.kcc .mobile-only .mobile-menu-wrap .mobile-menu-con {background-color: var(--kcc-main);}
    header.kcc .mobile-only .mobile-menu-wrap .mobile-menu-con::before {border-color: rgba(102, 110, 128, .4);}
    header.kcc .mobile-only .mobile-menu-wrap .mobile-menu-con > li + li {border-top-color: rgba(102, 110, 128, .4);}
    header.kcc .mobile-only .mobile-menu-wrap .user-wrap > ul > li:nth-child(1) {background-color: #666E80;}
    header.kcc .mobile-only .mobile-menu-wrap .user-wrap > ul > li:nth-child(2) {background-color: #9499A3;}
    header.kcc .mobile-only .mobile-menu-wrap .mobile-menu-con > li.active > h6 {color: var(--kcc-point);}
    header.kcc .mobile-only .mobile-menu-wrap .mobile-menu-con > li > ul > li.active {color: var(--kcc-main);}

    header.kt .mobile-only .mobile-menu-wrap .user-wrap {background-color: var(--kt-main); border-color: rgba(243, 110, 107, .4);}
    header.kt .mobile-only .mobile-menu-wrap .mobile-menu-con {background-color: var(--kt-main);}
    header.kt .mobile-only .mobile-menu-wrap .mobile-menu-con::before {border-color: rgba(243, 110, 107, .4);}
    header.kt .mobile-only .mobile-menu-wrap .mobile-menu-con > li + li {border-top-color: rgba(243, 110, 107, .4);}
    header.kt .mobile-only .mobile-menu-wrap .user-wrap > ul > li:nth-child(1) {background-color: #F36E6B;}
    header.kt .mobile-only .mobile-menu-wrap .user-wrap > ul > li:nth-child(2) {background-color: #EF9390;}
    header.kt .mobile-only .mobile-menu-wrap .mobile-menu-con > li.active > h6 {color: var(--kt-point);}
    header.kt .mobile-only .mobile-menu-wrap .mobile-menu-con > li > ul > li.active {color: var(--kt-main);}

    header.el .mobile-only .mobile-menu-wrap .user-wrap {background-color: var(--el-main); border-color: rgba(121, 113, 151, .4);}
    header.el .mobile-only .mobile-menu-wrap .mobile-menu-con {background-color: var(--el-main);}
    header.el .mobile-only .mobile-menu-wrap .mobile-menu-con::before {border-color: rgba(121, 113, 151, .4);}
    header.el .mobile-only .mobile-menu-wrap .mobile-menu-con > li + li {border-top-color: rgba(121, 113, 151, .4);}
    header.el .mobile-only .mobile-menu-wrap .user-wrap > ul > li:nth-child(1) {background-color: #797197;}
    header.el .mobile-only .mobile-menu-wrap .user-wrap > ul > li:nth-child(2) {background-color: #9A93B6;}
    header.el .mobile-only .mobile-menu-wrap .mobile-menu-con > li.active > h6 {color: var(--el-point);}
    header.el .mobile-only .mobile-menu-wrap .mobile-menu-con > li > ul > li.active {color: var(--el-main);}

    header.hd .mobile-only .mobile-menu-wrap .user-wrap {background-color: var(--hd-main); border-color: rgba(97, 113, 150, .4);}
    header.hd .mobile-only .mobile-menu-wrap .mobile-menu-con {background-color: var(--hd-main);}
    header.hd .mobile-only .mobile-menu-wrap .mobile-menu-con::before {border-color: rgba(97, 113, 150, .4);}
    header.hd .mobile-only .mobile-menu-wrap .mobile-menu-con > li + li {border-top-color: rgba(97, 113, 150, .4);}
    header.hd .mobile-only .mobile-menu-wrap .user-wrap > ul > li:nth-child(1) {background-color: #617196;}
    header.hd .mobile-only .mobile-menu-wrap .user-wrap > ul > li:nth-child(2) {background-color: #7C89A9;}
    header.hd .mobile-only .mobile-menu-wrap .mobile-menu-con > li.active > h6 {color: var(--hd-point);}
    header.hd .mobile-only .mobile-menu-wrap .mobile-menu-con > li > ul > li.active {color: var(--hd-main);}

    header .mobile-only .mobile-club-wrap {position: absolute; width: 100vw; height: calc(100vh - 6rem); padding: 1.6rem; display: none; background-color: var(--gray100); overflow-y: auto; flex-flow: column; align-items: center; justify-content: start;}
    header .mobile-only .mobile-club-wrap.active {display: flex;}
    header .mobile-only .mobile-club-wrap > li {width: 100%; height: 6rem; padding: 1rem; display: flex; align-items: center; border-radius: .8rem; background-color: var(--white02); cursor: pointer;}
    header .mobile-only .mobile-club-wrap > li + li {margin-top: .8rem;}
    header .mobile-only .mobile-club-wrap > li i {width: 6rem; height: 4rem; margin-right: 1.2rem}
    header .mobile-only .mobile-club-wrap > li p {font-size: 1.6rem; font-weight: 600;}
}
@media screen and (max-width: 820px) {
    header .mobile-only .header-wrap {padding: 1.6rem;}
    header .mobile-only .mobile-menu-wrap {width: 100vw;}
}


/* navigation --------------------------- */
.nav-wrap {position: absolute; top: 10.1rem; width: 100%; height: 4.8rem; background-color: var(--pb900);}
/* .nav-wrap {position: absolute; top: 10.1rem; width: 100%; background-color: var(--pb900); background-image: url(/imgs/nav-con-pattern.svg); background-repeat: no-repeat; background-size: cover; background-position: center top 4.9rem;} */
.nav-wrap .nav-con {width: 128rem; padding: 0 4rem; margin: 0 auto; display: flex; align-items: flex-start; justify-content: space-between;}
.nav-wrap .nav-con > li {display: flex; flex-flow: column; cursor: pointer; position: relative;}
/* .kbl .nav-wrap .nav-con > li:nth-child(1):nth-last-child(10), .nav-wrap .nav-con > li:nth-child(1):nth-last-child(10) ~ li {width: calc(100% / 10);}
.youth .nav-wrap .nav-con > li:nth-child(1):nth-last-child(8), .nav-wrap .nav-con > li:nth-child(1):nth-last-child(8) ~ li {width: calc(100% / 8);}
.nav-wrap .nav-con > li:nth-child(1):nth-last-child(9), .nav-wrap .nav-con > li:nth-child(1):nth-last-child(9) ~ li {width: calc(100% / 9);} */
.nav-wrap .nav-con > li > h6 {display: flex; align-items: center; height: 4.8rem; font-size: 1.4rem; font-weight: 500; color: #fff;}
.nav-wrap .nav-con > li > ul {display: none; width: max-content; padding: 2rem; border-radius: .4rem; background-color: var(--white01); position: absolute; top: 4.8rem; left: 50%; -webkit-transform: translateX(-50%); -moz-transform: translateX(-50%); -ms-transform: translateX(-50%); -o-transform: translateX(-50%); transform: translateX(-50%);}
.nav-wrap .nav-con > li > ul::before {content: ''; width: 1.2rem; height: .8rem; background-image: url(/imgs/nav-sub-menu.svg); background-repeat: no-repeat; background-size: contain; background-position: center; position: absolute; top: -.8rem; left: 50%; -webkit-transform: translateX(-50%); -moz-transform: translateX(-50%); -ms-transform: translateX(-50%); -o-transform: translateX(-50%); transform: translateX(-50%);}
.nav-wrap .nav-con > li > ul > li {font-size: 1.2rem; font-weight: 500; color: var(--gray500); cursor: pointer; word-break: keep-all; line-height: 1.4;}
.nav-wrap .nav-con > li > ul > li:hover,
.nav-wrap .nav-con > li > ul > li.active {font-weight: 600; color: var(--yr500);}
.nav-wrap .nav-con > li > ul > li + li {margin-top: 2rem;}

.nav-wrap .nav-con > li > ul:hover,
.nav-wrap .nav-con > li > h6:hover + ul {display: block; z-index: 100;}
/* .nav-wrap .nav-con:hover::before {content: ''; display: block; width: 100vw; min-width: 128rem; height: 1px; background-color: var(--pb800); position: absolute; top: 4.8rem; left: 0;} */

/* 구단별 navigation */
.db .nav-wrap {background-color: var(--db-main);}
.db .nav-wrap .nav-con:hover::before {background-color: var(--db-sub01); opacity: .3;}
.db .nav-wrap .nav-con > li > ul > li.active {color: var(--pb900);}
.ss .nav-wrap {background-color: var(--ss-main);}
.ss .nav-wrap .nav-con:hover::before {background-color: var(--ss-sub01); opacity: .3;}
.ss .nav-wrap .nav-con > li > ul > li.active {color: var(--ss-point);}
.sk .nav-wrap {background-color: var(--sk-main);}
.sk .nav-wrap .nav-con:hover::before {background-color: var(--sk-sub01); opacity: .3;}
.sk .nav-wrap .nav-con > li > ul > li.active {color: var(--sk-point);}
.sk .nav-wrap {background-color: var(--sk-main);}
.sk .nav-wrap .nav-con:hover::before {background-color: var(--sk-sub01); opacity: .3;}
.sk .nav-wrap .nav-con > li > ul > li.active {color: var(--sk-point);}
.lg .nav-wrap {background-color: var(--lg-main);}
.lg .nav-wrap .nav-con:hover::before {background-color: var(--lg-sub01); opacity: .3;}
.lg .nav-wrap .nav-con > li > ul > li.active {color: var(--lg-point);}
.or .nav-wrap {background-color: var(--or-main);}
.or .nav-wrap .nav-con:hover::before {background-color: var(--or-sub01); opacity: .3;}
.or .nav-wrap .nav-con > li > ul > li.active {color: var(--or-point);}
.el .nav-wrap {background-color: var(--el-main);}
.el .nav-wrap .nav-con:hover::before {background-color: var(--el-sub01); opacity: .3;}
.el .nav-wrap .nav-con > li > ul > li.active {color: var(--el-point);}
.kcc .nav-wrap {background-color: var(--kcc-main);}
.kcc .nav-wrap .nav-con:hover::before {background-color: var(--kcc-sub01); opacity: .3;}
.kcc .nav-wrap .nav-con > li > ul > li.active {color: var(--kcc-point);}
.kgc .nav-wrap {background-color: var(--kgc-main);}
.kgc .nav-wrap .nav-con:hover::before {background-color: var(--kgc-sub01); opacity: .3;}
.kgc .nav-wrap .nav-con > li > ul > li.active {color: var(--kgc-point);}
.kt .nav-wrap {background-color: var(--kt-main);}
.kt .nav-wrap .nav-con:hover::before {background-color: var(--kt-sub01); opacity: .3;}
.kt .nav-wrap .nav-con > li > ul > li.active {color: var(--kt-point);}
.hd .nav-wrap {background-color: var(--hd-main);}
.hd .nav-wrap .nav-con:hover::before {background-color: var(--hd-sub01); opacity: .3;}
.hd .nav-wrap .nav-con > li > ul > li.active {color: var(--hd-point);}

/* footer --------------------------- */
footer {width: 100%; min-width: 128rem; height: 100%; border-top: 1px solid var(--gray200); background-color: var(--white02);}
footer .footer-wrap {width: 128rem; padding: 4rem; margin: 0 auto; display: flex; align-items: flex-start;}
footer .footer-wrap > * + * {margin-left: 4rem;} 

footer .footer-wrap .info-wrap {width: calc(100% - 4rem - 7.2rem - 27.6rem); display: flex; flex-flow: column;}
footer .footer-wrap .info-wrap .page-link {display: flex; align-items: center;}
footer .footer-wrap .info-wrap .page-link > li {display: flex; align-items: center; font-size: 1.4rem; font-weight: 500; cursor: pointer;}
footer .footer-wrap .info-wrap .page-link > li + li::before {content: ''; display: block; width: .1rem; height: 1.2rem; margin: 0 .8rem; background-color: var(--gray300);}
footer .footer-wrap .info-wrap > p {font-size: 1.2rem; line-height: 1.6; margin-top: 3rem;}
footer .footer-wrap .info-wrap .info {display: flex; align-items: center; margin-top: 2rem;}
footer .footer-wrap .info-wrap .info > li {display: flex; align-items: center; font-size: 1.2rem;}
footer .footer-wrap .info-wrap .info > li + li::before {content: ''; display: block; width: .1rem; height: 1rem; margin: 0 .6rem; background-color: var(--black);}
footer .footer-wrap .info-wrap > span {margin-top: 2rem; font-size: 1.2rem; color: var(--gray500);}

footer .footer-wrap .sns-wrap {display: flex; flex-flow: column; row-gap: 1.2rem; align-items: flex-end;}
footer .footer-wrap .sns-wrap > select {width: 14rem; height: 3.2rem; font-size: 1.2rem;}
footer .footer-wrap .sns-wrap > ul {display: flex; align-items: center; justify-content: flex-end; column-gap: 1.2rem;}

@media screen and (max-width: 1024px) {
    footer {min-width: initial;}
    footer .footer-wrap {width: 100%;}

    footer .footer-wrap .info-wrap {width: calc(100% - 4rem - 7.2rem - 13.2rem); display: flex; flex-flow: column;}
    footer .footer-wrap .info-wrap .page-link > li {font-size: 1.2rem;}
    footer .footer-wrap .info-wrap .page-link > li + li::before {height: 1rem;}
    footer .footer-wrap .info-wrap > p {margin-top: 1.2rem; font-size: 1.2rem; word-break: keep-all;}
    footer .footer-wrap .info-wrap .info {flex-wrap: wrap; margin-top: .8rem;}
    footer .footer-wrap .info-wrap .info > li {font-size: 1.2rem; line-height: 1.6; white-space: nowrap;}
    footer .footer-wrap .info-wrap .info > li + li::before {height: .6rem;}
    footer .footer-wrap .info-wrap .info > li:nth-child(3):before {content: none;}
    footer .footer-wrap .info-wrap > span {margin-top: .8rem; font-size: 1.2rem;}

    footer .footer-wrap .sns-wrap {display: flex; flex-flow: column; row-gap: 1.2rem; align-items: flex-end;}
    footer .footer-wrap .sns-wrap > select {width: 14rem; height: 3.2rem; font-size: 1.2rem;}
    footer .footer-wrap .sns-wrap > ul {display: flex; align-items: center; justify-content: flex-end; column-gap: 1.2rem;}
    
    footer .mobile-only {display: block;}
}
@media screen and (max-width: 820px) {
    footer .footer-wrap {width: 100%; padding: 3.2rem; flex-flow: column; align-items: center; justify-content: center;}
    footer .footer-wrap > * + * {margin-left: 0;} 
    
    footer .footer-wrap .info-wrap {width: 100%; align-items: center;}
    footer .footer-wrap .info-wrap .page-link {margin-top: 2.4rem;}
    footer .footer-wrap .info-wrap > p {margin-top: 2.4rem; text-align: center; font-size: 1rem;}
    footer .footer-wrap .info-wrap .info {justify-content: center; margin-top: 1.6rem;}
    footer .footer-wrap .info-wrap .info > li {font-size: 1rem;}
    footer .footer-wrap .info-wrap > span {margin-top: 1.6rem; font-size: 1rem;}
    
    footer .footer-wrap .sns-wrap {align-items: center; margin-top: 3rem;}
}


/* container --------------------------- */
#container {position: relative; width: 100%; margin-top: 4.8rem; background-color: var(--white02);}
.contents {width: 128rem; margin: 0 auto; padding: 0 4rem 8rem; min-height: calc(100vh - 14.8rem - 22.8rem);}

.con-box + .con-box, .con-box + form {margin-top: 6rem;}
.con-box .con-tit {position: relative; display: flex; align-items: center; justify-content: space-between; margin-bottom: 2.4rem;}
.con-box .con-tit .con-tit-tab {display: flex; align-items: flex-end;}
.con-box .con-tit .con-tit-tab > * + * {margin-left: 1.6rem;}
.con-box .con-tit h4 {display: flex; align-items: center; font-size: 2.4rem; font-weight: 600;}
.con-box .con-tit h4 > i {margin-right: .6rem;}
.con-box .con-tit p {font-size: 1.4rem; color: var(--gray500); word-break: keep-all; line-height: 1.4; padding-right: 1.2rem;}
.con-box .con-tit > button {padding: 0 2rem; height: 4.8rem; font-size: 1.6rem;}
.con-box .con-tit select {width: fit-content;}
.con-box .con-tit .flex-tit {display: flex; align-items: center;}
.con-box .con-tit .flex-tit > li {font-size: 1.8rem; font-weight: 600; display: flex; align-items: center;}
.con-box .con-tit .flex-tit > li + li::before {content: ''; width: 1px; height: 1.4rem; background-color: var(--gray400); margin: 0 .6rem;}
.con-box .con-tit .option-wrap {display: flex; align-items: center;}
.con-box .con-tit .option-wrap > * + * {margin-left: .8rem;}
.con-box .con-tit .option-wrap select {width: 18rem;}
.con-box .con-tit .fit-select select {width: fit-content;}
.con-box .con-tit .more {font-size: 1.6rem; font-weight: 500; color: var(--gray500); cursor: pointer;}

.con-header {padding: 6rem 0 8rem;}
.con-header h2 {position: relative; padding-top: .8rem; font-size: 3rem; font-weight: 600;}
.con-header h2::before {content: ''; width: 1.6rem; height: .2rem; background-color: var(--black); display: block; position: absolute; top: 0; left: 0;}
.con-header p {font-size: 1.4rem; color: var(--gray500); margin-top: 1.6rem; line-height: 1.6; word-break: keep-all;}

@media screen and (max-width: 1024px) {
    #container {margin-top: 0;}
    .contents {width: 100%; padding: 0 3.2rem 6rem;}
    
    .con-box + .con-box, .con-box + form {margin-top: 4rem;}
    .con-box .con-tit {margin-bottom: 1.6rem;}
    .con-box .con-tit h4 {font-size: 2rem;}
    .con-box .con-tit h4 > i {width: 2rem; height: 2rem;}
    .con-box .con-tit > button {padding: 0 1.2rem; height: 4rem; font-size: 1.4rem;}
    .con-box .con-tit .flex-tit > li {font-size: 1.6rem;}
    .con-box .con-tit .flex-tit > li + li::before {height: 1.2rem;}
    .con-box .con-tit .more {font-size: 1.4rem;}

    .con-header {padding: 4rem 0 6rem;}
    .con-header h2 {font-size: 2.4rem;}
}
@media screen and (max-width: 820px) {
    .contents {padding: 0 1.6rem 4rem;}
    
    .con-box .con-tit {flex-wrap: wrap; row-gap: 1.2rem;}
    .con-box .con-tit.con-tit-desc {flex-direction: column-reverse;}
    .con-box .con-tit .con-tit-tab {width: 100%; justify-content: space-between;}
    .con-box .con-tit .con-tit-tab > * + * {margin-left: 0;}
    .con-box .con-tit p {font-size: 1.2rem; width: 100%; padding: 0;}
    .con-box .con-tit.one-line p {width: fit-content;}
    .con-box .con-tit .option-wrap {flex-flow: column; width: 100%; row-gap: 1.2rem;}
    .con-box .con-tit .option-wrap.option-mb {width: fit-content;}
    .con-box .con-tit .option-wrap > * {width: 100%; margin-left: 0;}
    .con-box .con-tit .option-wrap select {display: none;}
    .con-box .con-tit .option-wrap.option-mb select {display: block;}
    .con-box .con-tit .option-wrap .search-input {margin-top: -2rem; margin-bottom: 1.6rem;}

    .con-header {padding: 3.2rem 0 4rem;}
    .con-header h2 {padding-top: 1rem; font-size: 2rem;}
    .con-header h2::before {width: 1.4rem;}
    .con-header p {margin-top: 1.2rem;}

    .con-box + .con-box, .con-box + form {margin-top: 3.2rem;}
}


/* main --------------------------- */
.view-mode-change {position: fixed; bottom: 3.2rem; right: 2.4rem; width: fit-content; padding: 0 1.2rem; height: 4.4rem; box-shadow: 2px 2px 20px rgba(1, 18, 65, 0.04); background-color: var(--white01); border: 1px solid var(--gray200); border-radius: 3rem; display: flex; align-items: center; justify-content: center; column-gap: .4rem; cursor: pointer; z-index: 100;}
.view-mode-change p {font-size: 1.4rem; font-weight: 600; color: var(--gray500); align-items: center; display: flex; column-gap: .2rem;}
/*.view-mode-change p::before {content: '다크';}*/

@media (prefers-color-scheme: dark) {
    .view-mode-change .ic-dark-16 {background-image: url(/imgs/ico/ic-light-16.svg);}
    /*.view-mode-change p::before {content: '라이트';}*/

}
[data-theme="dark"] .view-mode-change .ic-dark-16 {background-image: url(/imgs/ico/ic-light-16.svg);}

.main {padding: 0 4rem 10rem;}
.main .con-box + .con-box {margin-top: 10rem;}
.main .main-con + .con-box {margin-top: 10rem;}

.main-con {display: grid; column-gap: 4.8rem;}
.main-con.grid340 {grid-template-columns: calc(100% - 38.8rem) 34rem;}
.main-con + .main-con {margin-top: 10rem;}
.main-con .con-box + .con-box {margin-top: 0;}

.main-con.grid340.ver02 {align-items: center;}
.main-con.grid340.ver02 .box-banner-swiper01 {margin: 0;}

@media screen and (max-width: 1024px) {
    .main {padding: 0 3.2rem 6rem;}
    .main .con-box + .con-box {margin-top: 4.8rem;}
    .main .main-con + .con-box {margin-top: 4.8rem;}

    .main-con {column-gap: 2rem;}
    .main-con.grid340 {grid-template-columns: calc(100% - 36rem) 34rem;}
    .main-con + .main-con {margin-top: 4.8rem;}
    .main-con .con-box + .con-box {margin-top: 0;}
}
@media screen and (max-width: 820px) {
    .main {padding: 0 1.6rem 4.8rem;}
    .main .con-box + .con-box {margin-top: 4.8rem;}
    .main .main-con + .con-box {margin-top: 4.8rem;}

    .main-con {display: grid; column-gap: 0; row-gap: 4.8rem;}
    .main-con.grid340 {grid-template-columns: repeat(1, 100%);}
    .main-con + .main-con {margin-top: 4.8rem;}
    .main-con .con-box + .con-box {margin-top: 0;}
}

/* main banner */
.main-banner-mobile-swiper01 {display: none;}

.main-banner-desktop-swiper01 {height: 52rem; margin-bottom: 10rem;}
.main-banner-desktop-swiper01::before {content: ''; display: block; width: 100vw; min-width: 128rem; height: 52rem; background-color:  #000; position: absolute; left: 0;}

.main-banner-desktop-swiper01 .main-banner-wrap .swiper-slide {transform: initial!important; background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 70%, #000000 100%), linear-gradient(270deg, rgba(18, 18, 18, 0) 60%, #000000 80%), linear-gradient(270deg, #000000 5%, rgba(0, 0, 0, 0) 30%);}
.main-banner-desktop-swiper01 .main-banner-con .txt {width: 48rem; height: 44.8rem; display: flex; flex-flow: column; justify-content: center;}
.main-banner-desktop-swiper01 .main-banner-con .txt > h4 {font-size: 3rem; font-weight: 600; line-height: 1.4; color: #fff;}
.main-banner-desktop-swiper01 .main-banner-con .txt > p {margin-top: 2rem; font-size: 1.4rem; color: var(--gray500); line-height: 1.6;}
.main-banner-desktop-swiper01 .main-banner-con .txt > a {width: fit-content; height: 2.4rem; margin-top: 4rem; font-size: 1.6rem; font-weight: 600; color: #fff; vertical-align: tpo; border-bottom: 1px solid #fff;}

.main-banner-desktop-swiper01 .swiper-slide-active .main-banner-con .img {position: absolute; top: 0; right: 0; z-index: -1; width: 100rem; height: 100%; display: block; background-repeat: no-repeat; background-size: contain; background-position: center; transform: scale(1.3, 1.3); -webkit-animation-name: backgroundScale01; animation-name: backgroundScale01; -webkit-animation-timing-function: ease-out; animation-timing-function: ease-out; -webkit-animation-duration: 5000ms; animation-duration: 5000ms; -webkit-animation-iteration-count: 1; animation-iteration-count: 1; -webkit-animation-fill-mode: forwards; animation-fill-mode: forwards;}
@-webkit-keyframes backgroundScale01 {
  from {-ms-transform: scale(1.2, 1.2); transform: scale(1.2, 1.2);}
  to {-ms-transform: scale(1.3, 1.3); transform: scale(1.3, 1.3);}
}
@keyframes backgroundScale01 {
    from {-ms-transform: scale(1.2, 1.2); transform: scale(1.2, 1.2);}
    to {-ms-transform: scale(1.3, 1.3); transform: scale(1.3, 1.3);}
}

.main-banner-desktop-swiper01 .main-banner-list .swiper-wrapper .swiper-slide {width: calc(100% / 5);}
.main-banner-desktop-swiper01 .main-banner-list .swiper-wrapper .swiper-slide:nth-child(1):nth-last-child(1) {width: 100%;}
.main-banner-desktop-swiper01 .main-banner-list .swiper-wrapper .swiper-slide:nth-child(1):nth-last-child(2), .main-banner-desktop-swiper01 .main-banner-list .swiper-wrapper .swiper-slide:nth-child(1):nth-last-child(2) ~ .swiper-slide {width: calc(100% / 2);}
.main-banner-desktop-swiper01 .main-banner-list .swiper-wrapper .swiper-slide:nth-child(1):nth-last-child(3), .main-banner-desktop-swiper01 .main-banner-list .swiper-wrapper .swiper-slide:nth-child(1):nth-last-child(3) ~ .swiper-slide {width: calc(100% / 3);}
.main-banner-desktop-swiper01 .main-banner-list .swiper-wrapper .swiper-slide:nth-child(1):nth-last-child(4), .main-banner-desktop-swiper01 .main-banner-list .swiper-wrapper .swiper-slide:nth-child(1):nth-last-child(4) ~ .swiper-slide {width: calc(100% / 4);}

.main-banner-desktop-swiper01 .main-banner-list {position: relative;}
.main-banner-desktop-swiper01 .main-banner-list::before {content: ''; display: block; width: 100%; height: 1px; background-color: rgba(255, 255, 255, .2); position: absolute; top: 1px; left: 0; z-index: 1;}
.main-banner-desktop-swiper01 .main-banner-list .swiper-slide {position: relative; display: flex; align-items: center; justify-content: center; cursor: pointer;}
.main-banner-desktop-swiper01 .main-banner-list .swiper-slide + .swiper-slide::before {content: ''; display: block; width: 1px; height: 1.2rem; background-color: rgba(255, 255, 255, .2); position: absolute; left: 0;}

.main-banner-desktop-swiper01 .main-banner-list-con > h6 {display: flex; align-items: center; justify-content: center; height: 7.2rem; text-align: center; font-size: 1.4rem; font-weight: 500; line-height: 1.6; color: var(--gray600);}
.main-banner-desktop-swiper01 .swiper-slide-thumb-active .main-banner-list-con .progress {display: block; height: .2rem; background-color: var(--yr500); animation: progress 5000ms linear; position: absolute; top: 0; left: 0;}
.main-banner-desktop-swiper01 .swiper-slide-thumb-active .main-banner-list-con > h6 {color: var(--yr500);}
@keyframes progress {
    0% {width: 0;}
    100% {width: 100%;}
}

.main-banner-list-nav {position: relative; height: 7.2rem;  top: -7.2rem;}
.main-banner-list-nav .main-banner-list-nav-prev {width: 2rem; height: 2rem; margin: 0; z-index: 1; background-color: #000; background-image: url(/imgs/ico/swiper-nav-prev.svg); background-repeat: no-repeat; background-size: contain; background-position: center; position: absolute; top: 50%; left: 0; -webkit-transform: translateY(-50%); -moz-transform: translateY(-50%); -ms-transform: translateY(-50%); -o-transform: translateY(-50%); transform: translateY(-50%); cursor: pointer;}
.main-banner-list-nav .main-banner-list-nav-prev.swiper-button-disabled {background-image: url(/imgs/ico/swiper-nav-prev-disabled.svg); cursor: default;}
.main-banner-list-nav .main-banner-list-nav-next {width: 2rem; height: 2rem; margin: 0; z-index: 1; background-color: #000; background-image: url(/imgs/ico/swiper-nav-next.svg); background-repeat: no-repeat; background-size: contain; background-position: center; position: absolute; top: 50%; right: 0; -webkit-transform: translateY(-50%); -moz-transform: translateY(-50%); -ms-transform: translateY(-50%); -o-transform: translateY(-50%); transform: translateY(-50%); cursor: pointer;}
.main-banner-list-nav .main-banner-list-nav-next.swiper-button-disabled {background-image: url(/imgs/ico/swiper-nav-next-disabled.svg); cursor: default;}

@media screen and (max-width: 1024px) {
    .main-banner-desktop-swiper01 {margin-bottom: 4.8rem;}
    .main-banner-desktop-swiper01::before {min-width: initial;}
}
@media screen and (max-width: 820px) {
    .main-banner-desktop-swiper01 {display: none;}
    .main-banner-mobile-swiper01 {display: block; width: 100vw; margin: 0 -1.6rem 4.8rem;}

    .main-banner-mobile-wrap {position: relative; width: 100%; height: 117vw; background-color:  #000;}
    .main-banner-mobile-wrap .img {width: 100%; height: 100%; background-repeat: no-repeat; background-repeat: no-repeat; background-size: 100% auto; background-position: center top;}
    .main-banner-mobile-wrap .img::before {content: ''; width: 100%; height: 100%; display: block; background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 54.67%, #000000 81.52%);}
    .main-banner-mobile-wrap .txt {width: 100%; position: absolute; bottom: 6rem;}
    .main-banner-mobile-wrap .txt div{width: 80%; height: 6.8rem; margin: 0 auto; display: flex; align-items: center; justify-content: center;}
    .main-banner-mobile-wrap .txt h4 {text-align: center; word-break: keep-all; font-size: 2.4rem; font-weight: 600; line-height: 1.4; color: #fff; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical}
    .main-banner-mobile-wrap .txt a {display: block; width: fit-content; height: 2rem; margin: 1.6rem auto 0; font-size: 1.4rem; font-weight: 600; color: #fff; border-bottom: 1px solid #fff;}
    
    .main-banner-mobile-wrap .swiper-pagination {position: absolute; bottom: 2rem!important; left: 50%!important; -webkit-transform: translateX(-50%); -moz-transform: translateX(-50%); -ms-transform: translateX(-50%); -o-transform: translateX(-50%); transform: translateX(-50%);}
    .main-banner-mobile-wrap .swiper-pagination > * {margin: 0!important;}
    .main-banner-mobile-wrap .swiper-pagination > * + * {margin-left: .8rem!important;}
    .main-banner-mobile-wrap .swiper-pagination .swiper-pagination-bullet {width: 3.2rem; height: .2rem; background-color: rgba(255, 255, 255, .2); border-radius: 0; opacity: 1;}
    .main-banner-mobile-wrap .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {position: relative;}
    .main-banner-mobile-wrap .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active::before {content: ''; display: block; height: .2rem; background-color: var(--yr500); animation: progress 3000ms linear; position: absolute; top: 0; left: 0;}
}

/* 공지사항 뉴스 */
.main-notice-grid {margin: -2.4rem!important; padding: 2.4rem!important;}
.main-notice-grid .swiper-slide {width: 100%; height: 12.6rem; padding: 2rem; display: flex; flex-flow: column; align-items: flex-start; justify-content: center; border: 1px solid var(--gray100); border-radius: 1.2rem; box-shadow: var(--shadow-gr); background-color: var(--white01); background-image: url(/imgs/notice-con-pattern.svg); background-repeat: no-repeat; background-position: right bottom; background-size: cover;}
.main-notice-grid .swiper-slide h6 {width: 100%; height: 4.5rem; font-size: 1.6rem; font-weight: 500; line-height: 1.4; word-break: keep-all; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;}
.main-notice-grid .swiper-slide span:not(.new) {display: block; margin-top: 1.6rem; font-size: 1.4rem; color: var(--gray500);}

@media (prefers-color-scheme: dark) {
    .main-notice-grid {margin: -1.6rem!important; padding: 1.6rem!important;}
    .main-notice-grid .swiper-slide {background-image: url(/imgs/notice-con-pattern-dark.svg);}
}
[data-theme="dark"] .main-notice-grid {margin: -1.6rem!important; padding: 1.6rem!important;}
[data-theme="dark"] .main-notice-grid .swiper-slide {background-image: url(/imgs/notice-con-pattern-dark.svg);}

@media screen and (max-width: 820px) {
    .main-notice-grid {margin: -1.6rem!important; padding: 1.6rem!important;}
    .main-notice-grid .swiper-slide {height: fit-content; padding: 1.6rem;border-radius: .8rem;}
    .main-notice-grid .swiper-slide span:not(.new) {display: block; margin-top: 1.6rem; font-size: 1.4rem; color: var(--gray500);}
    
    .main-notice-grid {padding-bottom: 2.2rem!important;}
    .main-notice-grid .swiper-pagination {position: absolute; bottom: 0rem!important; left: 50%!important; -webkit-transform: translateX(-50%); -moz-transform: translateX(-50%); -ms-transform: translateX(-50%); -o-transform: translateX(-50%); transform: translateX(-50%);}
    .main-notice-grid .swiper-pagination > * {margin: 0!important;}
    .main-notice-grid .swiper-pagination > * + * {margin-left: .4rem!important;}
    .main-notice-grid .swiper-pagination .swiper-pagination-bullet {width: 1.6rem; height: .2rem; background-color: var(--gray200); border-radius: 0; opacity: 1;}
    .main-notice-grid .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {background-color: var(--yr500);}
}

/* 게임노트 */
.main-game-grid {display: grid; grid-auto-flow: column; grid-template-columns: repeat(2, 1fr); grid-template-rows: repeat(3, 1fr); column-gap: 4rem;}
.main-game-grid > li {width: 100%; border-top: 1px solid var(--gray200); height: 6rem; display: flex; align-items: center; justify-content: space-between;}
.main-game-grid > li:nth-child(3n) {border-bottom: 1px solid var(--gray200);} 
.main-game-grid > li h6 {width: 50rem; display: block; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; font-size: 1.6rem; font-weight: 500;}
.main-game-grid > li span:not(.new) {width: 6.2rem; text-align: right; font-size: 1.4rem; color: var(--gray500);}

@media screen and (max-width: 1024px) {
    .main-game-grid {column-gap: 2rem;}
    .main-game-grid > li h6 {width: 30rem;}
}
@media screen and (max-width: 820px) {
    .main-game-grid {grid-template-columns: repeat(1, 1fr); column-gap: 0;}
    .main-game-grid > li {height: 5.2rem;}
    .main-game-grid > li h6 {width: 24rem; font-size: 1.4rem;}
    .main-game-grid > li span:not(.new) {width: 5rem; font-size: 1.2rem;}
    .main-game-grid > li:nth-child(n+4) {display: none;}
}

/* movieclip */
.main-movieclip-con {position: relative; padding: 10rem 0;}
.main-movieclip-con::before {content: ''; width: 100vw; min-width: 128rem; height: 100%; background-color: var(--pb900); background-image: url(/imgs/movieclip-con-pattern.svg); background-repeat: no-repeat; background-position: center; background-size: cover; position: absolute; top: 0; left: 50%; -webkit-transform: translateX(-50%); -moz-transform: translateX(-50%); -ms-transform: translateX(-50%); -o-transform: translateX(-50%); transform: translateX(-50%);}
.main-movieclip-con .con-tit h4 {color: #fff;}

@media (prefers-color-scheme: dark) {
    .main-movieclip-con::before {background-color: var(--gray800); background-image: url(/imgs/movieclip-con-pattern-dark.svg);}
}
[data-theme="dark"] .main-movieclip-con::before {background-color: var(--gray800); background-image: url(/imgs/movieclip-con-pattern-dark.svg);}
@media screen and (max-width: 1024px) {
    .main-movieclip-con {padding: 4.8rem 0;}
    .main-movieclip-con::before {min-width: 100%;}
}
@media screen and (max-width: 820px) {
    .main-movieclip-con::before {min-width: initial; background-image: url(/imgs/movieclip-con-pattern-mb.svg);}
}

.movieclip-swiper01 {position: relative;}
.movieclip-swiper01 .movieclip-con .swiper-slide {display: grid; grid-template-columns: repeat(2, 1fr); column-gap: 4rem; cursor: pointer;}
.movieclip-swiper01 .movieclip-con .swiper-slide .img {position: relative; width: 100%; height: 33rem; background-repeat: no-repeat; background-position: center; background-size: cover; border-radius: 1.2rem;}
.movieclip-swiper01 .movieclip-con .swiper-slide .img::before {content: ''; display: block; width: 100%; height: 100%; background-color: rgba(0, 0, 0, .2); position: absolute; top: 0; left: 0; z-index: 1; border-radius: 1.2rem;}
.movieclip-swiper01 .movieclip-con .swiper-slide .img::after {content: ''; display: block; width: 100%; height: 100%; background-image: url(/imgs/ico/img/img-play-60.svg); background-repeat: no-repeat; background-size: 6rem; background-position: center; position: absolute; top: 0; left: 0; z-index: 2; border-radius: 1.2rem;}
.movieclip-swiper01 .movieclip-con .swiper-slide .txt {display: flex; flex-flow: column; align-items: flex-start; justify-content: start; padding: 1.6rem 0;}
.movieclip-swiper01 .movieclip-con .swiper-slide .txt h6 {width: 34rem; height: 6.8rem; margin-top: .8rem; font-size: 2.4rem; font-weight: 600; color: #fff; line-height: 1.4; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;}
.movieclip-swiper01 .movieclip-con .swiper-slide .txt ul {margin-top: 1.6rem;}

.movieclip-swiper01 .movieclip-list {width: calc((100% - 4rem) / 2); position: absolute; bottom: 0; right: 0; z-index: 2;}
.movieclip-swiper01 .movieclip-list .swiper-slide {position: relative; border: 1px solid transparent; border-radius: .4rem; cursor: pointer;}
.movieclip-swiper01 .movieclip-list .swiper-slide .img {width: 100%; height: 9.8rem; background-repeat: no-repeat; background-size: cover; background-position: center; border-radius: .4rem;}

.movieclip-swiper01 .movieclip-list .swiper-slide.swiper-slide-thumb-active {border-color: var(--yr500)}
.movieclip-swiper01 .movieclip-list .swiper-slide.swiper-slide-thumb-active::before {content: ''; display: block; width: 100%; height: 100%; background-color: rgba(255, 96, 28, .2); position: absolute; top: 0; left: 0; border-radius: .4rem;}

@media screen and (max-width: 1024px) {
    .movieclip-swiper01 .movieclip-con .swiper-slide {column-gap: 2rem;}
    .movieclip-swiper01 .movieclip-con .swiper-slide .img  {height: 32vw; border-radius: .8rem;}
    .movieclip-swiper01 .movieclip-list .swiper-slide .img {height: 8vw;}

    .movieclip-swiper01 .movieclip-list {width: calc((100% - 2rem) / 2);}
}
@media screen and (max-width: 820px) {
    .movieclip-swiper01 .movieclip-con .swiper-slide {grid-template-columns: repeat(1, 1fr); column-gap: 0; row-gap: 1.2rem;}
    .movieclip-swiper01 .movieclip-con .swiper-slide .img {height: 50vw;}
    .movieclip-swiper01 .movieclip-con .swiper-slide .img::after {background-size: 4.8rem;}
    .movieclip-swiper01 .movieclip-con .swiper-slide .txt {padding: 0;}
    .movieclip-swiper01 .movieclip-con .swiper-slide .txt h6 {width: 100%; height: 4.4rem; margin-top: .4rem; font-size: 1.6rem; font-weight: 500;}
    .movieclip-swiper01 .movieclip-con .swiper-slide .txt ul {margin-top: 1.2rem;}
    
    .movieclip-swiper01 .movieclip-list {width: 100%; margin-top: 3.2rem; position: relative; bottom: initial; right: initial; z-index: initial;}
    .movieclip-swiper01 .movieclip-list .swiper-slide .img {height: 16vw;} 
}

/* hot photo */
.main-hot-photo-swiper01 {width: 100vw; min-width: 128rem; left: 50%; -webkit-transform: translateX(-50%); -moz-transform: translateX(-50%); -ms-transform: translateX(-50%); -o-transform: translateX(-50%); transform: translateX(-50%);}
.main-hot-photo-swiper01 .swiper-wrapper {align-items: center;}
.main-hot-photo-swiper01 .swiper-slide {width: 53rem; height: 29.7rem; position: relative; cursor: pointer;}
.main-hot-photo-swiper01 .swiper-slide .img {position: relative; width: 100%; height: 100%; background-repeat: no-repeat; background-size: cover; background-position: center; border-radius: 1.2rem;}
.main-hot-photo-swiper01 .swiper-slide .img::before {content: ''; width: 100%; height: 100%; position: absolute; top: 0; left: 0; background-color: rgba(255, 255, 255, .8); border-radius: 1.2rem;}
.main-hot-photo-swiper01 .swiper-slide .txt {display: none; position: absolute; left: 0; bottom: 0; padding: 2rem;}
.main-hot-photo-swiper01 .swiper-slide .txt h6 {height: 5.6rem; font-size: 2rem; line-height: 1.4; font-weight: 600; color: #fff; margin-top: .4rem; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;}
.main-hot-photo-swiper01 .swiper-slide .txt span:not(.tag) {display: block; margin-top: 1.6rem; font-size: 1.4rem; color: var(--yr100);}

.main-hot-photo-swiper01 .swiper-slide.swiper-slide-active {width: 39rem; height: 52rem;}
.main-hot-photo-swiper01 .swiper-slide.swiper-slide-active .img::before {content: ''; background-color: transparent; background-image: url(/imgs/photo-con-pattern.svg), linear-gradient(180deg, rgba(255, 96, 28, 0) 30%, #FF601C 92.62%);}
.main-hot-photo-swiper01 .swiper-slide.swiper-slide-active .txt {display: block;}

@media (prefers-color-scheme: dark) {
    .main-hot-photo-swiper01 .swiper-slide .img::before {background-color: rgba(0, 0, 0, .8);}
}
[data-theme="dark"] .main-hot-photo-swiper01 .swiper-slide .img::before {background-color: rgba(0, 0, 0, .8);}
@media screen and (max-width: 1024px) {
    .main-hot-photo-swiper01 {min-width: 100%;}
}
@media screen and (max-width: 820px) {
    .main-hot-photo-swiper01 {width: 100vw; min-width: initial;}
    .main-hot-photo-swiper01 .swiper-slide {width: 24rem; height: 13.5rem;}
    .main-hot-photo-swiper01 .swiper-slide .img,
    .main-hot-photo-swiper01 .swiper-slide .img::before {border-radius: .8rem;}
    .main-hot-photo-swiper01 .swiper-slide .txt {padding: 1.6rem;}
    .main-hot-photo-swiper01 .swiper-slide .txt h6 {height: 4rem; font-size: 1.4rem; font-weight: 500;}
    .main-hot-photo-swiper01 .swiper-slide .txt span:not(.tag) {margin-top: .8rem; font-size: 1.2rem;}    

    .main-hot-photo-swiper01 .swiper-slide.swiper-slide-active {width: 20rem; height: 26rem;}
}

/* main banner common */
.main-banner {width: 100%; height: 16rem; padding: 4.8rem; border-radius: 1.2rem; display: flex; flex-flow: column; align-items: flex-start; justify-content: center; background-repeat: no-repeat; background-position: center right; background-size: cover; cursor: pointer;}
.main-banner h6 {font-size: 2.4rem; font-weight: 600; color: #fff;}
.main-banner p {font-size: 1.6rem; color: var(--gray500); margin-top: 1.2rem;}

@media screen and (max-width: 820px) {
    .main-banner {width: 100%; height: 11.8rem; padding: 2.4rem 2rem; border-radius: .8rem;}
    .main-banner h6 {font-size: 1.8rem;}
    .main-banner p {font-size: 1.4rem; line-height: 1.4;}
}

/* join foreigner --------------------------- */
.contents.foreigner {position: sticky; z-index: 99;}

/* login --------------------------- */
.contents.auth {position: relative;}
.contents.auth::before {content: ''; width: 100vw; min-width: 128rem; height: 100%; display: block; background-color: var(--gray100); z-index: 0!important; position: absolute; top: 0; left: 50%; -webkit-transform: translateX(-50%); -moz-transform: translateX(-50%); -ms-transform: translateX(-50%); -o-transform: translateX(-50%); transform: translateX(-50%);}
.contents.auth * {position: relative; z-index: 1;}

.login-box {width: 44rem; margin: 0 auto; padding-top: 8rem; display: flex; flex-flow: column; align-items: center; justify-content: center;}
.login-box .tit {display: flex; flex-flow: column; align-items: center; justify-content: center;}
.login-box .tit h4 {font-size: 2.4rem; font-weight: 600; margin-top: 2.6rem;}
.login-box .tit p {font-size: 1.6rem; color: var(--gray500); margin-top: 1.2rem;}

.login-box .box {width: 100%; margin-top: 6rem; padding: 2.4rem; background-color: var(--white02); border-radius: 1.2rem; box-shadow: var(--shadow-gr);}
.login-box .box > li {position: relative;}
.login-box .box > li + li {margin-top: 1.2rem;}
.login-box .box > li button {width: 100%; margin-top: -.4rem;}
.login-box .box > li label {margin-bottom: 3.2rem;}
.login-box .box > li ul {display: flex; align-items: center; justify-content: center; margin-top: 2rem;}
.login-box .box > li ul > li {position: relative; display: flex; align-items: center; font-size: 1.4rem; font-weight: 500; color: var(--gray500); cursor: pointer;}
.login-box .box > li ul > li + li::before {content: ''; display: block; width: 1px; height: 1.2rem; background-color: var(--gray300); margin: 0 .6rem;}

.login-box .box > li input[type='email'], .login-box .box > li input[type='password'] {padding: 0 5.2rem; background-repeat: no-repeat; background-size: 2rem; background-position: left 1.6rem center;}
.login-box .box > li input[type='email'] {background-image: url(/imgs/ico/ic-input-email.svg);}
.login-box .box > li input[type='password'] {background-image: url(/imgs/ico/ic-input-password.svg);}
.login-box .box > li input[type='email']:focus, .login-box .box > li input[type='password']:focus {border-color: var(--yr500);}

.login-box .box > li input + button {display: none; width: 2rem; height: 2rem; margin: 0; border-radius: 100%; background-image: url(/imgs/ico/ic-won-delete-20.svg); background-repeat: no-repeat; background-size: contain; background-position: center; font-size: .1rem; color: transparent; border: 0; position: absolute; top: 50%; right: 1.6rem; -webkit-transform: translateY(-50%); -moz-transform: translateY(-50%); -ms-transform: translateY(-50%); -o-transform: translateY(-50%); transform: translateY(-50%);}
.login-box .box > li input:focus + button {display: block}

@media (prefers-color-scheme: dark) {
    .contents.auth::before {background-color: var(--gray200);}
}
[data-theme="dark"] .contents.auth::before {background-color: var(--gray200);}
@media screen and (max-width: 1024px) {
    .contents.auth {min-width: auto;}
    .contents.auth::before {min-width: 100%;}

    .login-box {margin: 0 auto; padding-top: 6rem;}
    .login-box .tit h4 {font-size: 1.8rem;}
    .login-box .tit p {font-size: 1.4rem; text-align: center; line-height: 1.4;}

    .login-box .box {margin-top: 4rem; padding: 2rem; border-radius: .8rem;}
}
@media screen and (max-width: 820px) {
    .contents.auth::before {min-width: 100%;}

    .login-box {width: 100%; padding-top: 6rem;}
    .login-box .box {margin-top: 4rem; padding: 2rem; border-radius: .8rem;}
    .login-box .box > li + li {margin-top: .8rem;}
    .login-box .box > li button {margin-top: 0;}
    
    .login-box .box > li input[type='email'], .login-box .box > li input[type='password'] {padding: 0 4.4rem; background-position: left 1.2rem center;}
    .login-box .box > li input[type='checkbox'] + label {margin-top: 1.2rem;}
    .login-box .box > li input + button {right: 1.2rem;}
}

/* 로그인 > 아이디 찾기 팝업 */
.auth-input {width: 100%; display: flex; flex-flow: column;}
.auth-input > li {width: 100%; display: flex; align-items: center; justify-content: space-between;}
.auth-input > li.input-btn input {width: calc(100% - 13.2rem);}
.auth-input > li.input-btn button {width: 12rem; min-width: auto;}
.auth-input > li + li {margin-top: 1.2rem;}

@media screen and (max-width: 820px) {
    .auth-input > li.input-btn input {width: calc(100% - 10.4rem);}
    .auth-input > li.input-btn button {width: 9.4rem; min-width: auto;}
    .auth-input > li + li {margin-top: .8rem;}
}

/* 휴면 회원 안내 팝업 */
.member-pop {display: flex; flex-flow: column; align-items: center; justify-content: center;}
.member-pop > h6 {font-size: 2rem; font-weight: 600; margin-top: 3.2rem; text-align: center; word-break: keep-all; line-height: 1.4;}
.member-pop > p {font-size: 1.6rem; margin-top: 1.6rem;}
.member-pop .auth {width: 100%; margin-top: 3.2rem; display: flex; flex-flow: column; row-gap: .8rem;}
.member-pop .auth > li label {min-height: 5.2rem; border: 1px solid transparent; position: relative; padding: 1.4rem 1.6rem; background-color: var(--gray100); border-radius: .4rem;}
.member-pop .auth > li label::before {position: absolute; right: 1.6rem; top: 50%; -webkit-transform: translateY(-50%); -moz-transform: translateY(-50%); -ms-transform: translateY(-50%); -o-transform: translateY(-50%); transform: translateY(-50%);}
.member-pop .auth > li label > span {margin-left: 0; line-height: 1.4; font-size: 1.6rem; font-weight: 500; color: var(--black)}
.member-pop .auth > li label > span > span {font-size: 1.4rem;}
.member-pop .auth > li input:checked + label {border-color: var(--yr500); background-color: var(--yr50);}
.member-pop .auth > li input:checked + label > span {color: var(--yr500); font-weight: 600;}
.member-pop .auth-wrap {margin-top: 3.2rem; width: 100%;}
.member-pop .auth-wrap > h4 {font-size: 1.6rem; font-weight: 600;}
.member-pop .auth-wrap > ul {margin-top: 1.6rem; display: flex; flex-flow: column; row-gap: .8rem;}
.member-pop .auth-wrap > ul > li {font-size: 1.4rem; font-weight: 500; display: flex; column-gap: .8rem;}
.member-pop .auth-wrap > ul > li input:nth-child(1):nth-last-child(1) {margin-bottom: 1.2rem;}
.member-pop .auth-wrap > ul > li button {min-width: 9.4rem;}

@media screen and (max-width: 820px) {
    .member-pop .auth > li label > span > span {display: block;}
}

/* 회원가입 */
.join-box {width: 44rem; margin: 0 auto; padding-top: 8rem; display: flex; flex-flow: column; align-items: center; justify-content: center;}
.join-box .tit {display: flex; flex-flow: column; align-items: center; justify-content: center;}
.join-box .tit h4 {font-size: 2.4rem; font-weight: 600; margin-top: 2.6rem;}
.join-box .tit p {font-size: 1.6rem; color: var(--gray500); margin-top: 1.2rem;} 

.join-box .step {width: 100%; margin-top: 6rem; display: flex; align-items: center; justify-content: space-between;}
.join-box .step > li {position: relative; display: flex; flex-flow: column; align-items: center; justify-content: center; padding-bottom: 2.8rem;}
.join-box .step > li i {width: 7.2rem; height: 7.2rem; border-radius: 100%;}
.join-box .step > li h6 {width: fit-content; height: 1.6rem; font-size: 1.6rem; font-weight: 500; color: var(--gray400); white-space: nowrap; position: absolute; bottom: 0; left: 50%; -webkit-transform: translateX(-50%); -moz-transform: translateX(-50%); -ms-transform: translateX(-50%); -o-transform: translateX(-50%); transform: translateX(-50%);}
.join-box .step > li.line {width: 7.2rem;}
.join-box .step > li.line::before {content: ''; width: 100%; height: 1px; padding: 0; background-color: var(--gray300);}
.join-box .step > li.step01 i {background-image: url(/imgs/ico/img/img-join-step01.svg);}
.join-box .step > li.step02 i {background-image: url(/imgs/ico/img/img-join-step02.svg);}
.join-box .step > li.step03 i {background-image: url(/imgs/ico/img/img-join-step03.svg);}

.join-box .step > li.active h6 {color: var(--yr500); font-weight: 600;}
.join-box .step > li.step01.active i {background-image: url(/imgs/ico/img/img-join-step01-ov.svg);}
.join-box .step > li.step02.active i {background-image: url(/imgs/ico/img/img-join-step02-ov.svg);}
.join-box .step > li.step03.active i {background-image: url(/imgs/ico/img/img-join-step03-ov.svg);}

@media (prefers-color-scheme: dark) {
    .join-box .step > li.step01 i {filter: invert(.9);}
    .join-box .step > li.step02 i {filter: invert(.9);}
    .join-box .step > li.step03 i {filter: invert(.9);}

    .join-box .step > li.step01.active i {filter: none;}
    .join-box .step > li.step02.active i {filter: none;}
    .join-box .step > li.step03.active i {filter: none;}
}
[data-theme="dark"] .join-box .step > li.step01 i {filter: invert(.9);}
[data-theme="dark"] .join-box .step > li.step02 i {filter: invert(.9);}
[data-theme="dark"] .join-box .step > li.step03 i {filter: invert(.9);}

[data-theme="dark"] .join-box .step > li.step01.active i {filter: none;}
[data-theme="dark"] .join-box .step > li.step02.active i {filter: none;}
[data-theme="dark"] .join-box .step > li.step03.active i {filter: none;}

@media screen and (max-width: 1024px) {
    .join-box {padding-top: 6rem;}
    .join-box .tit h4 {font-size: 1.8rem; margin-top: 1.2rem;}
    .join-box .tit p {font-size: 1.4rem; text-align: center; line-height: 1.4;}

    .join-box .step > li {padding-bottom: 2.6rem;}
    .join-box .step > li i {width: 6rem; height: 6rem;}
    .join-box .step > li h6 {height: 1.4rem; font-size: 1.4rem;}
}
@media screen and (max-width: 820px) {
    .join-box {width: 100%; padding-top: 4rem;}
    .join-box .step {padding: 0 1rem;}

    .join-box .step > li.line {width: 4.6rem;}
}

/* 외국인 회원가입 */
.foreigner-join-birthdate > div {display: flex; align-items: center;}
.foreigner-join-birthdate > div > select {width: calc(100% + 2.4rem); margin-right: 1.2rem;}

.join-box .tit-01 {display: flex; flex-flow: column; align-items: center; justify-content: center;}
.join-box .tit-01 h4 {font-size: 2.2rem; font-weight: 600; margin-top: 2.6rem;}
.join-box .tit-01 p {font-size: 1.4rem; color: var(--gray500); margin-top: 1.2rem;}

@media screen and (max-width: 1024px) {
    .join-box {padding-top: 6rem;}
    .join-box .tit-01 h4 {font-size: 1.6rem; margin-top: 1.2rem;}
    .join-box .tit-01 p {font-size: 1.2rem; text-align: center; line-height: 1.4;}
}
/* 회원가입 > 약관동의 */
.join-box .box {width: 100%; margin-top: 6rem; padding: 2.4rem; background-color: var(--white01); border-radius: 1.2rem; box-shadow: var(--shadow-gr);}
.join-box .box .join-agree-chk {display: flex; flex-flow: column; align-items: flex-start;}
.join-box .box .join-agree-chk > li.all {position: relative; padding-bottom: 2.4rem;}
.join-box .box .join-agree-chk > li.all::before {content: ''; display: block; width: calc(100% + 4.8rem); height: 1px; background-color: var(--gray200); position: absolute; bottom: 0; left: -2.4rem;}
.join-box .box .join-agree-chk > li.all + li {margin-top: 2.4rem}
.join-box .box .join-agree-chk > li {width: 100%; display: flex; align-items: center; justify-content: space-between;}
.join-box .box .join-agree-chk > li + li {margin-top: 2rem;}
.join-box .box .join-agree-chk > li label {align-items: flex-start; width: calc(100% - 3rem);}
.join-box .box .join-agree-chk > li label > span {font-size: 1.6rem; line-height: 1.2; color: var(--black); word-break: keep-all;}
.join-box .box .join-agree-chk > li label > span > span {font-size: 1.4rem; font-weight: 500; color: var(--yr500); margin-left: .4rem;}
.join-box .box .join-agree-chk > li > span {width: 3rem; text-align: right; font-size: 1.4rem; color: var(--gray500); text-decoration: underline; cursor: pointer;}
/*.join-box .box .join-agree-chk > li:nth-child(2) label > span {font-size: 1.8rem; font-weight: 700;}
.join-box .box .join-agree-chk > li:nth-child(2) label > span > span {font-size: 1.6rem; font-weight: 700;}*/

.join-box .box .join-agree-desc {position: relative; margin-top: 2.4rem; padding-top: 2.4rem;}
.join-box .box .join-agree-desc::before {content: ''; display: block; width: calc(100% + 4.8rem); height: 1px; background-color: var(--gray200); position: absolute; top: 0; left: -2.4rem;}
.join-box .box .join-agree-desc > li {font-size: 1.4rem; color: var(--gray500); line-height: 1.4; padding-left: 1.2rem; display: flex; align-items: center;}
.join-box .box .join-agree-desc > li + li {margin-top: 2rem;}
.join-box .box .join-agree-desc > li::before {content: '-'; position: absolute; left: 0; top: 0;}

.join-box .box .join-btn {margin-top: 3.2rem; display: flex; flex-flow: column; align-items: center; justify-content: center;}
.join-box .box .join-btn button {width: 100%;}
.join-box .box .join-btn button + button {margin-top: 1.2rem;}

@media screen and (max-width: 1024px) {
    .join-box .box {margin-top: 6rem; padding: 2rem; border-radius: .8rem;}
    .join-box .box .join-agree-chk > li.all {padding-bottom: 2rem;}
    .join-box .box .join-agree-chk > li.all::before {width: calc(100% + 4rem); left: -2rem;}
    .join-box .box .join-agree-chk > li.all + li {margin-top: 2rem}
    .join-box .box .join-agree-chk > li {width: 100%; display: flex; align-items: center; justify-content: space-between;}
    .join-box .box .join-agree-chk > li + li {margin-top: 1.6rem;}
    .join-box .box .join-agree-chk > li label > span {font-size: 1.4rem; line-height: 1.4;}
    .join-box .box .join-agree-chk > li label > span > span {font-size: 1.2rem;}
    .join-box .box .join-agree-chk > li > span {font-size: 1.2rem;}
    
    .join-box .box .join-agree-desc {margin-top: 2rem; padding-top: 2rem;}
    .join-box .box .join-agree-desc::before {width: calc(100% + 4rem); left: -2rem;}
    .join-box .box .join-agree-desc > li {font-size: 1.2rem;}
    .join-box .box .join-agree-desc > li + li {margin-top: 1.6rem;}
    
    .join-box .box .join-btn {margin-top: 2rem;}
    .join-box .box .join-btn button + button {margin-top: .8rem;}
}
@media screen and (max-width: 820px) {
    .join-box .box {margin-top: 4rem;}
}

/* 회원가입 > 회원정보 입력 */
.join-box .join-input {width: 100%; margin-top: 6rem; padding: 2.4rem; background-color: var(--white02); border-radius: 1.2rem; box-shadow: var(--shadow-gr); display: flex; flex-flow: column; row-gap: 2.4rem; align-items: flex-start; justify-content: center;}
.join-box .join-input > li {width: 100%; display: flex; flex-flow: column; row-gap: 1.2rem;}
.join-box .join-input > li h6 {font-size: 1.4rem; font-weight: 500;}
.join-box .join-input > li > button {width: 100%; margin-top: .8rem;}
.join-box .join-input > li > input + button {margin-top: 0;}
.join-box .join-input > li.half {display: flex; flex-flow: initial; align-items: center; column-gap: .8rem;}
.join-box .join-input > li.half > div {display: flex; flex-flow: column; row-gap: 1.2rem;}
.join-box .join-input > li .desc > li {font-size: 1.4rem; color: var(--gray500); line-height: 1.4; display: flex; align-items: flex-start; column-gap: .4rem; word-break: keep-all;}
.join-box .join-input > li .desc > li::before {content: '•';}

.academic-wrap {display: flex; align-items: center; grid-gap: 1.2rem .8rem; flex-wrap: wrap;}
.academic-wrap > li {width: calc((100% - .8rem) / 2);}
.academic-wrap > li:last-child {width: 100%;}

@media screen and (max-width: 1024px) {
    .join-box .join-input {margin-top: 6rem; padding: 2rem; border-radius: .8rem; row-gap: 2rem;}
    .join-box .join-input > li {row-gap: 1rem;}
    .join-box .join-input > li > h6 {font-size: 1.4rem; font-weight: 500;}
    .join-box .join-input > li > button {width: 100%; margin-top: .8rem;}
}
@media screen and (max-width: 820px) {
    .join-box .join-input {margin-top: 4rem;}
}

/* 회원가입 > 약관동의 */
.agree-wrap label > span {line-height: 1.4;}
.agree-desc {margin-top: 2rem; font-size: 1.4rem; line-height: 1.6;}
.agree-desc span {font-weight: 600; position: relative; display: inline-flex; align-items: center; padding-left: 1.2rem;}
.agree-desc span::before {content: '-'; position: absolute; left: 0;}

/* 마이 페이지 --------------------------- */
.mypage-summary {width: 100%; border-radius: .8rem; border: 1px solid var(--gray100); display: flex; padding: 4rem 6rem; background-image: url(/imgs/mypage-con-pattern.svg); background-repeat: no-repeat; background-size: auto 100%; background-position: right center; box-shadow: var(--shadow-gr);}
.mypage-summary > li {display: flex; align-items: center; justify-content: center; flex-flow: column;}
.mypage-summary > li.name {width: calc(100% - 48rem); align-items: flex-start;}
.mypage-summary > li.name h4 {font-size: 2.4rem; line-height: 1.4;}
.mypage-summary > li:not(.name) {width: 24rem; height: 12rem; border-left: 1px solid var(--gray200);}
.mypage-summary > li h6 {font-size: 1.6rem; color: var(--gray500);}
.mypage-summary > li p {font-size: 2.4rem; font-weight: 600; margin-top: 2rem;}

.mypage-num {margin-top: 3.2rem; display: grid; grid-template-columns: repeat(2, 1fr); grid-gap: 2rem;}
.mypage-num > li {background-color: var(--gray100); display: flex; align-items: center; justify-content: space-between; padding: 2.4rem 3.2rem; border-radius: .4rem;}
.mypage-num > li h6 {font-size: 1.8rem; font-weight: 600; display: flex; align-items: center;}
.mypage-num > li h6 i {margin-right: .8rem;}
.mypage-num > li p {font-size: 1.8rem;}

.mypage-wrap {position: relative; margin-top: 6rem; padding: 6rem 0 8rem;}
.mypage-wrap::before {content: ''; min-width: 128rem; width: 100vw; height: 100%; background-color: var(--gray100); position: absolute; top: 0; left: 50%; -webkit-transform: translateX(-50%); -moz-transform: translateX(-50%); -ms-transform: translateX(-50%); -o-transform: translateX(-50%); transform: translateX(-50%); z-index: 0!important;}
.mypage-wrap > * {z-index: 1; position: relative;}

.modify-wrap {width: 100%; border-radius: 1.2rem; padding: 4rem; background-color: var(--white02);}
.modify-wrap .modify-input {width: 40rem; display: flex; flex-flow: column; justify-content: center; align-items: center; margin: 0 auto;}
.modify-wrap .modify-input > li {width: 100%;}
.modify-wrap .modify-input > li + li {margin-top: 2.4rem;}
.modify-wrap .modify-input > li > * + * {margin-top: 1.2rem;}
.modify-wrap .modify-input > li > h6 {font-size: 1.4rem; font-weight: 500;}
.modify-wrap .modify-input > li > button {width: 100%; margin-top: .8rem;}
.modify-wrap .modify-input > li .input-btn > li {display: flex; align-items: center; justify-content: space-between;}
.modify-wrap .modify-input > li .input-btn > li:nth-child(1) input {width: calc(100% - 13.2rem);} 
.modify-wrap .modify-input > li .input-btn > li button {width: 12rem; min-width: auto; margin-left: 1.2rem;}
.modify-wrap .modify-input > li .input-btn > li + li {margin-top: 1.2rem;}
.modify-wrap .modify-input .marketing-agree h6 {font-size: 1.4rem; font-weight: 500;}
.modify-wrap .modify-input .marketing-agree .agree-chk {margin-top: 2rem; display: grid; grid-template-columns: repeat(2, 1fr); grid-gap: 2rem;}

.withdrawal-info {margin-top: 4rem; display: flex; align-items: center; justify-content: center; flex-flow: column;}
.withdrawal-info h6 {font-size: 1.6rem; font-weight: 600; text-decoration: underline; cursor: pointer; color: var(--gray500);}
.withdrawal-info p {font-size: 1.4rem; color: var(--gray500); margin-top: 1.4rem;}

.withdrawal-con + .withdrawal-con {margin-top: 3.2rem;}
.withdrawal-con h6 {font-size: 1.8rem; font-weight: 600; margin-bottom: 1.8rem;}
.withdrawal-con ul {display: flex; align-items: center;}
.withdrawal-con ul > li + li {margin-left: 4rem;}

.coupon-list {display: grid; grid-template-columns: repeat(2, 1fr); grid-gap: 2rem;}
.coupon-list > li {height: 17.4rem; border: 1px solid var(--gray200); border-radius: 0 .8rem .8rem 0; background-color: var(--white01); padding: 3.2rem 4rem; position: relative; background-image: url(/imgs/bg-pattern-coupon.svg); background-repeat: no-repeat; background-size: auto 100%; background-position: right bottom;}
.coupon-list > li::before {content: ''; display: block; width: .4rem; height: 100%; background-color: var(--yr500); position: absolute; top: 0; left: 0;}
.coupon-list > li::after {content: ''; display: block; width: 2.4rem; height: 2.4rem; background-color: var(--gray100); border-width: 1px; border-style: solid; border-radius: 100%; border-color: var(--gray100) var(--gray100) var(--gray200) var(--gray200); transform: rotate(45deg); position: absolute; right: -1.2rem; top: 7.5rem;}
.coupon-list > li .tag-wrap {display: flex;}
.coupon-list > li .tag-wrap .tag:last-child {margin-left: .5rem;}
.coupon-list > li .tag-wrap .tag.db {border-color: var(--db-main); color: var(--db-main);}
.coupon-list > li .tag-wrap .tag.ss {border-color: var(--ss-main); color: var(--ss-main);}
.coupon-list > li .tag-wrap .tag.sk {border-color: var(--sk-main); color: var(--sk-main);}
.coupon-list > li .tag-wrap .tag.lg {border-color: var(--lg-main); color: var(--lg-main);}
.coupon-list > li .tag-wrap .tag.el {border-color: var(--el-main); color: var(--el-main);}
.coupon-list > li .tag-wrap .tag.kcc {border-color: var(--kcc-main); color: var(--kcc-main);}
.coupon-list > li .tag-wrap .tag.car {border-color: var(--or-main); color: var(--or-main);}
.coupon-list > li .tag-wrap .tag.sono {border-color: var(--or-main); color: var(--or-main);}
.coupon-list > li .tag-wrap .tag.kgc {border-color: var(--kgc-main); color: var(--kgc-main);}
.coupon-list > li .tag-wrap .tag.kt {border-color: var(--kt-main); color: var(--kt-main);}
.coupon-list > li .tag-wrap .tag.hd {border-color: var(--hd-main); color: var(--hd-main);}
.coupon-list > li .tag-wrap .tag.kbl {border-color: var(--kcc-main); color: var(--kcc-main);}
.coupon-list > li h6 {margin-top: .8rem; font-size: 1.8rem; font-weight: 500; line-height: 1.4; width: 34.5rem; height: 5rem; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;}
.coupon-list > li .date {display: block; font-size: 1.4rem; color: var(--gray500); /*margin-top: 1.6rem;*/}
.coupon-list > li p {position: absolute; right: 4rem; bottom: 3.2rem; font-size: 1.4rem;}
.coupon-list > li p span {font-weight: 500;}
.coupon-list > li .num {position: initial; color: var(--gray500);}
.coupon-list > li.end::before {background-color: var(--gray400);}
.coupon-list > li.end .tag {border-color: var(--gray400); color: var(--gray400);}

@media screen and (max-width: 1024px) {
    .mypage-summary {padding: 0; display: grid; grid-template-columns: repeat(2, 1fr); background-image: url(/imgs/mypage-con-pattern-mb.svg);}
    .mypage-summary > li.name {width: 100%; grid-column: 1 / span 2; padding: 3.2rem 2.4rem; border-bottom: 1px solid var(--gray100);}
    .mypage-summary > li.name h4 {font-size: 2rem;}
    .mypage-summary > li:not(.name) {width: 100%; height: auto; border-left: 0; padding: 1.6rem;}
    .mypage-summary > li:not(.name) + li:not(.name) {border-left: 1px solid var(--gray100);}
    .mypage-summary > li h6 {font-size: 1.2rem;}
    .mypage-summary > li p {font-size: 1.6rem; margin-top: .8rem;}

    .mypage-num {margin-top: 1.6rem; display: grid; grid-template-columns: repeat(1, 1fr); grid-gap: .8rem;}
    .mypage-num > li {padding: 1.6rem;}
    .mypage-num > li i {width: 2rem; height: 2rem;}
    .mypage-num > li h6 {font-size: 1.4rem;}
    .mypage-num > li p {font-size: 1.4rem;}

    .mypage-wrap {margin-top: 4rem; padding: 4rem 0 6rem;}
    .mypage-wrap::before {min-width: auto;}

    .modify-wrap {border-radius: .8rem;}
    .modify-wrap .modify-input > li + li {margin-top: 2rem;}
    .modify-wrap .modify-input > li > * + * {margin-top: 1rem;}
    .modify-wrap .modify-input > li .input-btn > li:nth-child(1) input {width: calc(100% - 10.6rem);} 
    .modify-wrap .modify-input > li .input-btn > li button {width: 9.4rem;}
    .modify-wrap .modify-input .marketing-agree h6 {font-size: 1.4rem; font-weight: 500;}
    .modify-wrap .modify-input .marketing-agree .agree-chk {grid-template-columns: repeat(1, 1fr); grid-gap: 1.2rem;}

    .withdrawal-info h6 {font-size: 1.4rem;}
    .withdrawal-info p {font-size: 1.2rem;}

    .withdrawal-con h6 {font-size: 1.6rem; margin-bottom: 1.6rem;}
}

@media screen and (max-width: 820px) {
    .mypage-wrap {margin-top: 3.2rem; padding: 3.2rem 0 4rem;}

    .modify-wrap {padding: 2rem;}
    .modify-wrap .modify-input {width: 100%;}
    .modify-wrap .modify-input > li .input-btn > li:nth-child(1) input {width: calc(100% - 10rem);} 
    .modify-wrap .modify-input > li .input-btn > li button {margin-left: .8rem;}
    .modify-wrap .modify-input .marketing-agree .agree-chk {grid-gap: .8rem; margin-top: 1rem;}

    .withdrawal-info {margin-top: 2rem;}
    .withdrawal-info p {font-size: 1.2rem;}

    .withdrawal-con ul {align-items: flex-start; flex-flow: column;}
    .withdrawal-con ul > li + li {margin-left: 0; margin-top: .8rem;}

    .coupon-list {grid-template-columns: repeat(1, 1fr); grid-gap: 1.2rem;}
    .coupon-list > li {height: 14rem; padding: 2.4rem;}
    .coupon-list > li::before {width: .2rem;}
    .coupon-list > li::after {width: 1.6rem; height: 1.6rem; right: -.8rem; top: 6.2rem;}
    .coupon-list > li h6 {font-size: 1.6rem; width: 100%; height: 4.2rem; word-break: keep-all;}
    .coupon-list > li .date {font-size: 1.2rem;}
    .coupon-list > li p {right: 2.4rem; bottom: 2.4rem; font-size: 1.2rem;}
    
}

@media (prefers-color-scheme: dark) {
    .mypage-summary {background-image: url(/imgs/mypage-con-pattern-dark.svg);}
    @media screen and (max-width: 1024px) {
        .mypage-summary {background-image: url(/imgs/mypage-con-pattern-mb-dark.svg);}
    }
}
[data-theme="dark"] .mypage-summary {background-image: url(/imgs/mypage-con-pattern-dark.svg);}

.mypage-table01 table th,
.mypage-table01 table td {height: auto;}
.mypage-table01 .button {display: flex; align-items: center;}
.mypage-table01 .button > li + li {margin-left: .8rem;}

.grade-wrap {display: flex; align-items: center; justify-content: center;}
.grade-wrap > li + li {margin-left: .2rem;}

.inquiry {display: flex; align-items: flex-start; padding: 2.8rem 2.4rem;}
.inquiry .ic {width: 3.2rem; height: 3.2rem; margin-right: 1.6rem; border-radius: .4rem; background-color: var(--gray100); display: flex; align-items: center; justify-content: center; font-size: 2rem; font-weight: 600; color: var(--gray500);}
.inquiry .desc {width: calc(100% - 3.2rem - 1.6rem); display: flex; flex-flow: column; text-align: left;}
.inquiry .desc p {font-size: 1.4rem; line-height: 1.6;}
.inquiry .desc span {display: block; font-size: 1.4rem; color: var(--gray500); margin-top: 1.2rem;}

.inquiry.a {background-color: var(--gray100); border-radius: .8rem; margin: 1.2rem 2.4rem 0; padding: 2.4rem;}
.inquiry.a .ic {color: var(--yr500); background-color: var(--white02);}

.assess {padding: 4rem 2.4rem 2.8rem; display: flex; flex-flow: column; align-items: flex-start;}
.assess > h6 {font-size: 1.6rem; font-weight: 600; margin-bottom: 1.6rem;}
.assess > div {display: flex; align-items: center; justify-content: space-between; width: 100%; padding: 2.4rem; border: 1px solid var(--gray200); border-radius: .8rem;}
.assess > div .grade-wrap > li {cursor: pointer;}
.assess > button {margin: 2.4rem auto 0;}

@media screen and (max-width: 1024px) {
    .mypage-table01 table th,
    .mypage-table01 table td {white-space: nowrap;}
}
@media screen and (max-width: 820px) {
    .inquiry-list {display: flex; flex-flow: column;}
    .inquiry-list > li {background-color: var(--white01); border: 1px solid var(--gray200); border-radius: .8rem; padding: 2rem;}
    .inquiry-list > li + li {margin-top: 1.2rem;}
    .inquiry-list > li .ic {width: 2.8rem; height: 2.8rem; margin-right: .8rem; border-radius: .4rem; background-color: var(--gray100); display: flex; align-items: center; justify-content: center; font-size: 1.6rem; font-weight: 600; color: var(--gray500);}
    .inquiry-list > li .desc {width: calc(100% - 2.8rem - .8rem); display: flex; flex-flow: column; text-align: left;}
    .inquiry-list > li .desc p {font-size: 1.6rem; line-height: 1.6; font-weight: 500;}
    .inquiry-list > li .desc > div {margin-top: 1.6rem; display: flex; align-items: center; justify-content: space-between;}
    .inquiry-list > li .desc > div .grade-cont {display: flex; align-items: center;}
    .inquiry-list > li .desc > div .grade-cont > i {width: 1.6rem; height: 1.6rem; margin-right: .2rem;}
    .inquiry-list > li .desc > div .grade-cont > p {font-size: 1.4rem; color: var(--ss-point); font-weight: 500;}

    .inquiry-list > li .q {display: flex; align-items: flex-start;}
    .inquiry-list > li .a {margin: 2rem -2rem 0; padding: 2rem 2rem 0; border-top: 1px solid var(--gray200); display: flex; flex-flow: column;}
    .inquiry-list > li .a > p {font-size: 1.4rem; line-height: 1.6;}
    .inquiry-list > li .a > span {display: block; margin-top: 1.2rem; font-size: 1.2rem; color: var(--gray500);}
    .inquiry-list > li .a .a-cont {background-color: var(--gray100); border-radius: .4rem; margin-top: 2rem; padding: 1.6rem; display: flex; align-items: flex-start;}
    .inquiry-list > li .a .a-cont p {font-size: 1.4rem; font-weight: initial;}
    .inquiry-list > li .a .a-cont span {font-size: 1.2rem; margin-top: 1.2rem; color: var(--gray500);}
    .inquiry-list > li .a .ic {color: var(--yr500); background-color: var(--white02);}

    .inquiry-list > li .assess {margin-top: 2rem; padding: 0;}
    .inquiry-list > li .assess > h6 {font-size: 1.6rem; font-weight: 600; margin-bottom: 1.2rem;}
    .inquiry-list > li .assess > div {flex-flow: column; justify-content: center; padding: 2rem; border: 1px solid var(--gray200); border-radius: .4rem;}
    .inquiry-list > li .assess > div p {font-size: 1.4rem; line-height: 1.4;}
    .inquiry-list > li .assess > div .grade-wrap {margin-top: .8rem;}
    .inquiry-list > li .assess > button {margin: 1.2rem auto 0;}
}
@media (prefers-color-scheme: dark) {
    .inquiry .ic {background-color: var(--gray200);}
    .inquiry.a {background-color: var(--gray200);}
    .inquiry-list > li .ic {background-color: var(--gray200);}
    .inquiry-list > li .a .a-cont {background-color: var(--gray200);}
}
[data-theme="dark"] .inquiry .ic {background-color: var(--gray200);}
[data-theme="dark"] .inquiry.a {background-color: var(--gray200);}
[data-theme="dark"] .inquiry-list > li .ic {background-color: var(--gray200);}
[data-theme="dark"] .inquiry-list > li .a .a-cont {background-color: var(--gray200);}

/* table common style */
.bd-gray {border-right: 1px solid #E3E5E9; }

/* sticly table style */
.compare-offence-defence-table01 table th,
.compare-offence-defence-table01 table td {white-space: nowrap;}
.compare-team-table01 table th,
.compare-team-table01 table td {white-space: nowrap;}
.compare-team-table02 .point {background-color: var(--gray100);}
.compare-team-table02 th.point {border-color: var(--gray200);}

.archive-player-table01-wrap {display: grid; grid-template-columns: 1fr 3fr;}
.archive-player-table01 table th,
.archive-player-table01 table td {white-space: nowrap;}
.archive-player-table01 th i {display: inline-flex; margin-left: .4rem; cursor: pointer;}
.archive-player-table01 .on {background-color: var(--yr50); color: var(--yr500);}
.archive-player-table01 table tr td {height: 6rem;}
.archive-player-table01 table .border-r {border-right: 1px solid var(--gray200);}
.archive-player-table01 table .border-l {border-left: 1px solid var(--gray200);}

.archive-team-table01-wrap {display: grid; grid-template-columns: 1fr 3fr;}
.archive-team-table01 table th,
.archive-team-table01 table td {white-space: nowrap;}
.archive-team-table01 th i {display: inline-flex; margin-left: .4rem; cursor: pointer;}
.archive-team-table01 .on {background-color: var(--yr50); color: var(--yr500);}
.archive-team-table01 table tr td {height: 6rem;}
.archive-team-table01 table .border-l {border-left: 1px solid var(--gray200);}

.team-rank-table01 table th,
.team-rank-table01 table td {white-space: nowrap;}

.team-rank-grapth {background-color: var(--white01); border-radius: .8rem; }
.team-rank-grapth #chart {height: 500px!important;}

@media screen and (max-width: 820px) {
    .archive-player-table01-wrap,
    .archive-team-table01-wrap {width: 100vw; margin: 0 -1.6rem;}
    .team-rank-grapth #chart  {height: 26rem!important;}
}

/* LIVE/기록 > 일정 및 결과 > 리스트형 */
.game-schedule-list-wrap > li {display: flex; justify-content: space-between; border: 1px solid var(--gray100); border-radius: .8rem; box-shadow: var(--shadow-gr); position: relative;}
.game-schedule-list-wrap > li + li {margin-top: 2rem;}
.game-schedule-list-wrap > li .tit {width: 24rem; display: flex; flex-flow: column; align-content: center; justify-content: center; padding: 0 3.2rem; margin: 3.2rem 0; border-right: 1px solid var(--gray200); position: relative;}
.game-schedule-list-wrap > li .tit h6 {text-align: center; font-size: 1.6rem; font-weight: 600; color: var(--yr500);}
.game-schedule-list-wrap > li .tit p {text-align: center; font-size: 1.6rem; margin-top: .8rem;}
.game-schedule-list-wrap > li .tit .tag {position: absolute; top: -2rem; left: 1.2rem;}
.game-schedule-list-wrap > li .tit .ic-like {position: absolute; top: -2rem; right: 1.2rem; cursor: pointer;}
.game-schedule-list-wrap > li .tit .ic-like.active {background-image: url(/imgs/ico/ic-like-ov.svg);}
.game-schedule-list-wrap > li .score-wrap {position: relative; width: calc(100% - 24rem - 23.7rem); padding: 3.2rem; display: flex; align-items: center; justify-content: center; flex-flow: column;}
.game-schedule-list-wrap > li .score-wrap * {z-index: 1;}
.game-schedule-list-wrap > li .score-wrap::before {content: ''; width: 100%; height: 100%; position: absolute; top: 0; left: 0; background-image: url(/imgs/ticket-reserve-con-pattern.svg); background-repeat: no-repeat; background-position: right center; background-size: contain; z-index: 0!important;}
.game-schedule-list-wrap > li .score-wrap > li {width: 100%; display: flex; align-items: center; justify-content: space-between;}
.game-schedule-list-wrap > li .score-wrap > li + li {margin-top: .8rem;}
.game-schedule-list-wrap > li .score-wrap > li .team {display: flex; align-items: center;}
.game-schedule-list-wrap > li .score-wrap > li .team i {width: 6rem; height: 4rem; margin-right: .4rem;}
.game-schedule-list-wrap > li .score-wrap > li .team p {font-size: 1.8rem; font-weight: 600;}
.game-schedule-list-wrap > li .score-wrap > li .score {font-size: 2.4rem; font-weight: 600;}
.game-schedule-list-wrap > li .button {display: flex; flex-flow: column; align-items: center; justify-content: center; padding: 0 3.2rem; margin: 3.2rem 0; border-left: 1px solid var(--gray200);}
.game-schedule-list-wrap > li .button button {width: 17.2rem; height: 4.8rem; font-size: 1.6rem;}
.game-schedule-list-wrap > li .button button + button {margin-top: .8rem;}
.game-schedule-list-wrap > li.before .score-wrap::after {content: 'VS'; font-size: 2.4rem; font-weight: 600; position: absolute; top: 50%; right: 3.2rem; -webkit-transform: translateY(-50%); -moz-transform: translateY(-50%); -ms-transform: translateY(-50%); -o-transform: translateY(-50%); transform: translateY(-50%);}

.dleague-list-wrap > li {display: grid; grid-template-columns: calc(100% - 23.7rem) 23.7rem; border: 1px solid var(--gray100); border-radius: .8rem; box-shadow: var(--shadow-gr);  background-image: url(/imgs/ticket-reserve-con-pattern.svg); background-repeat: no-repeat; background-position: right bottom; background-size: contain;}
.dleague-list-wrap > li + li {margin-top: 2rem;}
.dleague-list-wrap > li > div {display: flex; justify-content: space-between; position: relative;}
.dleague-list-wrap > li > div:nth-child(1):nth-last-child(2) ~ div:nth-child(2) {grid-row: 1; grid-column: 2;}
.dleague-list-wrap > li > div:nth-child(1):nth-last-child(3) ~ div:nth-child(2) {grid-row: 2; grid-column: 1;}
.dleague-list-wrap > li > div:nth-child(1):nth-last-child(3) ~ div:nth-child(3) {grid-row: 1 / span 2;}
.dleague-list-wrap > li > div:nth-child(1):nth-last-child(4) ~ div:nth-child(3) {grid-row: 3; grid-column: 1;}
.dleague-list-wrap > li > div:nth-child(1):nth-last-child(4) ~ div:nth-child(4) {grid-row: 1 / span 3;}
.dleague-list-wrap > li > div + div:not(.button)::before {content: ''; width: calc(100% - 2.4rem); height: 1px; position: absolute; top: 0; left: 1.2rem; display: block; background-color: var(--gray200);}
.dleague-list-wrap > li > div .tit {width: 24rem; display: flex; flex-flow: column; align-content: center; justify-content: center; padding: 0 3.2rem; margin: 3.2rem 0; border-right: 1px solid var(--gray200);}
.dleague-list-wrap > li > div .tit h6 {text-align: center; font-size: 1.6rem; font-weight: 600; color: var(--yr500);}
.dleague-list-wrap > li > div .tit p {text-align: center; font-size: 1.6rem; margin-top: .8rem;}
.dleague-list-wrap > li > div .tit .tag {position: absolute; top: 1.2rem; left: 1.2rem;}
.dleague-list-wrap > li > div .score-wrap {position: relative; width: calc(100% - 24rem); padding: 3.2rem; display: flex; align-items: center; justify-content: center; flex-flow: column;}
.dleague-list-wrap > li > div .score-wrap > li {width: 100%; display: flex; align-items: center; justify-content: space-between;}
.dleague-list-wrap > li > div .score-wrap > li + li {margin-top: .8rem;}
.dleague-list-wrap > li > div .score-wrap > li .team {display: flex; align-items: center;}
.dleague-list-wrap > li > div .score-wrap > li .team i {width: 6rem; height: 4rem; margin-right: .4rem;}
.dleague-list-wrap > li > div .score-wrap > li .team p {font-size: 1.8rem; font-weight: 600;}
.dleague-list-wrap > li > div .score-wrap > li .score {font-size: 2.4rem; font-weight: 600;}
.dleague-list-wrap > li .button {display: flex; flex-flow: column; align-items: center; justify-content: center; padding: 0 3.2rem; margin: 3.2rem 0; border-left: 1px solid var(--gray200);}
.dleague-list-wrap > li .button p {margin-top: 1.2rem; font-size: 1.2rem; font-weight: 500; color: var(--gray500); line-height: 1.4; word-break: keep-all; text-align: center;}
.dleague-list-wrap > li .button button {width: 17.2rem; height: 4.8rem; font-size: 1.6rem;}
.dleague-list-wrap > li .button button + button {margin-top: .8rem;}
.dleague-list-wrap > li > div.before .score-wrap::after {content: ''; font-size: 2.4rem; font-weight: 600; position: absolute; top: 50%; right: 3.2rem; -webkit-transform: translateY(-50%); -moz-transform: translateY(-50%); -ms-transform: translateY(-50%); -o-transform: translateY(-50%); transform: translateY(-50%);}

@media (prefers-color-scheme: dark) {
    .dleague-list-wrap > li {background-image: none;}
    .game-schedule-list-wrap > li .score-wrap ::before {filter: invert(.9);}
}
[data-theme="dark"] .dleague-list-wrap > li {background-image: none;}
[data-theme="dark"] .game-schedule-list-wrap > li .score-wrap ::before {filter: invert(.9);}

@media screen and (max-width: 1024px) {
    .filter-wrap + .date-controller {margin-top: -2.4rem;}
    .date-controller {margin-bottom: 4rem; padding: 2rem;}
    .date-controller > li button {font-size: 1.2rem;}
    .date-controller > li .controller-wrap {border-radius: .4rem;}
    .date-controller > li .controller-wrap > li select {padding: 0  2.4rem 0 0; font-size: 1.8rem; background-size: 2rem;}
    .date-controller > li .controller-wrap > li i {width: 2.8rem; height: 2.8rem;}
    .date-controller > li .view-type-tab > li i {width: 2rem; height: 2rem;}

    .game-schedule-list-wrap {display: grid; grid-gap: 2rem; grid-template-columns: repeat(2, 1fr); align-items: flex-start;}
    .game-schedule-list-wrap > li {flex-flow: column; background-image: url(/imgs/bg-schedule-con-pattern-mb.svg); background-repeat: no-repeat; background-size: cover; background-position: right bottom;}
    .game-schedule-list-wrap > li + li {margin-top: 0;}
    .game-schedule-list-wrap > li .tit {position: relative; width: calc(100% - 4rem); padding: 2rem 0; margin: 0 2rem; border-right: 0; border-bottom: 1px solid var(--gray200);}
    .game-schedule-list-wrap > li .tit h6 {font-size: 1.4rem;}
    .game-schedule-list-wrap > li .tit p {font-size: 1.4rem; margin-top: .4rem;}
    .game-schedule-list-wrap > li .tit .tag {position: absolute; top: 2rem; left: 0;}
    .game-schedule-list-wrap > li .tit .ic-like {position: absolute; top: 2rem; right: 0;}
    .game-schedule-list-wrap > li .score-wrap::before {content: none;}
    .game-schedule-list-wrap > li .score-wrap {position: relative; width: 100%; padding: 2rem; background-image: none;}
    .game-schedule-list-wrap > li .score-wrap > li .team p {font-size: 1.4rem;}
    .game-schedule-list-wrap > li .score-wrap > li .score {font-size: 2rem;}
    .game-schedule-list-wrap > li .button {padding: 0 2rem 2rem; margin: 0; border-left: 0; height: 11.6rem;}
    .game-schedule-list-wrap > li .button button {width: 100%; height: 4.4rem; font-size: 1.4rem;}
    .game-schedule-list-wrap > li .button button + button {margin-top: .8rem;}
    .game-schedule-list-wrap > li.before .score-wrap::after {font-size: 2rem; right: 2rem;}

    .dleague-list-wrap {display: grid; grid-gap: 2rem; grid-template-columns: repeat(2, 1fr); align-items: flex-start;}
    .dleague-list-wrap > li {display: flex; flex-flow: column; background-image: none;}
    .dleague-list-wrap > li + li {margin-top: 0;}
    .dleague-list-wrap > li > div {flex-flow: column;}
    .dleague-list-wrap > li > div:nth-child(1):nth-last-child(2) ~ div:nth-child(2) {grid-row: 2; grid-column: 1;}
    .dleague-list-wrap > li > div:nth-child(1):nth-last-child(3) ~ div:nth-child(2) {grid-row: 2; grid-column: 1;}
    .dleague-list-wrap > li > div:nth-child(1):nth-last-child(3) ~ div:nth-child(3) {grid-row: 3;}
    .dleague-list-wrap > li > div:nth-child(1):nth-last-child(4) ~ div:nth-child(4) {grid-row: 4;}
    .dleague-list-wrap > li > div + div::before {content: ''; width: calc(100% - 2.4rem); height: 1px; position: absolute; top: 0; left: 1.2rem; display: block; background-color: var(--gray200);}
    .dleague-list-wrap > li > div .tit {position: relative; width: calc(100% - 4rem); padding: 2rem 0; margin: 0 2rem; border-right: 0;}
    .dleague-list-wrap > li > div .tit h6 {font-size: 1.4rem;}
    .dleague-list-wrap > li > div .tit p {font-size: 1.4rem; margin-top: .4rem;}
    .dleague-list-wrap > li > div .tit .tag {position: absolute; top: 2rem; left: 0;}
    .dleague-list-wrap > li > div .score-wrap {position: relative; width: 100%; padding: 0 2rem 2rem; background-image: none;}
    .dleague-list-wrap > li > div .score-wrap > li .team p {font-size: 1.4rem;}
    .dleague-list-wrap > li > div .score-wrap > li .score {font-size: 2rem;}
    .dleague-list-wrap > li .button {padding: 2rem; margin: 0; border-left: 0; height: 13.6rem;}
    .dleague-list-wrap > li .button button {width: 100%; height: 4.4rem; font-size: 1.4rem;}
    .dleague-list-wrap > li .button button + button {margin-top: .8rem;}
    .dleague-list-wrap > li.before .score-wrap::after {font-size: 2rem; right: 2rem;}

}
@media screen and (max-width: 820px) {
    .filter-wrap + .date-controller {margin-top: -2rem;}
    .date-controller {background-color: transparent; border-radius: 0; grid-template-columns: repeat(2, 1fr); grid-gap: 1.2rem; padding: 0; margin-bottom: 3.2rem;}
    .date-controller > li:nth-child(1) {grid-row: 2; grid-column: 1;}
    .date-controller > li:nth-child(2) {grid-row: 1; grid-column: 1 / span 2;}
    .date-controller > li:nth-child(3) {grid-row: 2; grid-column: 2;}

    .date-controller > li .controller-wrap {background-color: var(--gray100); padding: 1.6rem;}
    .date-controller > li .view-type-tab > li p {display: none;}
    .date-controller > li .view-type-tab > li i {margin-right: 0;}

    .game-schedule-list-wrap {grid-gap: 1.6rem; grid-template-columns: repeat(1, 1fr);}
    .game-schedule-list-wrap > li .button {height: fit-content;}

    .dleague-list-wrap {grid-gap: 1.6rem; grid-template-columns: repeat(1, 1fr);}
    .dleague-list-wrap > li .button {height: fit-content;}
}

/* LIVE/기록 > 일정 및 결과 > 상세(공통) */
.gamelist-set {display: flex; align-items: center; justify-content: space-between; margin-top: -4rem; padding-bottom: 2rem; margin-bottom: 4rem;}
.star-guide {display: flex; align-items: center; column-gap: .4rem; font-size: 1.4rem; font-weight: 500; color: var(--gray500);}
.star-guide > i {width: 1.6rem; height: 1.6rem;}

@media screen and (max-width: 1024px) {
    .gamelist-set {margin-top: -2rem; padding-bottom: 2rem; margin-bottom: 2rem;}

}
@media screen and (max-width: 820px) {
    .gamelist-set {margin-top: -1.2rem; padding-bottom: 2rem; margin-bottom: 0;}
    .star-guide {font-size: 1.2rem; display: block; width: 50%; word-break: keep-all; line-height: 1.4;}
    .star-guide > i {width: 1.4rem; height: 1.4rem; display: inline-block; vertical-align: middle;}
    .gamelist-set .toggle-wrap {margin-left: auto;}
}

.game-record-summary .option {display: flex; align-items: center; justify-content: end; margin-bottom: 2rem;}
.game-record-summary .option select {width: 32rem;}

.game-record-summary .summary-wrap {border-radius: 1.2rem; overflow: hidden;}
.game-record-summary .summary-wrap .summary-con {display: flex; align-items: center; justify-content: center; background-color: var(--pb900); padding: 3.6rem 6rem;}
.game-record-summary .summary-wrap .summary-con .team {width: calc((100% - 32rem) / 2); display: flex; align-items: center; justify-content: center;}
.game-record-summary .summary-wrap .summary-con .team:nth-child(1) {justify-content: end;}
.game-record-summary .summary-wrap .summary-con .team:nth-child(1) .info {align-items: end;}
.game-record-summary .summary-wrap .summary-con .versus + .team {justify-content: start;}
.game-record-summary .summary-wrap .summary-con .team >  * + * {margin-left: 4rem;}
.game-record-summary .summary-wrap .summary-con .team .info {display: flex; flex-flow: column; row-gap: .2rem;}
.game-record-summary .summary-wrap .summary-con .team .info > h6 {font-size: 2rem; font-weight: 600; color: #fff;}
.game-record-summary .summary-wrap .summary-con .team .info > ul > li {display: flex; align-items: center;}
.game-record-summary .summary-wrap .summary-con .team .info > ul > li + li {margin-top: .8rem;}
.game-record-summary .summary-wrap .summary-con .team .info > ul > li > * + * {margin-left: .4rem;}
.game-record-summary .summary-wrap .summary-con .team .info > ul > li > h6 {font-size: 1.4rem; color: var(--pb200);}
.game-record-summary .summary-wrap .summary-con .team .info > ul > li > p {font-size: 1.4rem; color: #fff; font-weight: 500;}

.game-record-summary .summary-wrap .summary-con .team:nth-child(1) .info > h6 {text-align: right;}
.game-record-summary .summary-wrap .summary-con .team:nth-child(1) .info > ul > li {justify-content: end;}

.game-record-summary .summary-wrap .summary-con .versus {width: 28rem; display: flex; flex-flow: column; align-items: center; justify-content: center; margin: 0 2rem; row-gap: 1.2rem;}
.game-record-summary .summary-wrap .summary-con .versus .score {display: flex; align-items: center; justify-content: center;}
.game-record-summary .summary-wrap .summary-con .versus .score > li {font-size: 4.8rem; color: #fff; font-weight: 600; display: flex; align-items: center;}
.game-record-summary .summary-wrap .summary-con .versus .score > li + li::before {content: ':'; color: var(--pb200); margin: 0 2.4rem;}
.game-record-summary .summary-wrap .summary-con .versus .judge {min-width: 21rem; background-color: var(--pb700); border-radius: .4rem; padding: .8rem; display: flex; align-items: center; justify-content: center;}
.game-record-summary .summary-wrap .summary-con .versus .judge > li {display: flex; align-items: center;}
.game-record-summary .summary-wrap .summary-con .versus .judge > li + li::before {content: ''; width: 1px; height: 1.2rem; background-color: var(--pb400); margin: 0 .6rem;}
.game-record-summary .summary-wrap .summary-con .versus .judge > li > * + * {margin-left: .4rem;}
.game-record-summary .summary-wrap .summary-con .versus .judge > li > h6 {font-size: 1.4rem; color: var(--pb200);}
.game-record-summary .summary-wrap .summary-con .versus .judge > li > p {font-size: 1.4rem; color: #fff; font-weight: 500;}
.game-record-summary .summary-wrap .summary-con .versus .broadcast {margin-top: 1.2rem; font-size: 1.2rem; color: var(--pb200);}

.game-record-summary .summary-wrap .summary-table {background-color: var(--pb700); padding-bottom: 1.6rem; position: relative;}
.game-record-summary .summary-wrap .summary-table::before {content: ''; width: 100%; height: 1px; background-color: var(--pb400); position: absolute; top: 4rem;}
.game-record-summary .summary-wrap .summary-table table {width: fit-content; margin: 0 auto; border-top: 0;}
.game-record-summary .summary-wrap .summary-table table::before {content: none;}
.game-record-summary .summary-wrap .summary-table table th {font-size: 1.4rem; font-weight: 500; line-height: 1; background-color: transparent; border: 0; color: var(--pb200);}
.game-record-summary .summary-wrap .summary-table table td {height: 3.2rem; padding: 1.6rem 1.6rem 0; font-size: 1.6rem; line-height: 1; background-color: transparent; border: 0; color: #fff; white-space: nowrap;}
.game-record-summary .summary-wrap .summary-table table td:nth-child(1) {text-align: left; font-weight: 500;}
.game-record-summary .summary-wrap .summary-table table tR > *:last-child {font-weight: 600; color: var(--yr500);}

@media screen and (max-width: 1024px) {
    .game-record-summary .summary-wrap {border-radius: .8rem;}
    .game-record-summary .summary-wrap .summary-con {padding: 2rem; flex-wrap: wrap;}
    .game-record-summary .summary-wrap .summary-con .team {flex-flow: column;}
    .game-record-summary .summary-wrap .summary-con .team:nth-child(1) {justify-content: center; flex-flow: column-reverse;}
    .game-record-summary .summary-wrap .summary-con .team:nth-last-child(1) {justify-content: center;}
    .game-record-summary .summary-wrap .summary-con .team > * + * {margin-left: 0;}
    .game-record-summary .summary-wrap .summary-con .team > i {width: 8rem; height: 5.2rem; margin-bottom: .4rem;}
    .game-record-summary .summary-wrap .summary-con .team .info > h6 {font-size: 1.6rem; text-align: center; line-height: 1.4;}
    .game-record-summary .summary-wrap .summary-con .team .info > ul {display: none;}
    .game-record-summary .summary-wrap .summary-con .team:nth-child(1) .info > h6 {text-align: center;}

    .game-record-summary .summary-wrap .summary-con .versus {margin: 0 2rem;}
    .game-record-summary .summary-wrap .summary-con .versus .score > li {font-size: 3.2rem;}
    .game-record-summary .summary-wrap .summary-con .versus .score > li + li::before {margin: 0 .8rem;}
    .game-record-summary .summary-wrap .summary-con .versus .judge {flex-flow: column;}
    .game-record-summary .summary-wrap .summary-con .versus .judge > li + li::before {content: none;}
    .game-record-summary .summary-wrap .summary-con .versus .judge > li + li {margin-top: .4rem;}
    .game-record-summary .summary-wrap .summary-con .versus .judge > li > h6 {font-size: 1.2rem;}
    .game-record-summary .summary-wrap .summary-con .versus .judge > li > p {font-size: 1.2rem;}

    .game-record-summary .summary-wrap .summary-con .mobile-only {display: flex; align-items: flex-start; justify-content: center; width: 100%; margin-top: 1.2rem; color: #fff;}
    .game-record-summary .summary-wrap .summary-con .mobile-only > * + * {margin-left: 2.4rem;}
    .game-record-summary .summary-wrap .summary-con .mobile-only ul {width: calc((100% - 2.4rem) / 2);}
    .game-record-summary .summary-wrap .summary-con .mobile-only ul > li {display: flex; align-items: center;}
    .game-record-summary .summary-wrap .summary-con .mobile-only ul > li + li {margin-top: .6rem;}
    .game-record-summary .summary-wrap .summary-con .mobile-only ul > li > * + * {margin-left: .6rem;}
    .game-record-summary .summary-wrap .summary-con .mobile-only ul > li h6 {font-size: 1.2rem; color: var(--pb200);}
    .game-record-summary .summary-wrap .summary-con .mobile-only ul > li p {font-size: 1.2rem; color: #fff; font-weight: 500;}
    .game-record-summary .summary-wrap .summary-con .mobile-only ul:nth-child(1) > li {justify-content: end;}

    .game-record-summary .summary-wrap .summary-table {padding-bottom: .8rem;}
    .game-record-summary .summary-wrap .summary-table::before {top: 2.8rem;}
    .game-record-summary .summary-wrap .summary-table table th {font-size: 1.2rem;}
    .game-record-summary .summary-wrap .summary-table table td {height: fit-content; padding: .8rem .8rem 0; font-size: 1.2rem;}
}
@media screen and (max-width: 820px) {
    .game-record-summary .option {margin-bottom: 1.2rem;}
    .game-record-summary .option select {width: 100%;}

    .game-record-summary .summary-wrap .summary-con .team {width: calc((100% - 18.4rem) / 2);}
    .game-record-summary .summary-wrap .summary-con .team > i {width: 6rem; height: 4rem;}
    .game-record-summary .summary-wrap .summary-con .team .info {align-items: center;}
    .game-record-summary .summary-wrap .summary-con .team .info > h6 {font-size: 1.2rem; height: 3.2rem; line-height: 1.2; word-break: keep-all;}

    .game-record-summary .summary-wrap .summary-con .versus {width: 14.8rem; margin: 0 1.8rem;}
    .game-record-summary .summary-wrap .summary-con .versus .score > li {font-size: 2.4rem;}
    .game-record-summary .summary-wrap .summary-con .versus .judge {min-width: 14.8rem; margin-top: .8rem;}
    .game-record-summary .summary-wrap .summary-con .versus .judge > li + li::before {margin: 0 .4rem;}
    .game-record-summary .summary-wrap .summary-con .versus .judge > li > * + * {margin-left: .2rem;}
    .game-record-summary .summary-wrap .summary-con .versus .judge > li > h6 {font-size: 1rem;}
    .game-record-summary .summary-wrap .summary-con .versus .judge > li > p {font-size: 1rem;}
    .game-record-summary .summary-wrap .summary-con .versus .broadcast {margin-top: .6rem; font-size: 1rem;}

    .game-record-summary .summary-wrap .summary-table table th {font-size: 1rem;}
    .game-record-summary .summary-wrap .summary-table table td {font-size: 1rem;}
}
/* 선수 스태프 --------------------------- */
/* 선수 스태프 > 선수 */
.player-list-wrap {display: grid; grid-template-columns: repeat(5, 1fr); grid-gap: 4rem 2rem;}
.player-list-wrap > li {cursor: pointer;}
.player-list-wrap > li .img {width: 100%; height: 16.8rem; border-radius: .8rem; background-repeat: no-repeat; background-size: cover; background-position: center top;}
.player-list-wrap > li .txt {margin-top: 1.6rem; display: flex; align-items: center; justify-content: space-between;}
.player-list-wrap > li .txt h6 {font-size: 2rem; font-weight: 600; width: 11.6rem; display: block; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
.player-list-wrap > li .txt span {width: 10.5rem; text-align: right; display: block; font-size: 1.4rem; color: var(--gray500);}

@media screen and (max-width: 1024px) {
    .player-list-wrap {grid-template-columns: repeat(4, 1fr); grid-gap: 2.4rem 1.2rem;}
    .player-list-wrap > li .img {height: 15vw; border-radius: .4rem;}
    .player-list-wrap > li .txt {margin-top: 1.2rem;}
    .player-list-wrap > li .txt h6 {font-size: 1.6rem; width: 9rem;}
    .player-list-wrap > li .txt span {font-size: 1.2rem; width: 9rem;}
}
@media screen and (max-width: 820px) {
    .player-list-wrap {grid-template-columns: repeat(2, 1fr); grid-gap: 2.4rem 1.2rem;}
    .player-list-wrap > li .img {height: 34vw;}
    .player-list-wrap > li .txt {margin-top: 1rem;}
    .player-list-wrap > li .txt h6 {width: 8.4rem; font-size: 1.4rem;}
    .player-list-wrap > li .txt span {font-size: 1rem; width: 8rem;}
}


/* 선수 > 코칭 스태프 */
.staff-list-wrap {display: grid; grid-template-columns: repeat(4, 1fr); grid-gap: 4rem 2rem;}
.staff-list-wrap + .staff-list-wrap {margin-top: 4rem;}
.staff-list-wrap > li {cursor: pointer;}
.staff-list-wrap > li .img {width: 100%; height: 27rem; border-radius: .8rem; background-repeat: no-repeat; background-size: cover; background-position: center top 1.6rem, center;}
.staff-list-wrap > li .txt {margin-top: 1.6rem; display: flex; align-items: center; justify-content: space-between;}
.staff-list-wrap > li .txt h6 {width: 18rem; font-size: 2rem; font-weight: 600; display: block; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
.staff-list-wrap > li .txt span {width: 11rem; text-align: right; font-size: 1.4rem; color: var(--gray500); display: block;}
.staff-list-wrap > li > button {width: 100%; margin-top: 3.2rem; background-color: var(--white01); border-color: var(--gray200); color: var(--gray500);}

@media screen and (max-width: 1024px) {
    .staff-list-wrap {grid-gap: 2rem 1.2rem;}
    .staff-list-wrap + .staff-list-wrap {margin-top: 2rem;}
    .staff-list-wrap > li .img {height: 22vw; border-radius: .4rem;}
    .staff-list-wrap > li .txt {margin-top: 1.2rem;}
    .staff-list-wrap > li .txt > h6 {width: 10rem; font-size: 1.6rem;}
    .staff-list-wrap > li .txt > span {width: 8rem; font-size: 1.2rem;}
    .staff-list-wrap > li > button {margin-top: 2rem; height: 4.4rem; font-size: 1.4rem;}
}
@media screen and (max-width: 820px) {
    .staff-list-wrap {grid-template-columns: repeat(2, 1fr);}
    .staff-list-wrap > li .img {height: 44vw;}
    .staff-list-wrap > li .txt h6 {font-size: 1.6rem; width: 7.4rem;}
    .staff-list-wrap > li .txt span {width: 8.6rem; font-size: 1.2rem;}
}

.staff-info {display: flex; align-items: flex-end;}
.staff-info .img {width: 12rem; height: 12rem; margin-right: 1.6rem; border-radius: .8rem; background-repeat: no-repeat; background-size: cover; background-position: center top 1rem, center;}
.staff-info .txt {width: calc(100% - 13.6rem); display: flex; flex-flow: column; align-items: flex-start;}
.staff-info .txt .name {display: flex; align-items: flex-end; margin-bottom: 1.2rem;}
.staff-info .txt .name h6 {font-size: 1.8rem; font-weight: 600;}
.staff-info .txt .name span {display: block; margin-left: .6rem; font-size: 1.4rem; color: var(--gray500);}
.staff-info .txt .info {width: 100%;}
.staff-info .txt .info > li {width: 100%; display: flex; align-items: center; justify-content: space-between;}
.staff-info .txt .info > li * {font-size: 1.4rem;}
.staff-info .txt .info > li h6 {color: var(--gray500);}
.staff-info .txt .info > li span {font-weight: 500;}

.staff-career > li {display: flex; flex-flow: column; align-items: flex-start;}
.staff-career > li + li {margin-top: 2.4rem;}
.staff-career > li h6 {font-size: 1.6rem; font-weight: 600; margin-bottom: .8rem;}
.staff-career > li > ul > li {display: flex; flex-flow: column; align-items: center; font-size: 1.4rem; color: var(--gray500); line-height: 1.6; padding-left: 1.2rem;}
.staff-career > li > ul > li::before {content: '-'; position: absolute; left: 0;}
.staff-career > li > ul > li + li {margin-top: .4rem;}
[data-theme="dark"] .staff-career > li > ul > li {color:#FFFFFF;}

@media screen and (max-width: 820px) {
    .staff-info .img {margin-right: 1.2rem; border-radius: .4rem;}
    .staff-info .txt {width: calc(100% - 13.2rem);}
    .staff-info .txt .name {display: flex; align-items: flex-end; margin-bottom: .8rem;}
    .staff-info .txt .name h6 {font-size: 1.6rem;}
    .staff-info .txt .name span {margin-left: .4rem; font-size: 1.2rem;}
    .staff-info .txt .info > li * {font-size: 1.2rem}

    .staff-career > li + li {margin-top: 2rem;}
    .staff-career > li h6 {margin-bottom: .6rem;}
    .staff-career > li > ul > li + li {margin-top: .4rem;}
}

/* fan > 문의 제안하기 */
.inquiry-wrap {display: grid; grid-template-columns: repeat(2, 1fr); grid-gap: 4rem 2rem;}
.inquiry-wrap .inquiry-box h6 {font-size: 1.8rem; font-weight: 600; margin-bottom: 1.6rem;}
.inquiry-wrap .inquiry-box:nth-child(3) {grid-row: 2; grid-column: 1 / span 2;}
.inquiry-wrap .inquiry-box .input-file {display: flex; align-items: center; grid-gap: .8rem;}
.inquiry-wrap .inquiry-box .input-file input {width: calc(100% - 12.8rem);}
.inquiry-wrap .inquiry-box .input-file button {min-width: 12rem;}

@media screen and (max-width: 1024px) {
    .inquiry-wrap {grid-gap: 2rem 1.2rem;}
    .inquiry-wrap .inquiry-box h6 {font-size: 1.6rem; margin-bottom: .8rem;}
}
@media screen and (max-width: 820px) {
    .inquiry-wrap {grid-template-columns: repeat(1, 1fr); grid-gap: 2rem;}
    .inquiry-wrap .inquiry-box:nth-child(3) {grid-column: 1;}
}

/* 미디어 뉴스 --------------------------- */
/* 미디어 뉴스 > 하이라이트 */
.media-list-grid-3 {display: grid; grid-template-columns: repeat(3, 1fr); grid-gap: 4rem 2rem;}
.media-list-grid-3 > li {display: flex; flex-flow: column; cursor: pointer;}
.media-list-grid-3 > li .img {width: 100%; height: 22rem; border-radius: 1.2rem; background-repeat: no-repeat; background-size: cover; background-position: center;}
.media-list-grid-3 > li .txt {margin-top: 1.2rem; display: flex; flex-flow: column; align-items: flex-start;}
.media-list-grid-3 > li .txt .title {display: flex; flex-flow: column; align-items: flex-start;}
.media-list-grid-3 > li .txt .title .tag {margin-bottom: .8rem;}
.media-list-grid-3 > li .txt .title h6 {width: 34rem; font-size: 1.6rem; font-weight: 500; display: block; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
.media-list-grid-3 > li .txt .title h6.ellipsis-2 {width: 100%; height: 4.5rem; white-space: initial; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; line-height: 1.4;}
.media-list-grid-3 > li .txt > ul {margin-top: 1.6rem;}
.media-list-grid-3 > li .txt > span {margin-top: 1.6rem; font-size: 1.4rem; color: var(--gray500);}

.media-list-grid-3 > li.hilight {position: relative; grid-column: 1 / span 3; border-radius: 1.2rem; overflow: hidden;}
.media-list-grid-3 > li.hilight .img {height: 67.8rem; background-repeat: no-repeat; background-size: cover; background-position: center;}
.media-list-grid-3 > li.hilight .txt {position: absolute; left: 0; bottom: 0; width: 100%; padding: 4rem; flex-flow: initial; justify-content: space-between; align-items: flex-end; display: none;}
.media-list-grid-3 > li.hilight .txt .title h6 {font-size: 2rem; color: #fff; width: 62rem;}

@media screen and (max-width: 1024px) {
    .media-list-grid-3 {grid-gap: 2rem 1rem;}
    .media-list-grid-3 > li .img {height: 18vw; border-radius: .4rem;}
    .media-list-grid-3 > li .txt .title .tag {margin-bottom: .4rem;}
    .media-list-grid-3 > li .txt .title h6 {width: 24rem; font-size: 1.4rem;}
    .media-list-grid-3 > li .txt .title h6.ellipsis-2 {font-size: 1.4rem; height: 4rem;}
    .media-list-grid-3 > li .txt > ul {margin-top: 1.2rem;}
    .media-list-grid-3 > li .txt > span {margin-top: 1.2rem;}

    .media-list-grid-3 > li.hilight .img {height: 52vw; border-radius: .4rem;}
    .media-list-grid-3 > li.hilight .txt {padding: 2rem;}
    .media-list-grid-3 > li.hilight .txt .title h6 {font-size: 1.8rem; width: 50rem;}
}
@media screen and (max-width: 820px) {
    .media-list-grid-3 {grid-template-columns: repeat(1, 1fr); grid-gap: 2rem;}
    .media-list-grid-3 > li {flex-flow: initial;}
    .media-list-grid-3 > li .img {width: 16rem; height: 9rem;}
    .media-list-grid-3 > li .txt {margin-top: 0; margin-left: 1.2rem; width: calc(100% - 17.2rem); display: flex; flex-flow: column; justify-content: center;}
    .media-list-grid-3 > li .txt .title h6 {width: 100%; height: 4rem; line-height: 1.4; white-space: initial; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; word-break: keep-all;}
    .media-list-grid-3 > li .txt .title h6.ellipsis-2 {line-height: 1.4; height: 4rem;}

    .media-list-grid-3 > li.hilight {grid-column: 1;}
    .media-list-grid-3 > li.hilight .img {width: 16rem; height: 9rem;}
    .media-list-grid-3 > li.hilight .txt {position: relative; left: initial; bottom: initial; width: calc(100% - 17.2rem); padding: 0; flex-flow: column; justify-content: initial; align-items: initial;}
    .media-list-grid-3 > li.hilight .txt .title h6 {width: 100%; font-size: 1.4rem; color: var(--black); white-space: initial; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;}
}

/* 미디어 뉴스 > 공지사항 > 상세 */
.board-view-wrap .board-tit {padding-bottom: 2rem; border-bottom: 1px solid var(--gray200);}
.board-view-wrap .board-tit h4 {font-size: 2.4rem; font-weight: 600; line-height: 1.6;}
.board-view-wrap .board-tit ul {margin-top: 4rem;}
.board-view-wrap .board-con {padding: 4rem 0; overflow-x: auto; width: 100%;}
.board-view-wrap .board-con img {width: 100%;}
.board-view-wrap .board-con * {font-size: 1.6rem; word-break: keep-all;}
.board-view-wrap .board-con p,
.board-view-wrap .board-con span {line-height: 1.8;}
.board-view-wrap .board-con p + p {margin-top: 1.6rem;}
.board-view-wrap .board-con table {margin: 10px 0 30px!important;}
.board-view-wrap .board-con table td {text-align: left;}
.board-view-wrap .board-file {border-top: 1px solid var(--gray200); padding: 2rem 0; display: flex; align-items: flex-start;}
.board-view-wrap .board-file h6 {font-size: 1.8rem; font-weight: 500; width: 7.2rem; margin-top: .4rem;}
.board-view-wrap .board-file ul {width: calc(100% - 7.2rem); display: flex; align-items: center; flex-wrap: wrap;}
.board-view-wrap .board-file ul > li {display: flex; align-items: flex-start; font-size: 1.8rem; color: var(--gray600); line-height: 1.6;}
.board-view-wrap .board-file ul > li i {margin-top: .3rem;}
.board-view-wrap .board-file ul > li p {width: calc(100% - 2rem); margin-left: .4rem; padding-right: 1.2rem; font-weight: 600;}
.con-view-btn.borad-btn {margin-top: 0;}
.con-view-btn {position: relative; margin-top: 4rem; padding-top: 5.2rem; display: flex; align-items: center; justify-content: space-between;}
.con-view-btn::before {content: ''; width: 100vw; min-width: 128rem; height: 1.2rem; background-color: var(--gray100); position: absolute; top: 0; left: 50%; -webkit-transform: translateX(-50%); -moz-transform: translateX(-50%); -ms-transform: translateX(-50%); -o-transform: translateX(-50%); transform: translateX(-50%);}
.con-view-btn button {width: 22rem;}
.con-view-btn > div {display: flex; align-items: center; justify-content: center;}
.con-view-btn > div button + button {margin-left: 1rem;}

@media (prefers-color-scheme: dark) {
    .board-view-wrap .board-con * {color: #fff!important;}
    .board-view-wrap .board-con table td {background-color: transparent!important;}
}
[data-theme="dark"] .board-view-wrap .board-con * {color: #fff!important;}
[data-theme="dark"] .board-view-wrap .board-con table td {background-color: transparent!important;}

@media screen and (max-width: 1024px) {
    .board-view-wrap .board-tit {padding-bottom: 1.6rem;}
    .board-view-wrap .board-tit h4 {font-size: 2rem; line-height: 1.4;}
    .board-view-wrap .board-tit ul {margin-top: 2rem;}
    .board-view-wrap .board-con {padding: 2rem 0;}
    .board-view-wrap .board-con * {font-size: 1.4rem;}
    .board-view-wrap .board-con p + p {margin-top: .8rem;}
    .board-view-wrap .board-file {padding: 1.6rem 0;}
    .board-view-wrap .board-file h6 {font-size: 1.3rem; width: 5rem; margin-top: .3rem;}
    .board-view-wrap .board-file ul {width: calc(100% - 5rem);}
    .board-view-wrap .board-file ul > li {font-size: 1.4rem; line-height: 1.6;}
    .board-view-wrap .board-file ul > li p {padding-right: .8rem;}
    .con-view-btn {padding-top: 4rem; display: flex; align-items: center; justify-content: space-between;}
    .con-view-btn::before {min-width: initial; height: .8rem;}
    .con-view-btn button {width: 16rem;}
    .con-view-btn > div button + button {margin-left: .8rem;}
}

@media screen and (max-width: 820px) {
    .con-view-btn::before {min-width: initial; height: .8rem;}
    .con-view-btn button {width: 8.4rem;}
    .con-view-btn button.prev,
    .con-view-btn button.next {width: 7.2rem!important;}
    .con-view-btn > div button + button {margin-left: .4rem;}
}

/* 미디어 뉴스 > 게임노트 */
.media-list-grid-4 {display: grid; grid-template-columns: repeat(4, 1fr); grid-gap: 4rem 2rem;}
.media-list-grid-4 > li {cursor: pointer;}
.media-list-grid-4 > li .img {height: 28.5rem; border-radius: 1.2rem; background-repeat: no-repeat; background-size: cover; background-position: center;}
.media-list-grid-4 > li .txt {margin-top: 1.2rem; display: flex; flex-flow: column; align-items: flex-start;}
.media-list-grid-4 > li .txt h6 {height: 5.2rem; font-size: 1.6rem; font-weight: 500; line-height: 1.6; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;}
.media-list-grid-4 > li .txt h6.ellipsis-1 {width: 26rem; height: fit-content; display: block; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
.media-list-grid-4 > li .txt span {margin-top: 1.6rem; font-size: 1.4rem; color: var(--gray500);}

@media screen and (max-width: 1024px) {
    .media-list-grid-4 {grid-gap: 2rem 1.2rem;}
    .media-list-grid-4 > li .img {height: 22vw; border-radius: .8rem;}
    .media-list-grid-4 > li .txt {margin-top: .8rem;}
    .media-list-grid-4 > li .txt h6 {height: 3.8rem; font-size: 1.4rem; line-height: 1.4;}
    .media-list-grid-4 > li .txt h6.ellipsis-1 {width: 16rem;}
    .media-list-grid-4 > li .txt span {margin-top: 1.2rem; font-size: 1.2rem;}
}
@media screen and (max-width: 820px) {
    .media-list-grid-4 {grid-template-columns: repeat(2, 1fr);}
    .media-list-grid-4 > li .img {height: 44vw;}
    .media-list-grid-4 > li .txt h6.ellipsis-1 {width: 12rem;}
}

/* 미디어 뉴스 > 사진 */
.photo-list-wrap {display: grid; grid-template-columns: repeat(3, 1fr); grid-gap: 4rem 2rem;}
.photo-list-wrap > li {cursor: pointer;}
.photo-list-wrap > li .img {height: 22rem; border-radius: 1.2rem; background-repeat: no-repeat; background-size: cover; background-position: center;}
.photo-list-wrap > li .txt {margin-top: 1.2rem; display: flex; flex-flow: column; align-items: flex-start;}
.photo-list-wrap > li .txt h6 {height: 5.2rem; font-size: 1.6rem; font-weight: 500; line-height: 1.6; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;}
.photo-list-wrap > li .txt span {margin-top: 1.6rem; font-size: 1.4rem; color: var(--gray500);}

/* 스토리 > 제도 및 규칙 */
.rule-list-wrap {display: grid; grid-template-columns: repeat(4, 1fr); grid-gap: 4rem 2rem;}
.rule-list-wrap li .img {width: 100%; height: 40rem; border-radius: .8rem; border: 1px solid var(--gray200); background-repeat: no-repeat; background-size: cover; background-position: center;}
.rule-list-wrap li .txt {margin-top: 1.6rem;}
.rule-list-wrap li .txt > h6 {width: 24rem; font-size: 2rem; font-weight: 600; display: block; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
.rule-list-wrap li .txt > ul {margin-top: 1.6rem;}
.rule-list-wrap li button {width: 100%; margin-top: 3.2rem; background-color: var(--white01); border-color: var(--gray200); color: var(--gray500);}

@media screen and (max-width: 1024px) {
    .rule-list-wrap {grid-gap: 2rem 1.2rem;}
    .rule-list-wrap li .img {height: 30vw; border-radius: .4rem;}
    .rule-list-wrap li .txt {margin-top: 1.2rem;}
    .rule-list-wrap li .txt > h6 {font-size: 1.6rem; height: 4.4rem; line-height: 1.4; width: auto; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; white-space: initial;} 
    .rule-list-wrap li .txt > ul {margin-top: .8rem;}
    .rule-list-wrap li button {margin-top: 2rem; height: 4.4rem; font-size: 1.4rem;}
}
@media screen and (max-width: 820px) {
    .rule-list-wrap {grid-template-columns: repeat(2, 1fr);}
    .rule-list-wrap > li .img {height: 62vw;}
    .rule-list-wrap > li .txt > h6 {font-size: 1.6rem;}
    .rule-list-wrap > li .txt > ul {font-size: 1.2rem;}
}

/* 스토리 > 제도 및 규칙 > 정관 및 규약 */
.story-player-rule-table01 table td:not(:nth-child(1)) {text-align: left; font-size: 1.4rem;}
.story-player-rule-table01 table td dl > dt {font-weight: 600;}
.story-player-rule-table01 table td dl > dt.font700 {font-weight: 700;}
.story-player-rule-table01 table td dl > dt.font700 + dt {margin-top: .2rem;}
.story-player-rule-table01 table td dl > dd {position: relative; display: flex; align-items: flex-start; padding-left: 1.2rem; line-height: 1.6; color: var(--gray500);}
.story-player-rule-table01 table td dl > dd::before {content: '-'; position: absolute; left: 0;}
.story-player-rule-table01 table td dl > dd.no-dash {text-indent: -1.6rem; padding-left: 2.8rem;}
.story-player-rule-table01 table td dl > dd.no-dash::before {content: none;}
.story-player-rule-table01 table td dl > dt + dt {margin-top: 1.2rem;}
.story-player-rule-table01 table td dl > dt + dd {margin-top: .2rem;}
.story-player-rule-table01 table td dl > dd + dt {margin-top: 1.2rem;}

@media screen and (max-width: 1024px) {
    .story-player-rule-table01 table td:not(:nth-child(1)) {font-size: 1.2rem;}
    .story-player-rule-table01 table td dl > dd.no-dash {text-indent: 0; padding-left: 1.2rem;}
    .story-player-rule-table01 table td dl > dd.no-dash::before {content: none;}
    .story-player-rule-table01 table td dl > dt + dt {margin-top: .8rem;}
    .story-player-rule-table01 table td dl > dd + dt {margin-top: .8rem;}
}

/* 스토리 > 제도 및 규칙 > 운영방식 */
.story-operation-method-wrap {border-top: 1px solid var(--black);}
.story-operation-method-wrap > li {display: flex; align-items: center; padding: 1.6rem;}
.story-operation-method-wrap > li:not(.img):last-child {border-bottom: 1px solid var(--gray200);}
.story-operation-method-wrap > li + li {border-top: 1px solid var(--gray200);}
.story-operation-method-wrap > li .num {width: 3.6rem; height: 3.6rem; margin-right: 1.6rem; font-size: 2rem; font-weight: 600; color: var(--gray500); background-color: var(--gray100); border-radius: .4rem; display: flex; align-items: center; justify-content: center;}
.story-operation-method-wrap > li .txt {width: calc(100% - 5.2rem); display: flex; flex-flow: column; align-items: flex-start; justify-content: center;}
.story-operation-method-wrap > li .txt p {font-size: 1.6rem; font-weight: 500; line-height: 1.4; word-break: keep-all;}
.story-operation-method-wrap > li .txt span {display: block; margin-top: .8rem; font-size: 1.4rem; color: var(--gray500); line-height: 1.6; word-break: keep-all;}
.story-operation-method-wrap > li.img {border-top: 0; background-color: var(--gray100); border-radius: 1.2rem; padding: 4rem;}
.story-operation-method-wrap > li.img img {width: 100%;}

@media screen and (max-width: 1024px) {
    .story-operation-method-wrap > li {padding: 1.2rem;}
    .story-operation-method-wrap > li .num {width: 2.8rem; height: 2.8rem; margin-right: 1.2rem; font-size: 1.6rem;}
    .story-operation-method-wrap > li .txt {width: calc(100% - 4rem);}
    .story-operation-method-wrap > li .txt p {font-size: 1.4rem;}
    .story-operation-method-wrap > li .txt span {margin-top: .4rem; font-size: 1.2rem;}
    .story-operation-method-wrap > li.img {border-radius: .8rem; padding: 2rem;}
}
@media screen and (max-width: 820px) {
    .story-operation-method-wrap > li {padding: 1.2rem 0;}
    .story-operation-method-wrap > li.img {padding: 1.6rem;}
}

/* KBL --------------------------- */
/* kbl > 소개 인사말 */
.greeting-para p {font-size: 1.6rem; line-height: 1.6;}
.greeting-para img {width: 100%; border-radius: 1.2rem;}
.greeting-para > * + img {margin-top: 4rem;}

.business-field {display: grid; grid-template-columns: repeat(5, 1fr); border: 1px solid var(--gray200); border-radius: 1.2rem; background-color: var(--white01);}
.business-field > li:not(:nth-child(5n+1)) {border-left: 1px solid var(--gray200);}
.business-field > li:not(:nth-child(n+6)) {border-bottom: 1px solid var(--gray200);}
.business-field > li {height: 24rem; display: flex; flex-flow: column; align-items: center; justify-content: center;}
.business-field > li p {height: 5.2rem; margin-top: 1.4rem; text-align: center; font-size: 1.6rem; font-weight: 500; line-height: 1.6;}

.map-wrap .map-img {margin-bottom: 4rem; border-radius: 1.2rem; overflow: hidden;}
.map-wrap .map-img img {width: 100%;}
.map-wrap > p {font-size: 2rem; line-height: 1.6; font-weight: 600;}
.transport-wrap {margin-top: 2.4rem; display: flex; border-top: 1px solid var(--black); border-bottom: 1px solid var(--gray200); background-color: var(--white01);}
.transport-wrap > li {padding: 2rem;}
.transport-wrap > li + li {border-left: 1px solid var(--gray200);}
.transport-wrap > li:nth-child(1):nth-last-child(3), .transport-wrap > li:nth-child(1):nth-last-child(3) ~ li {width: calc(100% / 3);}
.transport-wrap > li:nth-child(1):nth-last-child(2), .transport-wrap > li:nth-child(1):nth-last-child(2) ~ li {width: calc(100% / 2);}
.transport-wrap > li h6 {font-size: 1.6rem; font-weight: 500; margin-bottom: 2rem;}
.transport-wrap > li ul > li {display: flex; align-items: flex-start;}
.transport-wrap > li ul > li + li {margin-top: .4rem;}
.transport-wrap > li ul > li p {font-size: 1.4rem; font-weight: 500; width: 7.4rem; margin-right: 1.2rem; line-height: 1.6;}
.transport-wrap > li ul > li span {display: block; width: calc(100% - 8.6rem); font-size: 1.4rem; color: var(--gray500); line-height: 1.6;}

@media (prefers-color-scheme: dark) {
    .transport-wrap {border-top-color: var(--gray600);}
}
[data-theme="dark"] .transport-wrap {border-top-color: var(--gray600);}
@media screen and (max-width: 1024px) {
    .greeting-para p {font-size: 1.4rem;}
    .greeting-para img {border-radius: .8rem;}
    .greeting-para > * + img {margin-top: 2rem;}

    .business-field {border-radius: .8rem;}
    .business-field > li {height: 14.8rem;}
    .business-field > li i {width: 4.8rem; height: 4.8rem;}
    .business-field > li p {height: 4rem; margin-top: 1.2rem; font-size: 1.4rem; line-height: 1.4;}    

    .map-wrap .map-img {margin-bottom: 2rem; border-radius: .8rem;}
    .map-wrap > p {font-size: 1.6rem;}
    .transport-wrap {margin-top: 2rem;}
    .transport-wrap > li {padding: 1.6rem;}
    .transport-wrap > li h6 {margin-bottom: 1.6rem;}
    .transport-wrap > li ul > li p {width: 6rem; margin-right: .8rem; font-size: 1.2rem;}
    .transport-wrap > li ul > li span {width: calc(100% - 6.8rem); font-size: 1.2rem;}
}
@media screen and (max-width: 820px) {
    .business-field {grid-template-columns: repeat(2, 1fr);}
    .business-field > li:not(:nth-child(5n+1)) {border-left: 0;}
    .business-field > li:not(:nth-child(n+6)) {border-bottom: 0;}
    .business-field > li:not(:nth-child(2n+1)) {border-left: 1px solid var(--gray200);}
    .business-field > li:not(:nth-last-child(-n+2)) {border-bottom: 1px solid var(--gray200);}
    
    .transport-wrap {flex-wrap: wrap;}
    .transport-wrap > li {width: 100%!important; padding: 1.6rem 0;}
    .transport-wrap > li + li {border-left: 0; border-top: 1px solid var(--gray200);}
    .transport-wrap > li:nth-child(1):nth-last-child(3), .transport-wrap > li:nth-child(1):nth-last-child(3) ~ li {width: 100%;}
    .transport-wrap > li ul > li + li {margin-top: .8rem;}
}

/* 티켓 > 티켓안내 */
.ticket-guidance > * + * {margin-top: 2.4rem;}
.ticket-guidance > p {font-size: 1.6rem; line-height: 1.6; word-break: keep-all;}
.ticket-guidance ol > li {display: flex; align-items: flex-start; font-size: 1.6rem; line-height: 1.6; word-break: keep-all;}
.ticket-guidance ol > li + li {margin-top: 1.6rem;}
.ticket-guidance ol > li h6 {width: 8rem; display: block; font-weight: 500;}
.ticket-guidance ol > li p {width: calc(100% - 8rem);}
.ticket-guidance ol.desc > li h6 {width: 18rem;}
.ticket-guidance ol.desc > li p {width: calc(100% - 18rem);}
.ticket-guidance ul > li {position: relative; display: flex; align-items: flex-start; padding-left: 1.2rem; font-size: 1.4rem; color: var(--gray500); line-height: 1.6; word-break: keep-all;}
.ticket-guidance ul > li::before {content: '-'; position: absolute; left: 0;}

@media screen and (max-width: 1024px) {
    .ticket-guidance > * + * {margin-top: 1.6rem;}
    .ticket-guidance > p {font-size: 1.4rem;}
    .ticket-guidance ol > li {font-size: 1.4rem;}
    .ticket-guidance ol > li + li {margin-top: .8rem;}
    .ticket-guidance ol > li h6 {width: 6rem;}
    .ticket-guidance ol > li p {width: calc(100% - 6rem);}
    .ticket-guidance ol.desc > li h6 {width: 12rem;}
    .ticket-guidance ol.desc > li p {width: calc(100% - 12rem);}
    .ticket-guidance ol.desc > li h6 {width: 18rem;}
    .ticket-guidance ul > li {font-size: 1.2rem;}
    .ticket-guidance ul > li + li {margin-top: .4rem;}
}

/* 기타 --------------------------- */
/*  cursor */
.cursor-no-link {cursor: default !important;}
.cursor-link {cursor: pointer !important;}
/* 이용약관 */
.terms-wrap {font-size: 1.6rem; line-height: 1.6;}

@media screen and (max-width: 1024px) {
    .terms-wrap {font-size: 1.4rem;}
}

/* 유소년 --------------------------- */
.youth-table {width: 100%; overflow-x: auto;}
.youth-table table td[rowspan]:not(:last-child) {border-right: 1px solid var(--gray200);}
.youth-table table td button {display: inline-flex; height: 3.2rem; padding: 0 .8rem; margin-left: .6rem; font-size: 1.4rem; border-radius: .2rem; background-color: var(--white01); border-color: var(--gray200); color: var(--gray500);}
.youth-table + .youth-table {margin-top: 2.4rem;}

.youth-intro-wrap + * {margin-top: 2.4rem;}
.youth-intro-wrap > * + * {margin-top: 2.4rem;}
.youth-intro-wrap h6 {font-size: 2.4rem; font-weight: 600; line-height: 1.6;}
.youth-intro-wrap p {font-size: 1.6rem; line-height: 1.6;}

.youth-intro-img {width: 100%; border-radius: 1.2rem; overflow: hidden;}
.youth-intro-img img {width: 100%;}

.youth-intro-desc {border-top: 1px solid var(--black);}
.youth-intro-desc > li {border-bottom: 1px solid var(--gray200); padding: 2rem;}
.youth-intro-desc > li > * + * {margin-top: .8rem;}
.youth-intro-desc > li h6 {font-size: 1.6rem; font-weight: 500;}
.youth-intro-desc > li p {font-size: 1.4rem; color: var(--gray500); line-height: 1.4;}

@media (prefers-color-scheme: dark) {
    .youth-intro-desc {border-top-color: var(--gray200);}
}
[data-theme="dark"] .youth-intro-desc {border-top-color: var(--gray200);}
@media screen and (max-width: 1024px) {
    .youth-intro-wrap > * + * {margin-top: 1.2rem;}
    .youth-intro-wrap h6 {font-size: 1.8rem;}
    .youth-intro-wrap p {font-size: 1.4rem;}
    
    .youth-intro-img {border-radius: .8rem;}
    
    .youth-intro-desc > li {padding: 1.2rem 0;}
    .youth-intro-desc > li > * + * {margin-top: .8rem;}
    .youth-intro-desc > li h6 {font-size: 1.4rem;}
    .youth-intro-desc > li p {font-size: 1.2rem;}
}

.youth-branch-wrap {border-top: 1px solid var(--black); padding: 2rem; border-bottom: 1px solid var(--gray200);}
.youth-branch-wrap > li {display: flex; align-items: flex-start;}
.youth-branch-wrap > li + li {margin-top: 1.2rem;}
.youth-branch-wrap > li h6 {font-size: 1.6rem; font-weight: 500; line-height: 1.6; width: 12rem;}
.youth-branch-wrap > li ul {width: calc(100% - 12rem);}
.youth-branch-wrap > li ul > li {font-size: 1.6rem; color: var(--gray500); line-height: 1.6;}

.youth-branch-staff {background-color: var(--gray100); border-radius: 1.2rem; padding: 8rem;}
.youth-branch-staff > ul {display: grid; grid-template-columns: repeat(4, 1fr); grid-gap: 4rem 8rem;}
.youth-branch-staff > ul .img {width: 20rem; height: 20rem; margin: 0 auto; border-radius: .8rem; background-repeat: no-repeat; background-position: center; background-size: cover;}
.youth-branch-staff > ul .txt {margin-top: 1.6rem;}
.youth-branch-staff > ul .txt h6 {font-size: 1.6rem; font-weight: 600;}
.youth-branch-staff > ul .txt ul {margin-top: .8rem;}
.youth-branch-staff > ul .txt ul > li {font-size: 1.4rem; line-height: 1.6;}

.youth-branch-map {width: 100%; border-radius: 1.2rem; overflow: hidden;}
.youth-branch-map img {width: 100%;}

@media (prefers-color-scheme: dark) {
    .youth-branch-wrap {border-top-color: var(--gray200);}
}
[data-theme="dark"] .youth-branch-wrap {border-top-color: var(--gray200);}
@media screen and (max-width: 1024px) {
    .youth-branch-wrap {padding: 1.2rem 0;}
    .youth-branch-wrap > li + li {margin-top: .8rem;}
    .youth-branch-wrap > li h6 {font-size: 1.4rem; width: 10rem;}
    .youth-branch-wrap > li ul {width: calc(100% - 10rem);}
    .youth-branch-wrap > li ul > li {font-size: 1.4rem;}
    
    .youth-branch-staff {border-radius: .8rem; padding: 2rem;}
    .youth-branch-staff > ul {grid-template-columns: repeat(4, 1fr); grid-gap: 2rem;}
    .youth-branch-staff > ul .img {width: 14.5rem; height: 14.5rem; border-radius: .4rem;}
    .youth-branch-staff > ul .txt {margin-top: 1.2rem;}
    .youth-branch-staff > ul .txt h6 {font-size: 1.4rem;}
    .youth-branch-staff > ul .txt ul > li {font-size: 1.2rem;}
    
    .youth-branch-map {border-radius: .8rem;}
}
@media screen and (max-width: 820px) {
    .youth-branch-staff > ul {grid-template-columns: repeat(2, 1fr); grid-gap: 2rem 1.2rem;}
}

/* 22.11.24 경품 지급 안내 팝업, 정보 제공 동의 */
.marketing-pop {display: flex; flex-flow: column; align-items: center; justify-content: center;}
.marketing-pop > i {width: 10rem; height: 10rem;}
.marketing-pop > p {margin-top: 1.6rem;}
.marketing-agree {width: 100%; max-width: 45.2rem; margin: 3.6rem auto 0; padding: 1.8rem 1.6rem; display: flex; align-items: center; justify-content: space-between; background-color: var(--gray100); border-radius: .4rem;}
.marketing-agree > p {display: flex; align-items: center; column-gap: .2rem; line-height: 100%; font-size: 1.6rem; font-weight: 500;}
.marketing-agree > p > span {font-size: 1.4rem;}
.marketing-agree > a {font-size: 1.4rem; color: var(--gray500); text-decoration: underline;}

.coupon.con-empty {padding: 6rem 0 0;}

/* 23.03.28 유소년 */
.info-input {display: flex; flex-flow: column; row-gap: 2.4rem;}
.info-input > li {display: flex; flex-flow: column; row-gap: 1.2rem;}
.info-input > li > h6 {font-size: 1.4rem; font-weight: 500;}

/* progress Css --------------------------- */
.progress-wrapper {
    width: 100%;
    height: 5px;
}
.html5-progress-bar {
    background-color: #fff;
    box-shadow:  0px 1px 2px 0px rgba(0, 0, 0, .2);
}
.html5-progress-bar progress {
    background-color: #f3f3f3;
    width: 100%;
    height: 10px;
}
.html5-progress-bar progress::-webkit-progress-bar {
    background-color: #f3f3f3;
    border-radius: 9px;
}
.html5-progress-bar progress::-webkit-progress-value {
    background: #cdeb8e;
    background: -moz-linear-gradient(top,  #cdeb8e 0%, #a5c956 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#cdeb8e), color-stop(100%,#a5c956));
    background: -webkit-linear-gradient(top,  #cdeb8e 0%,#a5c956 100%);
    background: -o-linear-gradient(top,  #cdeb8e 0%,#a5c956 100%);
    background: -ms-linear-gradient(top,  #cdeb8e 0%,#a5c956 100%);
    background: linear-gradient(to bottom,  #cdeb8e 0%,#a5c956 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cdeb8e', endColorstr='#a5c956',GradientType=0 );
    border-radius: 9px;
}
.html5-progress-bar progress::-moz-progress-bar {
    background: #cdeb8e;
    background: -moz-linear-gradient(top,  #cdeb8e 0%, #a5c956 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#cdeb8e), color-stop(100%,#a5c956));
    background: -webkit-linear-gradient(top,  #cdeb8e 0%,#a5c956 100%);
    background: -o-linear-gradient(top,  #cdeb8e 0%,#a5c956 100%);
    background: -ms-linear-gradient(top,  #cdeb8e 0%,#a5c956 100%);
    background: linear-gradient(to bottom,  #cdeb8e 0%,#a5c956 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cdeb8e', endColorstr='#a5c956',GradientType=0 );
    border-radius: 9px;
}
.html5-progress-bar .progress-value {
    padding: 0px 5px;
    line-height: 20px;
    margin-left: 5px;
    font-size: .8em;
    color: #555;
    height: 18px;
    float: right;
}
/* progress Css --------------------------- */