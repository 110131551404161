

/* root --------------------------- */
:root {
    --vh: 100%;
    
    /* ------------ brand color */
    --pb50: #E3E6ED;
    --pb100: #BAC0D4;
    --pb200: #8D97B7;
    --pb300: #63709A;
    --pb400: #445387;
    --pb500: #223976; /* kbl sub color */
    --pb600: #1C326E;
    --pb700: #142A63;
    --pb800: #0B2157;
    --pb900: #011241; /* kbl main color */

    --yr50: #FBEAE7;
    --yr100: #FFCEBC;
    --yr200: #FFAE90;
    --yr300: #FF8F63;
    --yr400: #FF7740;
    --yr500: #FF601C; /* kbl point color */
    --yr600: #F45A18;
    --yr700: #E65313;
    --yr800: #D84C0F;
    --yr900: #BF3F06;

    /* ------------ greyscale color */
    --gray100: #F2F3F6;
    --gray200: #E3E5E9;
    --gray300: #D7D8DA;
    --gray400: #BDBEC0;
    --gray500: #747479;
    --gray600: #424243;
    --gray700: #2B2B2B;
    --gray800: #1C1C1C;
    --gray900: #121212;

    /* ------------ system color */
    --red: #FF3529;
    --blue: #236CD9;
    --white01: #FFFFFF;
    --white02: #FFFFFF;
    --black: #000000;

    /* ------------ club color */
    /* 원주 DB 프로미 : db */
    --db-main: #03662C;
    --db-point: #00399F;
    --db-sub01: #71BB98;
    --db-sub02: #C6EAD9;
    /* 서울 삼성 썬더스 : ss */
    --ss-main: #0032A0;
    --ss-point: #FFB81C;
    --ss-sub01: #6D94C7;
    --ss-sub02: #CEDDF1;
    /* 서울 SK 나이츠 : sk */
    --sk-main: #DB0028;
    --sk-point: #FF9600;
    --sk-sub01: #EC696F;
    --sk-sub02: #F9D3DA;
    /* 창원 LG 세이커스 : lg */
    --lg-main: #550E10;
    --lg-point: #FFBA00;
    --lg-sub01: #996E70;
    --lg-sub02: #D3B0B2;
    /* 고양 소노 스카이거너스 : or (오리온) */
    --or-main: #283D60;
    --or-point: #7DA1C9;
    --or-sub01: #63748D;
    --or-sub02: #CBD1D9;
    /* 전수 KCC 이지스 : kcc */
    --kcc-main: #07215A;
    --kcc-point: #FF9D00;
    --kcc-sub01: #666E80;
    --kcc-sub02: #ACB3C3;
    /* 안양 KGC 인삼공사 : kgc */
    --kgc-main: #D61D38;
    --kgc-point: #171717;
    --kgc-sub01: #E9667E;
    --kgc-sub02: #F2CBD2;
    /* 수원 KT 소닉붐 : kt */
    --kt-main: #E21820;
    --kt-point: #F07C22;
    --kt-sub01: #F36E6B;
    --kt-sub02: #F1CBD1;
    /* 대구 한국가스공사 : el */
    --el-main: #201351;
    --el-point: #BE1540;
    --el-sub01: #797197;
    --el-sub02: #BEBACF;
    /* 울산 현대모비스 피버스 : hd */
    --hd-main: #00287A;
    --hd-point: #ED1C24;
    --hd-sub01: #617196;
    --hd-sub02: #BFC7D8;

    /* ------------ box shadow */
    --shadow-gr: .2rem .2rem 2rem rgba(1, 18, 65, 0.04);
    --shadow-pb: .4rem .4rem 1.2rem rgba(1, 18, 65, 0.16);
    --shadow-yr: .4rem .4rem 3.2rem rgba(255, 96, 28, 0.2);
}

@media (prefers-color-scheme: dark) {
    :root {
        /* ------------ greyscale color */
        --gray100: #1C1C1C;
        --gray200: #2B2B2B;
        --gray300: #424243;
        --gray400: #424243;

        /* ------------ system color */
        --white01: #1C1C1C;
        --white02: #121212;
        --black: #FFFFFF;
    }
}

header.youth .desktop-only .header-wrap .header-con .logo {width: 14.6rem;}
header.youth .desktop-only .header-wrap .header-con .logo .logo-kbl {background-image: url(/imgs/logo/logo-kbl-youth.svg);}
header.youth .nav-wrap {background-color: var(--yr500);}
header.youth .nav-wrap .nav-con:hover::before {background-color: var(--yr400);}
header.youth .nav-wrap .nav-con > li > ul > li.active {color: var(--pb800);}

.youth .main-banner-desktop-swiper01 .swiper-slide-active .main-banner-con .img {right: auto; z-index: 0; width: 100%; height: 44.8rem; background-repeat: no-repeat; background-size: contain; background-position: center; -webkit-animation-name: none; animation-name: none; -webkit-animation-timing-function: initial; animation-timing-function: initial; -webkit-animation-duration: initial; animation-duration: initial; -webkit-animation-iteration-count: initial; animation-iteration-count: initial; -webkit-animation-fill-mode: initial; animation-fill-mode: initial;}
.youth .main-banner-desktop-swiper01 .main-banner-list,
.youth .main-banner-desktop-swiper01 .main-banner-list-nav {width: 120rem; margin: 0 auto;}
.youth .con-box .con-tit > span {font-size: 1.6rem; font-weight: 500; color: var(--gray500); word-break: keep-all;}

@media screen and (max-width: 1024px) {
    .youth .main-banner-desktop-swiper01 .main-banner-list,
    .youth .main-banner-desktop-swiper01 .main-banner-list-nav {width: 100%;}
}
@media screen and (max-width: 820px) {
    .youth .con-box .con-tit > span {font-size: 1.4rem; line-height: 1.4;}
    .youth .con-box .con-tit .option-wrap select {display: block; width: 100%!important;}
}
[data-theme="dark"] header.youth .desktop-only .header-wrap .header-con .logo .logo-kbl {background-image: url(/imgs/logo/logo-kbl-youth-dark.svg);}

header.youth .mobile-only .mobile-menu-wrap .user-wrap {background-color: var(--yr500); border-block-color: var(--yr400);}
header.youth .mobile-only .mobile-menu-wrap .user-wrap > ul > li:nth-child(1) {background-color: var(--yr400);}
header.youth .mobile-only .mobile-menu-wrap .user-wrap > ul > li:nth-child(2) {background-color: var(--yr300);}

.youth .chain-wrap {display: grid; grid-template-columns: repeat(5, 1fr); grid-gap: 2rem;}
.youth .chain-wrap > li {display: flex; align-items: center; column-gap: .6rem; border: 1px solid var(--gray200); background-color: var(--white01); border-radius: .4rem; padding: 1.6rem; cursor: pointer;}
.youth .chain-wrap > li > i {width: 4.8rem; height: 3.2rem;}
.youth .chain-wrap > li > p {font-size: 1.6rem; font-weight: 500;}
.youth .chain-wrap > li.active {border-color: var(--yr500); background-color: var(--yr50);}
.youth .chain-wrap > li.active > p {font-weight: 600; color: var(--yr500);}

@media screen and (max-width: 1024px) {
    .youth .chain-wrap {grid-gap: 1rem;}
    .youth .chain-wrap > li > i {width: 3.8rem; height: 2.6rem;}
    .youth .chain-wrap > li > p {font-size: 1.4rem;}
}
@media screen and (max-width: 820px) {
}

.youth .chain-map-wrap {width: 100%; height: 48rem; margin-top: 4rem; border-radius: 1.2rem; position: relative; background-repeat: no-repeat; background-size: cover; background-position: center;}
.youth .chain-map-wrap .chain-search {width: 34rem; height: 40rem; border-radius: .8rem; position: absolute; top: 4rem; left: 4rem; background-color: var(--white01); box-shadow: .4rem .4rem 1.2rem rgba(70, 81, 109, .16);}
.youth .chain-map-wrap .chain-search .title {display: flex; align-items: center; justify-content: center; padding: 2rem;}
.youth .chain-map-wrap .chain-search .title > h4 {font-size: 2rem; font-weight: 600;}
.youth .chain-map-wrap .chain-search .search-wrap {padding: 0 2rem;}
.youth .chain-map-wrap .chain-search .search-wrap .search {position: relative;}
.youth .chain-map-wrap .chain-search .search-wrap .search > input {width: 100%;}
.youth .chain-map-wrap .chain-search .search-wrap .search > button {width: fit-content; height: fit-content; background-color: transparent; border-color: transparent; position: absolute; right: 1.4rem; top: 50%; -webkit-transform: translateY(-50%); -moz-transform: translateY(-50%); -ms-transform: translateY(-50%); -o-transform: translateY(-50%); transform: translateY(-50%);}
.youth .chain-map-wrap .chain-search .search-wrap > span {font-size: 1.2rem; color: var(--gray500); margin-top: 1.2rem; display: block; line-height: 1.4; word-break: keep-all;}
.youth .chain-map-wrap .chain-search .result {margin-top: 2rem; height: 100%;}
.youth .chain-map-wrap .chain-search .result .result-num {background-color: var(--gray100); padding: 1.2rem 2rem; font-size: 1.4rem;}
.youth .chain-map-wrap .chain-search .result > ul {height: fit-content; max-height: calc(100% - 6rem - 7.2rem - 2rem - 3.8rem); overflow-y: auto;}
.youth .chain-map-wrap .chain-search .result > ul > li {padding: 2rem; display: flex; flex-flow: column; row-gap: .8rem;}
.youth .chain-map-wrap .chain-search .result > ul > li + li {border-top: 1px solid var(--gray200)}
.youth .chain-map-wrap .chain-search .result > ul > li > h6 {font-size: 1.6rem; font-weight: 600;}
.youth .chain-map-wrap .chain-search .result > ul > li > p {font-size: 1.4rem; line-height: 1.4;}

@media screen and (max-width: 1024px) {
    .youth .chain-map-wrap {height: 40rem; margin-top: 2rem;}
    .youth .chain-map-wrap .chain-search {width: 28rem; height: 36rem; top: 2rem; left: 2rem;}
    .youth .chain-map-wrap .chain-search .title {padding: 1.6rem;}
    .youth .chain-map-wrap .chain-search .title > h4 {font-size: 1.6rem;}
    .youth .chain-map-wrap .chain-search .search-wrap {padding: 0 1.6rem;}
    .youth .chain-map-wrap .chain-search .search-wrap .search > button {right: .6rem;}
    .youth .chain-map-wrap .chain-search .search-wrap > span {margin-top: .8rem; height: 3.4rem;}
    .youth .chain-map-wrap .chain-search .result {margin-top: 1rem;}
    .youth .chain-map-wrap .chain-search .result .result-num {padding: .8rem 1.6rem; font-size: 1.2rem;}
    .youth .chain-map-wrap .chain-search .result > ul {max-height: calc(100% - 4.8rem - 8.6rem - 1rem - 2.8rem);}
    .youth .chain-map-wrap .chain-search .result > ul > li {padding: 1.2rem 1.6rem; row-gap: .4rem;}
    .youth .chain-map-wrap .chain-search .result > ul > li > h6 {font-size: 1.4rem;}
    .youth .chain-map-wrap .chain-search .result > ul > li > p {font-size: 1.2rem;}
}
@media screen and (max-width: 820px) {
    .youth .chain-search-mobile {display: flex; flex-flow: column; row-gap: 1.2rem; margin-top: 1.2rem;}
    .youth .chain-search-mobile > div {/*display: grid;*/ grid-template-columns: repeat(2, 1fr); column-gap: .8rem;}
    .youth .chain-search-mobile > div .search {position: relative;}
    .youth .chain-search-mobile > div .search > input {width: 100%;}
    .youth .chain-search-mobile > div .search > button {width: fit-content; height: fit-content; background-color: transparent; border-color: transparent; position: absolute; right: 0; top: 50%; -webkit-transform: translateY(-50%); -moz-transform: translateY(-50%); -ms-transform: translateY(-50%); -o-transform: translateY(-50%); transform: translateY(-50%);}
    .youth .chain-search-mobile > span {font-size: 1.2rem; color: var(--gray500); line-height: 1.4; word-break: keep-all;}
    }

.youth .chain-map-wrap .chain-view {position: absolute; background-color: var(--white01); width: 32rem; border-radius: .8rem; box-shadow: .4rem .4rem 1.2rem rgba(70, 81, 109, .16);}
.youth .chain-map-wrap .chain-view .title {display: flex; align-items: center; justify-content: center; padding: 2rem; border-bottom: 1px solid var(--gray200); position: relative;}
.youth .chain-map-wrap .chain-view .title > h4 {font-size: 2rem; font-weight: 600;}
.youth .chain-map-wrap .chain-view .title > i {position: absolute; right: 2rem; top: 50%; -webkit-transform: translateY(-50%); -moz-transform: translateY(-50%); -ms-transform: translateY(-50%); -o-transform: translateY(-50%); transform: translateY(-50%); cursor: pointer;}
.youth .chain-map-wrap .chain-view .cont {padding: 2rem;}
.youth .chain-map-wrap .chain-view .cont > ul {display: flex; flex-flow: column; row-gap: 2rem;}
.youth .chain-map-wrap .chain-view .cont > ul > li {display: flex; flex-flow: column; row-gap: .8rem; word-break: break-all; white-space: normal;}
.youth .chain-map-wrap .chain-view .cont > ul > li > h6 {font-size: 1.6rem; font-weight: 600;}
.youth .chain-map-wrap .chain-view .cont > ul > li > p {font-size: 1.4rem; line-height: 1.4;}
.youth .chain-map-wrap .chain-view .cont > button {margin-top: 2rem; height: 5.2rem; font-size: 1.6rem; width: 100%;}

@media screen and (max-width: 1024px) {
    .youth .chain-map-wrap .chain-view {width: 26rem;}
    .youth .chain-map-wrap .chain-view .title {padding: 1.6rem;}
    .youth .chain-map-wrap .chain-view .title > h4 {font-size: 1.6rem;}
    .youth .chain-map-wrap .chain-view .title > i {right: 1.6rem;}
    .youth .chain-map-wrap .chain-view .cont {padding: 1.6rem;}
    .youth .chain-map-wrap .chain-view .cont > ul {flex-flow: row; flex-wrap: wrap; grid-gap: 1.6rem;}
    .youth .chain-map-wrap .chain-view .cont > ul > li:nth-child(1) {width: 100%;}
    .youth .chain-map-wrap .chain-view .cont > ul > li:not(:nth-child(1)) {width: calc((100% - 1.6rem) / 2);}
    .youth .chain-map-wrap .chain-view .cont > ul > li > h6 {font-size: 1.4rem;}
    .youth .chain-map-wrap .chain-view .cont > ul > li > p {font-size: 1.2rem;}
    .youth .chain-map-wrap .chain-view .cont > button {margin-top: 1.6rem; height: 4.8rem;}
}

.youth .main-movieclip-con::before {background-color: var(--gray100); background-image: url(/imgs/movieclip-con-pattern-youth.png);}
.youth .main-movieclip-con .ic-movieclip-24 {filter: invert(1);}
.youth .main-movieclip-con .con-tit h4 {color: var(--black);}
.youth .movieclip-swiper01 .movieclip-con .swiper-slide .txt h6 {color: var(--black);}

@media screen and (max-width: 820px) {
    .youth .main-movieclip-con::before {background-image: url(/imgs/movieclip-con-pattern-youth-mb.png);}
}

.youth .main-hot-photo-swiper01 .swiper-slide.swiper-slide-active .img::before {background-image: url(/imgs/photo-con-pattern.svg), linear-gradient(90deg, rgba(1, 18, 65, 0) 30%, #011241 92.62%);}
.youth .main-hot-photo-swiper01 .swiper-slide .txt .tag {border-color: var(--yr500); color: var(--yr500);}
.youth .main-hot-photo-swiper01 .swiper-slide .txt span:not(.tag) {color: var(--pb200);}

/* 유소년 소개 */
.youth .youth-box + .youth-box {margin-top: 8rem;}
.youth .youth-box .youth-tit {margin-bottom: 2.4rem; display: flex; align-items: center; justify-content: space-between;}
.youth .youth-box .youth-tit > h4 {font-size: 2.4rem; font-weight: 600;}
.youth .youth-box .youth-tit > h5 {font-size: 2rem; font-weight: 600; margin-bottom: -.8rem;}
.youth .youth-box .youth-tit + p,
.youth .con-box .con-tit + p {font-size: 1.6rem; line-height: 1.6; margin-bottom: 2.4rem; word-break: keep-all;}
.youth .youth-box .youth-guide {margin-top: 1.6rem;}
.youth .youth-box .youth-guide .title > h6 {margin-top: 1.6rem; font-size: 1.6rem; font-weight: 500; margin-bottom: 1.6rem; color: var(--gray600);}
.youth .youth-box .youth-guide .desc > p {font-size: 1.6rem; line-height: 1.6; margin-bottom: 2.4rem; color: var(--gray500); white-space: pre-wrap;}
.youth .youth-box .youth-guide .desc table td {white-space: pre-wrap;}

@media screen and (max-width: 820px) {
    .youth .youth-box + .youth-box {margin-top: 4rem;}
    .youth .youth-box .youth-tit {margin-bottom: 1.6rem; flex-flow: column; align-items: initial; row-gap: 1.6rem;}
    .youth .youth-box .youth-tit > h4 {font-size: 2rem;}
    .youth .youth-box .youth-tit > h5 {font-size: 1.6rem; margin-bottom: -.4rem;}
    .youth .youth-box .youth-tit + p,
    .youth .con-box .con-tit + p  {font-size: 1.4rem; margin-bottom: 1.6rem;}
    .youth .youth-box .youth-guide .title > h6 {font-size: 1.4rem;}
    .youth .youth-box .youth-guide .desc > p {font-size: 1.4rem;}
}

.youth .youth-player-swiper .swiper-slide .img {height: 16.8rem; border-radius: .8rem; background-repeat: no-repeat; background-size: cover, 100% auto; background-position: center top 1rem, top;}
.youth .youth-player-swiper .swiper-slide .info {margin-top: 1.6rem; display: flex; align-items: center; justify-content: space-between;}
.youth .youth-player-swiper .swiper-slide .info > h6 {font-size: 2rem; font-weight: 600;}
.youth .youth-player-swiper .swiper-slide .info > span {font-size: 1.4rem; color: var(--gray500);}

.youth .youth-player-swiper-wrap {position: relative;}
.youth .youth-player-swiper-wrap .youth-player-swiper-next {width: 4rem; height: 4rem; border-radius: 100%; background-image: url(/imgs/ico/ic-arrow-right-40.svg); z-index: 1; position: absolute; top: 6.4rem; right: -2rem;}
.youth .img-youth-value {width: 100%;}

@media screen and (max-width: 1024px) {
    .youth .youth-player-swiper .swiper-slide .img {height: 15vw;}
    .youth .youth-player-swiper-wrap .youth-player-swiper-next {top: calc(7.5vw - 20rem)}
}
@media screen and (max-width: 820px) {
    .youth .youth-player-swiper {margin: 0 -1.6rem; padding: 0 1.6rem;}
    .youth .youth-player-swiper .swiper-slide {width: 15.2rem;}
    .youth .youth-player-swiper .swiper-slide .img {height: 11.4rem;}
    .youth .youth-player-swiper .swiper-slide .info {margin-top: 1.2rem;}
    .youth .youth-player-swiper .swiper-slide .info > h6 {font-size: 1.6rem;}
    .youth .youth-player-swiper .swiper-slide .info > span {font-size: 1.2rem;}

    .youth .youth-player-swiper-wrap .youth-player-swiper-next {width: 3.6rem; height: 3.6rem; top: 4rem; right: 0;}
}

.youth .youth-core-value {display: grid; grid-template-columns: repeat(4, 1fr); border-radius: 1.2rem; border: 1px solid var(--gray200);}
.youth .youth-core-value > li {height: 24rem; display: flex; flex-flow: column; row-gap: .8rem; align-items: center; justify-content: center;}
.youth .youth-core-value > li + li {border-left: 1px solid var(--gray200);}
.youth .youth-core-value > li > h6 {margin-top: .6rem; font-size: 2rem; font-weight: 600;}
.youth .youth-core-value > li > p {font-size: 1.6rem; font-weight: 500;}

@media screen and (max-width: 1024px) {
    .youth .youth-core-value > li {height: 20vw;}
}
@media screen and (max-width: 820px) {
    .youth .youth-core-value {grid-template-columns: repeat(2, 1fr);}
    .youth .youth-core-value > li {height: 14.8rem; row-gap: .6rem;}
    .youth .youth-core-value > li:nth-of-type(2n) {border-left: 1px solid var(--gray200);}
    .youth .youth-core-value > li:nth-of-type(n+3) {border-top: 1px solid var(--gray200);}
    .youth .youth-core-value > li > h6 {margin-top: .6rem; font-size: 1.6rem;}
    .youth .youth-core-value > li > p {font-size: 1.2rem;}
}

.youth .youth-oper-status {position: relative; height: 36rem; margin-bottom: -8rem; display: flex; align-items: center; justify-content: center;}
.youth .youth-oper-status > * {position: relative; z-index: 1;}
.youth .youth-oper-status::before {content: ''; width: 100vw; height: 36rem; background-image: url(/imgs/bg-youth-now.png); background-repeat: no-repeat; background-size: cover; background-position: center; position: absolute; left: 50%; -webkit-transform: translateX(-50%); -moz-transform: translateX(-50%); -ms-transform: translateX(-50%); -o-transform: translateX(-50%); transform: translateX(-50%); z-index: 0;}
.youth .youth-oper-status > ul {display: grid; grid-template-columns: repeat(4, 1fr); width: 100%;}
.youth .youth-oper-status > ul > li {display: flex; flex-flow: column; align-items: center; justify-content: center; row-gap: .8rem;}
.youth .youth-oper-status > ul > li > h6 {margin-top: 1.2rem; font-size: 4.8rem; font-weight: 600; color: var(--white01);}
.youth .youth-oper-status > ul > li > p {font-size: 2rem; font-weight: 600; color: var(--white01);}

@media screen and (max-width: 1024px) {
    .youth .youth-oper-status {height: 30vw; margin-bottom: -6rem;}
    .youth .youth-oper-status::before {height: 30vw;}
}
@media screen and (max-width: 820px) {
    .youth .youth-oper-status {height: 32.8rem; margin-bottom: -4rem;}
    .youth .youth-oper-status::before {height: 32.8rem;}
    .youth .youth-oper-status > ul {grid-template-columns: repeat(2, 1fr);}
    .youth .youth-oper-status > ul > li {row-gap: .6rem; height: 14.4rem;}
    .youth .youth-oper-status > ul > li > i {width: 4rem; height: 4rem;}
    .youth .youth-oper-status > ul > li > h6 {margin-top: .6rem; font-size: 3.2rem;}
    .youth .youth-oper-status > ul > li > p {font-size: 1.4rem;}
}

/* 유소년 > 클럽 소개 > 지점 안내 */
.youth .youth-branch-info {border-top: 1px solid var(--black); border-bottom: 1px solid var(--gray200); padding: 3.2rem 0;}
.youth .youth-branch-info > li {display: flex; align-items: start; column-gap: 2rem;}
.youth .youth-branch-info > li + li {margin-top: .6rem;}
.youth .youth-branch-info > li > h6 {font-size: 1.6rem; font-weight: 500; width: 12rem; line-height: 1.6;}
.youth .youth-branch-info > li > p {width: calc(100% - 6rem - 2rem); font-size: 1.6rem; color: var(--gray500); line-height: 1.6;}

.youth .youth-branch-teacher {display: flex; flex-wrap: wrap; column-gap: 8rem; padding: 8rem; background-color: var(--gray100); border-radius: 1.2rem;}
.youth .youth-branch-teacher > li {display: flex; flex-flow: column; row-gap: 1rem;}
.youth .youth-branch-teacher > li .img {width: 20rem; height: 20rem; border-radius: .8rem; background-repeat: no-repeat; background-size: cover; background-position: center;}
.youth .youth-branch-teacher > li .info {display: flex; align-items: center; justify-content: space-between;}
.youth .youth-branch-teacher > li .info > h6 {font-size: 1.6rem; font-weight: 500;}
.youth .youth-branch-teacher .teacher-info {font-size: 1.6rem; line-height: 1.6; color: var(--gray600);}

@media screen and (max-width: 1024px) {
    .youth .youth-branch-teacher {column-gap: 1rem; padding: 2rem; background-color: var(--gray100);}
}
@media screen and (max-width: 820px) {
    .youth .youth-branch-info {padding: 2rem 0;}
    .youth .youth-branch-info > li {column-gap: 1.6rem;}
    .youth .youth-branch-info > li + li {margin-top: 1.2rem;}
    .youth .youth-branch-info > li > h6 {font-size: 1.4rem; width: 10rem;}
    .youth .youth-branch-info > li > p {width: calc(100% - 5rem - 1.6rem); font-size: 1.4rem;}
    
    .youth .youth-branch-teacher {display: grid; grid-template-columns: repeat(2, 1fr); grid-gap: 2rem 1rem; border-radius: .8rem;}
    .youth .youth-branch-teacher > li .img {width: 100%; height: 39vw; border-radius: .4rem;}
    .youth .youth-branch-teacher > li .info > h6 {font-size: 1.4rem;}
    .youth .youth-branch-teacher .teacher-info {font-size: 1.4rem;}
}

/* 유소년 > 클럽 소개 > 구단 클럽 운영 현황 */
.youth .club-info-wrap {display: flex; column-gap: 2rem;}
.youth .club-info-wrap .club-emblem {width: 22.4rem; border: 1px solid var(--gray200); border-radius: .8rem; display: flex; align-items: center; justify-content: center;}
.youth .club-info-wrap .club-emblem > i {width: 16rem; height: 10.7rem;}
.youth .club-info-wrap > div:not(.club-emblem) {width: calc(100% - 22.4rem - 2rem); height: 100%;}
.youth .club-info-wrap > div:not(.club-emblem) table td {text-align: left;}

@media screen and (max-width: 820px) {
    .youth .club-info-wrap {flex-flow: column; row-gap: 1.6rem;}
    .youth .club-info-wrap .club-emblem {width: 100%; height: 13.4rem;}
    .youth .club-info-wrap .club-emblem > i {width: 14rem; height: 94rem;}
    .youth .club-info-wrap > div:not(.club-emblem) {width: 100%;}
    .youth .club-info-wrap > div:not(.club-emblem) table td {text-align: left;}
}

/* 유소년 > 대회 소개 */
.youth .youth-img {border-radius: 1.2rem; overflow: hidden;}
.youth .youth-img img {width: 100%;}
.youth .youth-img + .con-box {margin-top: 8rem;}

.youth .youth-game-highlight-photo {display: grid; grid-template-columns: repeat(2, 1fr); grid-gap: 2rem;}
.youth .youth-game-highlight-photo > li {display: flex; flex-flow: column; row-gap: 1.6rem;}
.youth .youth-game-highlight-photo > li .img {height: 33.2rem; border-radius: 1.2rem; background-repeat: no-repeat; background-position: center; background-size: cover;}
.youth .youth-game-highlight-photo > li .info > h6 {font-size: 2rem; font-weight: 500; line-height: 1.6; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;}
.youth .youth-game-highlight-photo > li .info > ul {margin-top: 1.6rem;}

.youth .youth-game-photo {display: grid; grid-template-columns: repeat(4, 1fr); grid-gap: 1.2rem 2rem;}
.youth .youth-game-photo > li {height: 22.8rem; border: 1px solid var(--gray200); border-radius: .8rem; background-repeat: no-repeat; background-size: cover; background-position: center;}

@media screen and (max-width: 1024px) {
    .youth .youth-img + .con-box {margin-top: 6rem;}

    .youth .youth-game-highlight-photo > li .img {height: 26vw; border-radius: .8rem;}
    .youth .youth-game-photo > li {height: 17vw;}
    
}
@media screen and (max-width: 820px) {
    .youth .youth-img {border-radius: .8rem;}
    .youth .youth-img + .con-box {margin-top: 4rem;}
    
    .youth .youth-game-highlight-photo {grid-template-columns: repeat(1, 1fr);}
    .youth .youth-game-highlight-photo > li {row-gap: 1.2rem;}
    .youth .youth-game-highlight-photo > li .img {height: 52vw;}
    .youth .youth-game-highlight-photo > li .info > h6 {font-size: 1.4rem;}
    .youth .youth-game-highlight-photo > li .info > ul {margin-top: 1rem;}
    
    .youth .youth-game-photo {grid-template-columns: repeat(2, 1fr); grid-gap: 1.2rem;}
    .youth .youth-game-photo > li {height: 38vw;}
}

.youth .club-game-img {display: grid; grid-template-columns: repeat(2, 1fr); column-gap: 2rem; margin-bottom: 6rem;}
.youth .club-game-img > li {height: 33.2rem; border-radius: 1.2rem; background-repeat: no-repeat; background-size: cover; background-position: center;}
.youth .club-game-img-banner {display: grid; grid-template-columns: repeat(1, 1fr); column-gap: 2rem; margin-bottom: 6rem;}
.youth .club-game-img-banner > li {height: 33.2rem; border-radius: 1.2rem; background-repeat: no-repeat; background-size: cover; background-position: center;}

.youth .youth-grid-2 {display: grid; grid-template-columns: repeat(2, 1fr); grid-gap: 4rem 1.6rem;}
.youth .youth-grid-2 > div > h6 {font-size: 1.8rem; font-weight: 600; margin-bottom: 1.6rem;}
.youth .youth-grid-3 {display: grid; grid-template-columns: repeat(3, 1fr); grid-gap: 4rem 1.6rem;}
.youth .youth-grid-3 > div > h6 {font-size: 1.8rem; font-weight: 600; margin-bottom: 1.6rem;}

@media screen and (max-width: 1024px) {
    .youth .youth-grid-3 {grid-template-columns: repeat(2, 1fr);}
    
}
@media screen and (max-width: 820px) {
    .youth .club-game-img {grid-template-columns: repeat(1, 1fr); row-gap: 1.2rem; margin-bottom: 4rem;}
    .youth .club-game-img > li {height: 42vw; border-radius: .8rem;}
    .youth .club-game-img-banner {grid-template-columns: repeat(1, 1fr); row-gap: 1.2rem; margin-bottom: 4rem;}
    .youth .club-game-img-banner > li {height: 42vw; border-radius: .8rem;}

    .youth .youth-grid-2 {grid-template-columns: repeat(1, 1fr); grid-gap: 2rem;}
    .youth .youth-grid-2 > div > h6 {font-size: 1.6rem; margin-bottom: 1.2rem;}
    .youth .youth-grid-3 {grid-template-columns: repeat(1, 1fr); grid-gap: 2rem;}
    .youth .youth-grid-3 > div > h6 {font-size: 1.6rem; margin-bottom: 1.2rem;}
}

.youth .youth-player-list {border-top: 1px solid var(--black);}
.youth .youth-player-list > li {padding: 4rem 0; display: flex; align-items: center; column-gap: 2rem;}
.youth .youth-player-list > li + li {border-top: 1px solid var(--gray200);}
.youth .youth-player-list > li .team {width: calc(100% - 76rem - 2rem); display: flex; align-items: center; column-gap: .8rem;}
.youth .youth-player-list > li .team > i {width: 12rem; height: 8rem;}
.youth .youth-player-list > li .team > div > h4 {font-size: 2.2rem; font-weight: 600;}
.youth .youth-player-list > li .team > div > p {margin-top: 1.2rem; font-size: 1.4rem; font-weight: 500; color: var(--gray500);}
.youth .youth-player-list > li .player {width: 76rem; display: grid; grid-template-columns: repeat(5, 1fr); grid-gap: .8rem;}
.youth .youth-player-list > li .player > li {cursor: pointer; height: 4.8rem; display: flex; align-items: center; justify-content: center; border-radius: .4rem; border: 1px solid var(--gray200); color: var(--gray500); font-size: 1.6rem; font-weight: 500;}

.youth .youth-player-list > li .list {display: flex; white-space: pre;}
.youth .youth-player-list > li .list > h4 {font-size: 1.6rem; font-weight: 600; color: var(--gray800); margin-top: 1.6rem;}

@media screen and (max-width: 1024px) {
    .youth .youth-player-list > li {padding: 2rem 0; column-gap: 1.2rem;}
    .youth .youth-player-list > li .team {width: calc(100% - 50rem - 1.2rem);}
    .youth .youth-player-list > li .team > div > h4 {line-height: 1.2; word-break: keep-all;}
    .youth .youth-player-list > li .player {width: 50rem; grid-template-columns: repeat(4, 1fr);}
    .youth .youth-player-list > li .player > li {cursor: pointer; height: 4.8rem; display: flex; align-items: center; justify-content: center; border-radius: .4rem; border: 1px solid var(--gray200); color: var(--gray500); font-size: 1.6rem; font-weight: 500;}
}
@media screen and (max-width: 820px) {
    .youth .tab-style01 {flex-wrap: wrap; position: relative;}
    .youth .tab-style01::after {content: none;}
    /* .youth .tab-style01::after {content: ''; width: 100%; height: 1px; background-color: var(--gray200); position: absolute;  top: 50%; -webkit-transform: translateY(-50%); -moz-transform: translateY(-50%); -ms-transform: translateY(-50%); -o-transform: translateY(-50%); transform: translateY(-50%); z-index: 1;} */
    .youth .tab-style01 > li {position: relative; z-index: 2;}
    .youth .tab-style01 > li.active {border-radius: .4rem;}
    .youth .tab-style01 > li:nth-child(1):nth-last-child(6), .tab-style01 > li:nth-child(1):nth-last-child(6) ~ li {width: calc(100% / 3);}
    .youth .tab-style01 > li:nth-child(1):nth-last-child(6) ~ li:nth-child(4)::before {content: none;}

    .youth .youth-player-list > li {flex-flow: column; row-gap: 2rem;}
    .youth .youth-player-list > li .team {width: 100%; column-gap: .4rem;}
    .youth .youth-player-list > li .team > i {width: 6rem; height: 4rem;}
    .youth .youth-player-list > li .team > div > h4 {font-size: 1.6rem;}
    .youth .youth-player-list > li .team > div > p {font-size: 1.2rem;}
    .youth .youth-player-list > li .player {width: 100%; grid-template-columns: repeat(4, 1fr); grid-gap: 1.2rem .8rem;}
    .youth .youth-player-list > li .player > li {height: 4.4rem; font-size: 1.4rem;}

    .youth .youth-player-list > li .list {flex-wrap: wrap;}
    .youth .youth-player-list > li .list > h4 {font-size: 1.4rem; font-weight: 600; color: var(--gray800); margin-bottom: 1.2rem; margin-top: -0.6rem;}
    .youth .youth-player-list > li .list-ul {width: 100%; display: grid; grid-template-columns: repeat(1, 1fr); grid-gap: .8rem;}
}

.youth .summary-wrap .quick-link {display: grid; grid-template-columns: repeat(2, 1fr); column-gap: 1px; border-radius: .4rem; width: 17rem; overflow: hidden;}
.youth .summary-wrap .quick-link > li {display: flex; align-items: center; justify-content: center; height: 3.2rem; font-size: 1.4rem; font-weight: 500; color: #fff; background-color: var(--pb400); cursor: pointer;}
.youth .game-record-summary + .con-box {margin-top: 6rem;}

.youth .youth-game-record-box {border-radius: .8rem; border: 1px solid var(--gray200); padding: 3.2rem 2.4rem; display: flex; align-items: center; justify-content: space-between;}
.youth .youth-game-record-box .name {display: flex; flex-flow: column; row-gap: .6rem;}
.youth .youth-game-record-box .name > h6 {font-size: 1.6rem; font-weight: 600;}
.youth .youth-game-record-box .name > p {font-size: 1.4rem; color: var(--gray500);}
.youth .youth-game-record-box .score {display: flex; align-items: center; column-gap: .8rem;}
.youth .youth-game-record-box .score > p {font-size: 3rem; display: flex; align-items: end; font-weight: 600; color: var(--yr500); column-gap: .4rem;}
.youth .youth-game-record-box .score > p > span {font-size: 1.6rem; font-weight: initial; line-height: 1.4;}

@media screen and (max-width: 820px) {
    .youth .summary-wrap .quick-link {width: 12.8rem;}
    .youth .summary-wrap .quick-link > li {height: 2.8rem; font-size: 1.2rem;}

    .youth .youth-game-record-box {padding: 2rem;}
}


.youth .youth-view-wrap {position: relative; border-radius: 1.2rem; display: grid; grid-template-columns: 1fr 4fr; align-items: center; overflow: hidden; margin-bottom: 6rem;}
.youth .youth-view-wrap .img {background-color: var(--pb800); height: 100%; display: flex; align-items: center; justify-content: center;}
.youth .youth-view-wrap .img > i {width: 16rem; height: 10.6rem;}
.youth .youth-view-wrap .img > img {width: 18rem; height:18rem; border-radius: 10%; overflow:hidden}
.youth .youth-view-wrap .txt {width: 100%; padding: 4rem; display: flex; flex-flow: column; row-gap: 2.4rem; background-color: var(--pb900); background-image: url(/imgs/player-rank-con-pattern.svg); background-repeat: no-repeat; background-size: cover; background-position: center;}
.youth .youth-view-wrap .txt > h6 {font-size: 2.4rem; font-weight: 600; color: #fff;}
.youth .youth-view-wrap .txt > ul {display: flex; grid-gap: 1rem 9rem; flex-wrap: wrap;}
.youth .youth-view-wrap .txt > ul > li {width: calc((100% - 9rem) / 2); display: flex; align-items: center; justify-content: space-between; font-size: 1.4rem;}
.youth .youth-view-wrap .txt > ul > li h6 {color: var(--pb200);}
.youth .youth-view-wrap .txt > ul > li p {color: #fff; font-weight: 500;}
.youth .youth-view-wrap .txt > ul > li h5 {font-size: 2.4rem; font-weight: 600; color: #fff;}

.youth table tr.point td {background-color: var(--yr50);}
@media (prefers-color-scheme: dark) {
    .youth table tr.point td {background-color: #000000;}
}
[data-theme="dark"] .youth table tr.point td {background-color: #000000;}

@media screen and (max-width: 820px) {
    .youth .youth-view-wrap {border-radius: .8rem; grid-template-columns: 1fr; margin-bottom: 4rem;}
    .youth .youth-view-wrap .img {height: 16rem;}
    .youth .youth-view-wrap .img > i {width: 12rem; height: 8rem;}
    .youth .youth-view-wrap .img > img {width: 14rem; height:14rem; border-radius: 10%; overflow:hidden}
    .youth .youth-view-wrap .txt {padding: 2rem; row-gap: 1.6rem;}
    .youth .youth-view-wrap .txt > h6 {font-size: 1.6rem;}
    .youth .youth-view-wrap .txt > ul {grid-gap: .8rem; flex-flow: column;}
    .youth .youth-view-wrap .txt > ul > li {width: 100%; font-size: 1rem;}
}

.youth .youth-game-btn {border: 1px solid var(--gray200); border-radius: .4rem; margin-top: .8rem; display: grid; grid-template-columns: repeat(2, 1fr); width: 17.2rem;}
.youth .youth-game-btn > li {cursor: pointer; font-size: 1.6rem; display: flex; align-items: center; justify-content: center; color: var(--gray500); height: 4.8rem; font-weight: 600; background-color: var(--white01);}
.youth .youth-game-btn > li + li {border-left: 1px solid var(--gray200);}

@media screen and (max-width: 820px) {
    .youth .youth-game-btn {width: 100%;}
    .youth .youth-game-btn > li {height: 4.4rem; font-size: 1.4rem;}
}

.youth .youth-match-tit {display: flex; align-items: center; justify-content: space-between; padding: 4rem 0; border-top: 1px solid var(--black); border-bottom: 1px solid var(--gray200);}
.youth .youth-match-tit .tit {display: flex; flex-flow: column; row-gap: 1.2rem;}
.youth .youth-match-tit .tit > h4 {font-size: 2.4rem; font-weight: 600; line-height: 1.2;}
.youth .youth-match-tit .tit > p {font-size: 1.4rem; font-weight: 500; color: var(--gray500);}
.youth .youth-match-tit .btn {display: flex; align-items: center; column-gap: .8rem;}
.youth .youth-match-tit .btn > button {width: 15.6rem;}

@media screen and (max-width: 820px) {
    .youth .youth-match-tit {flex-flow: column; row-gap: 2rem; justify-content: center; padding: 2rem 0;}
    .youth .youth-match-tit .tit > h4 {font-size: 1.6rem;}
    .youth .youth-match-tit .tit > p {font-size: 1.2rem;}
    .youth .youth-match-tit .btn {width: 100%;}
    .youth .youth-match-tit .btn > button {width: calc((100% - .8rem) / 2); height: 4.4rem;}
}

.youth .youth-match-wrap + .youth-match-wrap {margin-top: 6rem;}

.youth .youth-match-con {width: 100%; padding: 6rem 0; display: grid; grid-template-columns: repeat(7, 1fr); grid-template-rows: repeat(4, 21.4rem);}
.youth .youth-match-con .round {position: relative;}
.youth .youth-match-con .round .team {border: 1px solid var(--gray200); border-radius: .8rem; overflow: hidden;}
.youth .youth-match-con .round .team .img {display: flex; align-items: center; justify-content: center; height: 16.5rem;}
.youth .youth-match-con .round .team .img > i {width: 12rem; height: 8rem;}
.youth .youth-match-con .round .team > h6 {padding: 1.6rem; font-size: 1.6rem; font-weight: 500; display: flex; align-items: center; justify-content: center; width: 100%; border-top: 1px solid var(--gray200); color: #FFFFFF; background-color: var(--pb900);}
.youth .youth-match-con .round .info {display: flex; flex-flow: column; align-items: center; justify-content: center; row-gap: 1.6rem;}
.youth .youth-match-con .round .info .score {display: flex; align-items: center; justify-content: center; column-gap: .8rem;}
.youth .youth-match-con .round .info .score > li {font-size: 2.4rem; font-weight: 600; color: var(--gray400); display: flex; align-items: center; column-gap: .8rem;}
.youth .youth-match-con .round .info .score > li.win {color: var(--yr500);}
.youth .youth-match-con .round .info .score > li + li::before {content: ':'; color: var(--gray400);}
.youth .youth-match-con .round .info .more {border: 1px solid var(--gray200); border-radius: .4rem; display: flex; align-items: center; justify-content: center; width: 100%; z-index: 9;}
.youth .youth-match-con .round .info .more > li {display: flex; align-items: center; justify-content: center; width: calc(100% / 3); height: 4.4rem; font-size: 1.4rem; font-weight: 600; cursor: pointer;}
.youth .youth-match-con .round .info .more > li + li {border-left: 1px solid var(--gray200);}

.youth .youth-match-con .round.lose .team {background-color: var(--gray100);}

.youth .youth-match-con .round.final {grid-row: 1; grid-column: 4;}
.youth .youth-match-con .round.final .team {background-color: var(--yr50); border-color: var(--yr500);}
.youth .youth-match-con .round.final .team > h6 {color: #FFFFFF; background-color: var(--yr500);}
.youth .youth-match-con .round.final .info {margin-top: 1.6rem;}

.youth .youth-match-con .round.semifinal .info {margin-top: 8.6rem;}
.youth .youth-match-con .round.semifinal.team01 {grid-row: 2; grid-column: 2;}
.youth .youth-match-con .round.semifinal.team02 {grid-row: 2; grid-column: 6;}

.youth .youth-match-con .round.semifinal .line {width: 150%; height: 50%; position: absolute; top: -50%; left: 50%;}
.youth .youth-match-con .round.semifinal .line::before {content: ''; width: 1px; height: 100%; background-color: var(--gray200); position: absolute; top: 0; left: 0;}
.youth .youth-match-con .round.semifinal .line::after {content: ''; width: 100%; height: 1px; background-color: var(--gray200); position: absolute; top: 0; left: 0;}
.youth .youth-match-con .round.semifinal.team02 .line {left: initial; right: 50%;}
.youth .youth-match-con .round.semifinal.team02 .line::before {left: initial; right: 0;}
.youth .youth-match-con .round.semifinal.win .line::before,
.youth .youth-match-con .round.semifinal.win .line::after {background-color: var(--yr500);}

.youth .youth-match-con .round.match.team01 {grid-row: 4; grid-column: 1;}
.youth .youth-match-con .round.match.team02 {grid-row: 4; grid-column: 3;}
.youth .youth-match-con .round.match.team03 {grid-row: 4; grid-column: 5;}
.youth .youth-match-con .round.match.team04 {grid-row: 4; grid-column: 7;}

.youth .youth-match-con .round.match .line {width: 100%; height: 100%; position: absolute; top: -99.5%; left: 50%;}
.youth .youth-match-con .round.match .line::before {content: ''; width: 1px; height: 66.6%; background-color: var(--gray200); position: absolute; bottom: 0; left: 0;}
.youth .youth-match-con .round.match .line::after {content: ''; width: 100%; height: 1px; background-color: var(--gray200); position: absolute; bottom: 66.6%; left: 0;}
.youth .youth-match-con .round.match .line span {display: block; width: 1px; height: 33.3%; background-color: var(--gray200); position: absolute; top: 0; right: -.5px;}

.youth .youth-match-con .round.match.team02 .line,
.youth .youth-match-con .round.match.team04 .line {left: initial; right: 50%;}
.youth .youth-match-con .round.match.team02 .line::before,
.youth .youth-match-con .round.match.team04 .line::before {left: initial; right: 0;}
.youth .youth-match-con .round.match.team02 .line span,
.youth .youth-match-con .round.match.team04 .line span {right: initial; left: -.5px;}
.youth .youth-match-con .round.match.win .line::before,
.youth .youth-match-con .round.match.win .line::after,
.youth .youth-match-con .round.match.win .line span {background-color: var(--yr500); z-index: 1;}


.youth .youth-match-con-wrap {width: 100%; overflow-x: auto;}
.youth .youth-match-con-add {width: 100%; min-width: 120rem; display: grid;}

.youth .youth-match-con-add .round {position: relative;}
.youth .youth-match-con-add .round .team {border: 1px solid var(--gray200); border-radius: .8rem; overflow: hidden; height: 100%; position: relative; z-index: 2; background-color: var(--white01);}
.youth .youth-match-con-add .round .team .img {display: flex; align-items: center; justify-content: center; height: calc(100% - 4rem);}
.youth .youth-match-con-add .round .team .img > img,
.youth .youth-match-con-add .round .team .img > i {width: 7.2rem; height: 4.8rem;}
.youth .youth-match-con-add .round .team > h6 {height: 4rem; font-size: 1.4rem; font-weight: 500; display: flex; align-items: center; justify-content: center; width: 100%; border-top: 1px solid var(--gray200);}
.youth .youth-match-con-add .round .info {display: flex; flex-flow: column; align-items: center; justify-content: center; row-gap: 1.6rem; margin-top: 1.6rem;}
.youth .youth-match-con-add .round .info .score {display: flex; align-items: center; justify-content: center; column-gap: .8rem;}
.youth .youth-match-con-add .round .info .score > li {font-size: 2.4rem; font-weight: 600; color: var(--gray400); display: flex; align-items: center; column-gap: .8rem;}
.youth .youth-match-con-add .round .info .score > li > p {font-size: 1.8rem; font-weight: 500; color: var(--black); text-align: center;}
.youth .youth-match-con-add .round .info .score > li.win {color: var(--yr500);}
.youth .youth-match-con-add .round .info .score > li + li::before {content: ':'; color: var(--gray400);}
.youth .youth-match-con-add .round .info .more {width: 17.2rem; border: 1px solid var(--gray200); border-radius: .4rem; display: flex; align-items: center; justify-content: center; z-index: 9;}
.youth .youth-match-con-add .round .info .more > li {display: flex; align-items: center; justify-content: center; width: calc(100% / 3); height: 4.4rem; font-size: 1.4rem; font-weight: 600; cursor: pointer;}
.youth .youth-match-con-add .round .info .more > li + li {border-left: 1px solid var(--gray200);}
.youth .youth-match-con-add .round .info > div {display: flex; flex-flow: column; row-gap: .8rem; align-items: center; justify-content: center;}
.youth .youth-match-con-add .round .info > div > p {font-size: 1.4rem; font-weight: 500;}
.youth .youth-match-con-add .round .info > div > span {font-size: 1.4rem; font-weight: 500; color: var(--gray500);}

.youth .youth-match-con-add .round .info > button {height: 4rem; padding: 0 1.2rem; font-size: 1.4rem; color: var(--gray500); background-color: var(--white01); border: 1px solid var(--gray200);}

.youth .youth-match-con-add .round .line {position: absolute; left: 50%; z-index: 1;}
.youth .youth-match-con-add .round .line::before {content: ''; width: 1px; height: 100%; background-color: var(--gray200); position: absolute; top: 0; left: 0;}
.youth .youth-match-con-add .round .line::after {content: ''; width: 100%; height: 1px; background-color: var(--gray200); position: absolute; top: 0; left: 0;}
.youth .youth-match-con-add .round.win .line::before,
.youth .youth-match-con-add .round.win .line::after {background-color: var(--yr500);}

.youth .youth-match-con-add .round.lose .team {background-color: var(--gray100);}

.youth .youth-match-con-add .round.final .team {background-color: var(--yr50); border-color: var(--yr500);}
.youth .youth-match-con-add .round.final .team > h6 {color: var(--yr500); border-color: var(--yr500);}
.youth .youth-match-con-add .round.final .team .img > img,
.youth .youth-match-con-add .round.final .team .img > i {width: 10rem; height: 6.6rem;}

.youth .youth-match-con-add .round.semifinal .team .img > img,
.youth .youth-match-con-add .round.semifinal .team .img > i {width: 10rem; height: 6.6rem;}

.youth .youth-match-con-add.type01 {grid-template-columns: repeat(58, 1fr); grid-template-rows: repeat(16, 7.1rem);}

.youth .youth-match-con-add.type01 .round.final {grid-row: 1 / span 2; grid-column: 27 / span 6;}

.youth .youth-match-con-add.type01 .round.semifinal.team01 {grid-row: 3 / span 2; grid-column: 11 / span 6;}
.youth .youth-match-con-add.type01 .round.semifinal.team02 {grid-row: 3 / span 2; grid-column: 43 / span 6;}

.youth .youth-match-con-add.type01 .round.semifinal .line {width: 217%; height: 50%; top: -50%;}
.youth .youth-match-con-add.type01 .round.semifinal.team02 .line {left: initial; right: 50%;}
.youth .youth-match-con-add.type01 .round.semifinal.team02 .line::before {left: initial; right: 0;}

.youth .youth-match-con-add.type01 .round.quarter.team01 {grid-row: 6 / span 2; grid-column: 5 / span 4;}
.youth .youth-match-con-add.type01 .round.quarter.team02 {grid-row: 9 / span 2; grid-column: 22 / span 4;}
.youth .youth-match-con-add.type01 .round.quarter.team03 {grid-row: 9 / span 2; grid-column: 34 / span 4;}
.youth .youth-match-con-add.type01 .round.quarter.team04 {grid-row: 6 / span 2; grid-column: 51 / span 4;}

.youth .youth-match-con-add.type01 .round.quarter.team01 .line {width: 100%; height: 100%; top: -100%;}
.youth .youth-match-con-add.type01 .round.quarter.team02 .line {width: 175%; height: 250%; top: -250%; left: initial; right: 50%;}
.youth .youth-match-con-add.type01 .round.quarter.team02 .line::before {left: initial; right: 0;}
.youth .youth-match-con-add.type01 .round.quarter.team03 .line {width: 175%; height: 250%; top: -250%;}
.youth .youth-match-con-add.type01 .round.quarter.team04 .line {width: 100%; height: 100%; top: -100%; left: initial; right: 50%;}
.youth .youth-match-con-add.type01 .round.quarter.team04 .line::before {left: initial; right: 0;}

.youth .youth-match-con-add.type01 .round.quarter-nomi.team01 {grid-row: 13 / span 2; grid-column: 1 / span 4;}
.youth .youth-match-con-add.type01 .round.quarter-nomi.team02 {grid-row: 9 / span 2; grid-column: 10 / span 4;}
.youth .youth-match-con-add.type01 .round.quarter-nomi.team03 {grid-row: 9 / span 2; grid-column: 46 / span 4;}
.youth .youth-match-con-add.type01 .round.quarter-nomi.team04 {grid-row: 13 / span 2; grid-column: 55 / span 4;}

.youth .youth-match-con-add.type01 .round.quarter-nomi.team01 .line {width: 50%; height: 300%; top: -300%;}
.youth .youth-match-con-add.type01 .round.quarter-nomi.team02 .line {width: 75%; height: 100%; top: -100%; left: initial; right: 50%;}
.youth .youth-match-con-add.type01 .round.quarter-nomi.team02 .line::before {left: initial; right: 0;}
.youth .youth-match-con-add.type01 .round.quarter-nomi.team03 .line {width: 75%; height: 100%; top: -100%;}
.youth .youth-match-con-add.type01 .round.quarter-nomi.team04 .line {width: 50%; height: 300%; top: -300%; left: initial; right: 50%;}
.youth .youth-match-con-add.type01 .round.quarter-nomi.team04 .line::before {left: initial; right: 0;}

.youth .youth-match-con-add.type01 .round.match.team01 {grid-row: 13 / span 2; grid-column: 7 / span 4;}
.youth .youth-match-con-add.type01 .round.match.team02 {grid-row: 13 / span 2; grid-column: 13 / span 4;}
.youth .youth-match-con-add.type01 .round.match.team03 {grid-row: 13 / span 2; grid-column: 19 / span 4;}
.youth .youth-match-con-add.type01 .round.match.team04 {grid-row: 13 / span 2; grid-column: 25 / span 4;}
.youth .youth-match-con-add.type01 .round.match.team05 {grid-row: 13 / span 2; grid-column: 31 / span 4;}
.youth .youth-match-con-add.type01 .round.match.team06 {grid-row: 13 / span 2; grid-column: 37 / span 4;}
.youth .youth-match-con-add.type01 .round.match.team07 {grid-row: 13 / span 2; grid-column: 43 / span 4;}
.youth .youth-match-con-add.type01 .round.match.team08 {grid-row: 13 / span 2; grid-column: 49 / span 4;}

.youth .youth-match-con-add.type01 .round.match .info {position: absolute; left: 75%;}

.youth .youth-match-con-add.type01 .round.match .line {width: 75%; height: 100%; top: -100%;}
.youth .youth-match-con-add.type01 .round.match .line::before {height: 50%; top: initial; bottom: 0;}
.youth .youth-match-con-add.type01 .round.match .line::after {top: 50%;}
.youth .youth-match-con-add.type01 .round.match.win .line span {height: 50%; width: 1px; background-color: var(--yr500); position: absolute; top: 0; right: 0; display: block;}
.youth .youth-match-con-add.type01 .round.match.team02 .line,
.youth .youth-match-con-add.type01 .round.match.team04 .line,
.youth .youth-match-con-add.type01 .round.match.team06 .line,
.youth .youth-match-con-add.type01 .round.match.team08 .line {left: initial; right: 50%;}
.youth .youth-match-con-add.type01 .round.match.team02 .line::before,
.youth .youth-match-con-add.type01 .round.match.team04 .line::before,
.youth .youth-match-con-add.type01 .round.match.team06 .line::before,
.youth .youth-match-con-add.type01 .round.match.team08 .line::before {left: initial; right: 0;}
.youth .youth-match-con-add.type01 .round.match.team02.win .line span,
.youth .youth-match-con-add.type01 .round.match.team04.win .line span,
.youth .youth-match-con-add.type01 .round.match.team06.win .line span,
.youth .youth-match-con-add.type01 .round.match.team08.win .line span {left: 0; right: initial;}


.youth .youth-match-con-add.type02 {grid-template-columns: repeat(56, 1fr); grid-template-rows: repeat(7, 14.2rem);}

.youth .youth-match-con-add.type02 .round.final {grid-row: 1; grid-column: 26 / span 6;}

.youth .youth-match-con-add.type02 .round.semifinal.team01 {grid-row: 2; grid-column: 11 / span 6;}
.youth .youth-match-con-add.type02 .round.semifinal.team02 {grid-row: 2; grid-column: 41 / span 6;}

.youth .youth-match-con-add.type02 .round.semifinal .line {width: 200%; height: 50%; top: -50%;}
.youth .youth-match-con-add.type02 .round.semifinal.team02 .line {left: initial; right: 50%;}
.youth .youth-match-con-add.type02 .round.semifinal.team02 .line::before {left: initial; right: 0;}
.youth .youth-match-con-add.type02 .round.semifinal .info {margin-top: 5rem;}

.youth .youth-match-con-add.type02 .round.quarter.team01 {grid-row: 4; grid-column: 3 / span 6;}
.youth .youth-match-con-add.type02 .round.quarter.team02 {grid-row: 4; grid-column: 19 / span 6;}
.youth .youth-match-con-add.type02 .round.quarter.team03 {grid-row: 4; grid-column: 33 / span 6;}
.youth .youth-match-con-add.type02 .round.quarter.team04 {grid-row: 4; grid-column: 49 / span 6;}

.youth .youth-match-con-add.type02 .round.quarter .line {width: 133%; height: 100%; top: -100%;}
.youth .youth-match-con-add.type02 .round.quarter .line::before {height: 75%; top: initial; bottom: 0;}
.youth .youth-match-con-add.type02 .round.quarter .line::after {top: 25%;}
.youth .youth-match-con-add.type02 .round.quarter.win .line span {height: 25%; width: 1px; background-color: var(--yr500); position: absolute; right: 0; display: block;}
.youth .youth-match-con-add.type02 .round.quarter.team02 .line,
.youth .youth-match-con-add.type02 .round.quarter.team04 .line {left: initial; right: 50%;}
.youth .youth-match-con-add.type02 .round.quarter.team02 .line::before,
.youth .youth-match-con-add.type02 .round.quarter.team04 .line::before {left: initial; right: 0;}
.youth .youth-match-con-add.type02 .round.quarter.team02.win .line span,
.youth .youth-match-con-add.type02 .round.quarter.team04.win .line span {left: 0; right: initial;}
.youth .youth-match-con-add.type02 .round.quarter .info {margin-top: 5rem;}

.youth .youth-match-con-add.type02 .round.match.team01 {grid-row: 6; grid-column: 1 / span 4;}
.youth .youth-match-con-add.type02 .round.match.team02 {grid-row: 6; grid-column: 7 / span 4;}
.youth .youth-match-con-add.type02 .round.match.team03 {grid-row: 6; grid-column: 17 / span 4;}
.youth .youth-match-con-add.type02 .round.match.team04 {grid-row: 6; grid-column: 23 / span 4;}
.youth .youth-match-con-add.type02 .round.match.team05 {grid-row: 6; grid-column: 31 / span 4;}
.youth .youth-match-con-add.type02 .round.match.team06 {grid-row: 6; grid-column: 37 / span 4;}
.youth .youth-match-con-add.type02 .round.match.team07 {grid-row: 6; grid-column: 47 / span 4;}
.youth .youth-match-con-add.type02 .round.match.team08 {grid-row: 6; grid-column: 53 / span 4;}

.youth .youth-match-con-add.type02 .round.match .info {position: absolute; left: 25%;}

.youth .youth-match-con-add.type02 .round.match .line {width: 75%; height: 100%; top: -100%;}
.youth .youth-match-con-add.type02 .round.match .line::before {height: 75%; top: initial; bottom: 0;}
.youth .youth-match-con-add.type02 .round.match .line::after {top: 25%;}
.youth .youth-match-con-add.type02 .round.match.team02 .line,
.youth .youth-match-con-add.type02 .round.match.team04 .line,
.youth .youth-match-con-add.type02 .round.match.team06 .line,
.youth .youth-match-con-add.type02 .round.match.team08 .line {left: initial; right: 50%;}
.youth .youth-match-con-add.type02 .round.match.team02 .line::before,
.youth .youth-match-con-add.type02 .round.match.team04 .line::before,
.youth .youth-match-con-add.type02 .round.match.team06 .line::before,
.youth .youth-match-con-add.type02 .round.match.team08 .line::before {left: initial; right: 0;}
.youth .youth-match-con-add.type02 .round.match.win .line span {height: 25%; width: 1px; background-color: var(--yr500); position: absolute; top: 0; right: 0; display: block;}
.youth .youth-match-con-add.type02 .round.match.team02.win .line span,
.youth .youth-match-con-add.type02 .round.match.team04.win .line span,
.youth .youth-match-con-add.type02 .round.match.team06.win .line span,
.youth .youth-match-con-add.type02 .round.match.team08.win .line span {left: 0; right: initial;}


.youth .youth-match-con-add.type03 {grid-template-columns: repeat(22, 1fr); grid-template-rows: repeat(10, 7.1rem); padding: 6rem 0;}

.youth .youth-match-con-add.type03 .round.final {grid-row: 1 / span 2; grid-column: 11 / span 2;}

.youth .youth-match-con-add.type03 .round.semifinal.team01 {grid-row: 3 / span 2; grid-column: 4 / span 2;}
.youth .youth-match-con-add.type03 .round.semifinal.team02 {grid-row: 3 / span 2; grid-column: 18 / span 2;}

.youth .youth-match-con-add.type03 .round.semifinal .line {width: 300%; height: 50%; top: -50%;}
.youth .youth-match-con-add.type03 .round.semifinal.team02 .line {left: initial; right: 50%;}
.youth .youth-match-con-add.type03 .round.semifinal.team02 .line::before {left: initial; right: 0;}

.youth .youth-match-con-add.type03 .round.quarter.team01 {grid-row: 5 / span 2; grid-column: 7 / span 2;}
.youth .youth-match-con-add.type03 .round.quarter.team02 {grid-row: 5 / span 2; grid-column: 15 / span 2;}

.youth .youth-match-con-add.type03 .round.quarter .line {width: 100%; height: 50%; top: -50%; left: initial; right: 50%;}
.youth .youth-match-con-add.type03 .round.quarter.team01 .line::before {left: initial; right: 0;}
.youth .youth-match-con-add.type03 .round.quarter.team02 .line {right: -50%;}

.youth .youth-match-con-add.type03 .round.match.team01 {grid-row: 9 / span 2; grid-column: 1 / span 2;}
.youth .youth-match-con-add.type03 .round.match.team02 {grid-row: 9 / span 2; grid-column: 5 / span 2;}
.youth .youth-match-con-add.type03 .round.match.team03 {grid-row: 9 / span 2; grid-column: 9 / span 2;}
.youth .youth-match-con-add.type03 .round.match.team04 {grid-row: 9 / span 2; grid-column: 13 / span 2;}
.youth .youth-match-con-add.type03 .round.match.team05 {grid-row: 9 / span 2; grid-column: 17 / span 2;}
.youth .youth-match-con-add.type03 .round.match.team06 {grid-row: 9 / span 2; grid-column: 21 / span 2;}

.youth .youth-match-con-add.type03 .round.match .line {width: 50%; height: 150%; top: -150%;}
.youth .youth-match-con-add.type03 .round.match .line::before {height: 100%; top: initial; bottom: 0;}

.youth .youth-match-con-add.type03 .round.match.team01 .line,
.youth .youth-match-con-add.type03 .round.match.team06 .line {width: 100%; height: 250%; top: -250%;}

.youth .youth-match-con-add.type03 .round.match.team03 .line,
.youth .youth-match-con-add.type03 .round.match.team05 .line,
.youth .youth-match-con-add.type03 .round.match.team06 .line {left: initial; right: 50%;}
.youth .youth-match-con-add.type03 .round.match.team03 .line::before,
.youth .youth-match-con-add.type03 .round.match.team05 .line::before,
.youth .youth-match-con-add.type03 .round.match.team06 .line::before {left: initial; right: 0;}

@media screen and (max-width: 1023px) {
    .youth .youth-match-wrap + .youth-match-wrap {margin-top: 4rem;}

    .youth .youth-match-con {padding: 3.2rem 0; grid-template-columns: repeat(8, 1fr); grid-template-rows: repeat(5, 30vw);}
    .youth .youth-match-con .round .team .img {height: 20vw;}
    .youth .youth-match-con .round .team .img > i {width: 100%; height: 100%;}
    .youth .youth-match-con .round .team > h6 {padding: 1.2rem; font-size: 1.2rem;}
    .youth .youth-match-con .round .info {row-gap: 1.2rem;}
    .youth .youth-match-con .round .info .score {column-gap: .4rem;}
    .youth .youth-match-con .round .info .score > li {font-size: 1.6rem; column-gap: .4rem;}
    .youth .youth-match-con .round .info .more {width: 140%; background-color: var(--white01); position: relative; z-index: 2;}
    .youth .youth-match-con .round .info .more > li {height: 3rem; font-size: 1.2rem;}

    .youth .youth-match-con .round.final {grid-row: 1; grid-column: 4 / span 2;}
    .youth .youth-match-con .round.final .info {margin-top: 1.2rem;}
    
    .youth .youth-match-con .round.semifinal .info {margin-top: 2.4rem;}
    .youth .youth-match-con .round.semifinal.team01 {grid-row: 3; grid-column: 2 / span 2;}
    .youth .youth-match-con .round.semifinal.team02 {grid-row: 3; grid-column: 6 / span 2;}
    
    .youth .youth-match-con .round.semifinal .line {width: 50%; height: 150%; position: absolute; top: -150%; left: 50%;}
    
    .youth .youth-match-con .round.match.team01 {grid-row: 5; grid-column: 1 / span 2;}
    .youth .youth-match-con .round.match.team02 {grid-row: 5; grid-column: 3 / span 2;}
    .youth .youth-match-con .round.match.team03 {grid-row: 5; grid-column: 5 / span 2;}
    .youth .youth-match-con .round.match.team04 {grid-row: 5; grid-column: 7 / span 2;}
    
    .youth .youth-match-con .round.match .line {width: 50%; height: 100%;}
    .youth .youth-match-con .round.match .line::before {height: 90%;}
    .youth .youth-match-con .round.match .line::after {bottom: 90%;}
    .youth .youth-match-con .round.match .line span {height: 10%;}
    
    .youth .youth-match-con .round.match.team02 .line,
    .youth .youth-match-con .round.match.team04 .line {left: initial; right: 50%;}
    .youth .youth-match-con .round.match.team02 .line::before,
    .youth .youth-match-con .round.match.team04 .line::before {left: initial; right: 0;}
    .youth .youth-match-con .round.match.team02 .line span,
    .youth .youth-match-con .round.match.team04 .line span {right: initial; left: -.5px;}
    .youth .youth-match-con .round.match.win .line::before,
    .youth .youth-match-con .round.match.win .line::after,
    .youth .youth-match-con .round.match.win .line span {background-color: var(--yr500); z-index: 1;}
}

.youth .youth-match-info .img {width: 100%; height: 25rem; background-repeat: no-repeat; background-size: auto; background-position: center;}
.youth .youth-match-info-club .img {width: 100%; height: 95rem; background-repeat: no-repeat; background-size: contain; background-position: center;}
.youth .youth-match-info-club-2 .img {width: 100%; height: 62rem; background-repeat: no-repeat; background-size: contain; background-position: center;}

@media screen and (max-width: 820px) {
    .youth .youth-match-info .img {width: 100%; height: 15rem; background-repeat: no-repeat; background-size: contain; background-position: center;}
    .youth .youth-match-info-club .img {width: 100%; height: 30rem; background-repeat: no-repeat; background-size: contain; background-position: center;}
    .youth .youth-match-info-club-2 .img {width: 100%; height: 20rem; background-repeat: no-repeat; background-size: contain; background-position: center;}
}

.youth .youth-record td {white-space: pre-wrap;}


.youth .youth-img-wrap {display: grid; grid-template-columns: repeat(3, 1fr); align-items: center; grid-gap: 1rem;}
.youth .youth-img-wrap img {width: 100%;}

@media screen and (max-width: 820px) {
    .youth .youth-img-wrap {grid-template-columns: repeat(1, 1fr);}
}


/* 측정데이터 > 항목 소개 */
.physical-step {display: grid; grid-template-columns: repeat(4, 1fr); grid-gap: 2.4rem 3.6rem;}
.physical-step > li {height: 7.2rem; display: flex; align-items: center; column-gap: 1.2rem; padding: 1.6rem 2rem; background-color: var(--gray100); border-radius: .4rem; position: relative;}
.physical-step > li + li::before {content: ''; width: 2rem; height: 2rem; background-image: url(/imgs/ico/ic-physical-arrow-20.svg); background-repeat: no-repeat; background-size: contain; background-position: center; position: absolute; left: -2.8rem;}
.physical-step > li > p {font-size: 1.6rem; font-weight: 500;}

@media screen and (max-width: 820px) {
    .physical-step {grid-template-columns: repeat(1, 1fr);}
    .physical-step > li {height: 5.6rem; column-gap: .8rem; padding: 1.2rem 1.6rem;}
    .physical-step > li + li::before {top: -2.2rem; left: 50%; -webkit-transform: translateX(-50%) rotate(90deg); -moz-transform: translateX(-50%) rotate(90deg); -ms-transform: translateX(-50%) rotate(90deg); -o-transform: translateX(-50%) rotate(90deg); transform: translateX(-50%) rotate(90deg);}
    .physical-step > li > i {width: 3.2rem; height: 3.2rem;}
    .physical-step > li > p {font-size: 1.6rem; font-weight: 500;}
}

.physical-purpose {border-top: 1px solid var(--black);}
.physical-purpose > li {display: flex; align-items: center; column-gap: 1.4rem; height: 6rem; padding: 0 1.4rem; border-bottom: 1px solid var(--gray200);}
.physical-purpose > li > div {width: 3.6rem; height: 3.6rem; border-radius: .4rem; background-color: var(--gray100); display: flex; align-items: center; justify-content: center; font-size: 2rem; font-weight: 600; color: var(--gray500);}
.physical-purpose > li > p {font-size: 1.6rem; font-weight: 500; line-height: 1.6; word-break: keep-all;}

@media screen and (max-width: 820px) {
    .physical-purpose > li {height: 6.4rem; padding: 0;}
    .physical-purpose > li > div {width: 3.2rem; height: 3.2rem; font-size: 1.6rem;}
    .physical-purpose > li > p {font-size: 1.4rem;}
}

/* 측정데이터 > 평균기록 */
.youth-record-part {display: flex; flex-flow: column; row-gap: 8rem;}
.youth-record-part > li {border-top: 1px solid var(--black); padding-top: 4rem; display: flex; grid-gap: 2rem;}
.youth-record-part > li > div {display: flex; flex-flow: column; row-gap: 2.4rem; align-items: center;}
.youth-record-part > li > div > h3 {font-size: 2.4rem; font-weight: 600;}
.youth-record-part > li > div > ul {display: flex; column-gap: 2rem;}
.youth-record-part > li > div > ul > li {display: flex; flex-flow: column; row-gap: 1.6rem; align-items: center;}
.youth-record-part > li > div > ul > li > img {width: 28.5rem; height: 19rem;}
.youth-record-part > li > div > ul > li > p {display: flex; align-items: center; justify-content: center; column-gap: .2rem; font-size: 1.6rem; font-weight: 500;}

@media screen and (max-width: 820px) {
    .youth-record-part {row-gap: 4rem;}
    .youth-record-part > li {padding-top: 2.4rem; flex-wrap: wrap;}
    .youth-record-part > li > div {row-gap: 1.6rem;}
    .youth-record-part > li > div > h3 {font-size: 2rem;}
    .youth-record-part > li > div > ul {grid-gap: 2rem 1.2rem; justify-content: center; flex-wrap: wrap;}
    .youth-record-part > li > div > ul > li {row-gap: .8rem; align-items: center; flex-wrap: wrap; width: calc((100% - 1.2rem) / 2);}
    .youth-record-part > li > div > ul > li > img {width: 100%; height: auto;}
    .youth-record-part > li > div > ul > li > p {column-gap: .4rem; font-size: 1.4rem;}
    .youth-record-part > li > div > ul > li > p > i {width: 1.2rem; height: 1.2rem;}
}

/* 측정데이터 > 퍼센타일 확인 */
.physical-table1 input {width: 12rem;}

.physical-graph {display: flex; column-gap: 1.2rem; align-items: center;}
.physical-graph > p {text-align: center; font-size: 1.6rem; font-weight: 600; width: 4rem;}
.physical-graph > div {width: calc(100% - 4rem - 1.2rem); height: 1.2rem; border-radius: 4rem; background-color: var(--gray100);}
.physical-graph > div > span {display: block; height: 1.2rem; border-radius: 4rem; background: linear-gradient(-90deg, #FF601C 0%, #BF3F06 100%);}

/* 측정데이터 > 내 기록 확인 */
.physical-record {display: flex; align-items: center; column-gap: 3rem; border-top: 1px solid var(--black); padding: 4rem 0; border-bottom: 1px solid var(--gray200);}
.physical-record .graph {width: 42rem; height: 32rem; border-radius: .8rem; background-color: var(--white01); border: 1px solid var(--gray200); display: flex; align-items: center; justify-content: center;}

.physical-record .info {width: calc(100% - 42rem - 3rem); display: flex; flex-flow: column; row-gap: 2.4rem;}
.physical-record .info > h5 {font-size: 3rem; font-weight: 600;}
.physical-record .info > ul {display: grid; grid-template-columns: repeat(2, 1fr); grid-gap: .8rem;}
.physical-record .info > ul > li {height: 6rem; padding: 0 2rem; display: flex; align-items: center; justify-content: space-between; background-color: var(--gray100);}
.physical-record .info > ul > li > h6 {font-size: 1.6rem; color: var(--gray500);}
.physical-record .info > ul > li > p {font-size: 2rem; font-weight: 500;}

@media screen and (max-width: 820px) {
    .physical-record {flex-flow: column; row-gap: 1.6rem; padding: 2.4rem 0;}
    .physical-record .graph {width: 100%;}
    
    .physical-record .info {width: 100%; row-gap: 1.6rem;}
    .physical-record .info > h5 {font-size: 2.4rem;}
    .physical-record .info > ul {grid-template-columns: repeat(1, 1fr);}
    .physical-record .info > ul > li {height: 5.2rem; padding: 0 1.2rem;}
    .physical-record .info > ul > li > h6 {font-size: 1.4rem;}
    .physical-record .info > ul > li > p {font-size: 1.6rem;}
}

.color-desc {display: flex; align-items: center; column-gap: 2.4rem;}
.color-desc > li {display: flex; align-items: center; column-gap: .8rem; font-size: 1.6rem;}
.color-desc > li > span {display: block; width: 2rem; height: 2rem;}

@media screen and (max-width: 820px) {
    .color-desc {column-gap: 1.6rem;}
    .color-desc > li {column-gap: .6rem; font-size: 1.4rem;}
    .color-desc > li > span {width: 1.6rem; height: 1.6rem;}
}

.physical-data {display: grid; grid-template-columns: repeat(2, 1fr); grid-gap: 4rem 2rem;}
.physical-data + .physical-data {margin-top: 8rem;}
.physical-data > div {display: flex; flex-flow: column; row-gap: 1.6rem;}
.physical-data > div > h6 {font-size: 2rem; font-weight: 600;}
.physical-data > div > div {display: flex; align-items: center; column-gap: .8rem;}
.physical-data > div > div > table {width: calc(100% - .8rem - 38.2rem);}
.physical-data > div > div .graph {position: relative; width: 38.2rem; height: 100%;}
.physical-data > div > div .graph .background {width: 100%; height: 100%; position: relative;}
.physical-data > div > div .graph .background .line {width: 100%; height: 100%; border: 1px solid var(--gray200); display: flex; justify-content: space-evenly;}
.physical-data > div > div .graph .background .line > li {width: 1px; height: 100%; border-left: 1px dashed var(--gray200);}
.physical-data > div > div .graph .background .score {position: absolute; bottom: 0; left: 0; width: 100%; height: 3rem; background-color: var(--gray100); padding: 0 1rem; display: flex; align-items: center; justify-content: space-evenly; border: 1px solid var(--gray200); border-top: 0;}
.physical-data > div > div .graph .background .score > li {font-size: 1.4rem;}

.physical-data > div > div .graph .record {position: absolute; top: 0; left: 0; width: 100%; height: calc(100% - 3rem); display: flex; flex-flow: column; justify-content: space-evenly; z-index: 9;}
.physical-data > div > div .graph .record > div {display: flex; flex-flow: column; row-gap: .4rem; position: relative;}
.physical-data > div > div .graph .record > div > div {height: 1.2rem; border-radius: 0 1rem 1rem 0; position: relative;}
.physical-data > div > div .graph .record > div > div > p {font-size: 1.4rem; font-weight: 600; position: absolute; top: 1.6rem; right: 0;}

.physical-data > div > div .graph .record .team > div {background-color: var(--pb800);}
.physical-data > div > div .graph .record .school > div {background-color: var(--pb300);}
.physical-data > div > div .graph .record .my > div {background-color: var(--yr500);}
.physical-data > div > div .graph .record .team > div > p {color: var(--pb800);}
.physical-data > div > div .graph .record .school > div > p {color: var(--pb300);}
.physical-data > div > div .graph .record .my > div > p {color: var(--yr500);}

.physical-data > button {grid-column: 1 / span 2; margin: 0 auto; width: 22rem; height: 5.2rem; column-gap: .2rem; border-color: var(--gray200); color: var(--gray500); background-color: var(--white01); cursor: pointer;}

.physical-data + div + .physical-data > div > div > table {width: 100%;}

@media screen and (max-width: 820px) {
    .physical-data {display: flex; flex-flow: column; row-gap: 3.2rem;}
    .physical-data + .physical-data {margin-top: 4rem;}
    .physical-data > div {row-gap: 1.2rem;}
    .physical-data > div > h6 {font-size: 1.6rem;}
    .physical-data > div > div {flex-flow: column; row-gap: 1.2rem;}
    .physical-data > div > div > table {width: 100%;}
    .physical-data > div > div > table th {width: 50%;}
    .physical-data > div > div .graph {width: 100%; height: 18.4rem;}
    .physical-data > div > div .graph .background .score > li {font-size: 1.2rem;}
    
    .physical-data > button {grid-column: initial; margin: -1.2rem auto 0; width: 16rem; height: 4.8rem;}    
}