
/* root --------------------------- */
:root {
    /* ------------ brand color */
    --pb50: #E3E6ED;
    --pb100: #BAC0D4;
    --pb200: #8D97B7;
    --pb300: #63709A;
    --pb400: #445387;
    --pb500: #223976; /* kbl sub color */
    --pb600: #1C326E;
    --pb700: #142A63;
    --pb800: #0B2157;
    --pb900: #011241; /* kbl main color */

    --yr50: #FBEAE7;
    --yr100: #FFCEBC;
    --yr200: #FFAE90;
    --yr300: #FF8F63;
    --yr400: #FF7740;
    --yr500: #FF601C; /* kbl point color */
    --yr600: #F45A18;
    --yr700: #E65313;
    --yr800: #D84C0F;
    --yr900: #BF3F06;

    /* ------------ greyscale color */
    --gray100: #F2F3F6;
    --gray200: #E3E5E9;
    --gray300: #D7D8DA;
    --gray400: #BDBEC0;
    --gray500: #747479;
    --gray600: #424243;
    --gray700: #2B2B2B;
    --gray800: #1C1C1C;
    --gray900: #121212;

    /* ------------ system color */
    --red: #FF3529;
    --blue: #236CD9;
    --white01: #FFFFFF;
    --white02: #FFFFFF;
    --black: #000000;

    /* ------------ club color */
    /* 원주 DB 프로미 : db */
    --db-main: #03662C;
    --db-point: #00399F;
    --db-sub01: #71BB98;
    --db-sub02: #C6EAD9;
    /* 서울 삼성 썬더스 : ss */
    --ss-main: #0032A0;
    --ss-point: #FFB81C;
    --ss-sub01: #6D94C7;
    --ss-sub02: #CEDDF1;
    /* 서울 SK 나이츠 : sk */
    --sk-main: #DB0028;
    --sk-point: #FF9600;
    --sk-sub01: #EC696F;
    --sk-sub02: #F9D3DA;
    /* 창원 LG 세이커스 : lg */
    --lg-main: #550E10;
    --lg-point: #FFBA00;
    --lg-sub01: #996E70;
    --lg-sub02: #D3B0B2;
    /* 고양 소노 스카이거너스 : or (오리온) */
    --or-main: #283D60;
    --or-point: #7DA1C9;
    --or-sub01: #63748D;
    --or-sub02: #CBD1D9;
    /* 전수 KCC 이지스 : kcc */
    --kcc-main: #07215A;
    --kcc-point: #FF9D00;
    --kcc-sub01: #666E80;
    --kcc-sub02: #ACB3C3;
    /* 안양 KGC 인삼공사 : kgc */
    --kgc-main: #D61D38;
    --kgc-point: #171717;
    --kgc-sub01: #E9667E;
    --kgc-sub02: #F2CBD2;
    /* 수원 KT 소닉붐 : kt */
    --kt-main: #E21820;
    --kt-point: #F07C22;
    --kt-sub01: #F36E6B;
    --kt-sub02: #F1CBD1;
    /* 대구 한국가스공사 : el */
    --el-main: #201351;
    --el-point: #BE1540;
    --el-sub01: #797197;
    --el-sub02: #BEBACF;
    /* 울산 현대모비스 피버스 : hd */
    --hd-main: #00287A;
    --hd-point: #ED1C24;
    --hd-sub01: #617196;
    --hd-sub02: #BFC7D8;

    /* ------------ box shadow */
    --shadow-gr: .2rem .2rem 2rem rgba(1, 18, 65, 0.04);
    --shadow-pb: .4rem .4rem 1.2rem rgba(1, 18, 65, 0.16);
    --shadow-yr: .4rem .4rem 3.2rem rgba(255, 96, 28, 0.2);
}

@media (prefers-color-scheme: dark) {
    :root {
        /* ------------ greyscale color */
        --gray100: #1C1C1C;
        --gray200: #2B2B2B;
        --gray300: #424243;
        --gray400: #424243;

        /* ------------ system color */
        --white01: #1C1C1C;
        --white02: #121212;
        --black: #FFFFFF;
    }
}
[data-theme="dark"] {
    /* ------------ greyscale color */
    --gray100: #1C1C1C;
    --gray200: #2B2B2B;
    --gray300: #424243;
    --gray400: #424243;

    /* ------------ system color */
    --white01: #1C1C1C;
    --white02: #121212;
    --black: #FFFFFF;
}
